import { clone, shiftPushArray } from '../../../helpers/javascript';

export const textProcessing = (text, type) => {
  const textArray = text.split(/\n/gm).filter((t) => t !== '');
  let data = [];

  textArray.map((t) => {
    data.push({ text: t !== '<empty>' ? t : '', type: type });
  });

  return data;
};

export const convertTextArrayToText = (data) => {
  let _data = '';
  data.map((d, i) => {
    _data += d.text === '' ? '<empty>' : d.text;
    if (i < data.length - 1) _data += '\n\n';
  });

  return _data;
};

const maxNumVideos = (scenes, numbVideos) => {
  let numberMax = 0;
  if (numbVideos === 0) {
    scenes.map((s) => {
      const realVideosTexts = s.text.split(/\n/gm).filter((t) => t !== '');
      const realVideosMedias = s.medias;
      const realVideos =
        realVideosTexts.length > realVideosMedias.length
          ? realVideosTexts.length
          : realVideosMedias.length;
      if (realVideos > numberMax) numberMax = realVideos;
    });
  } else numberMax = numbVideos;

  return numberMax;
};

export const scenesProcessing = (scenes, activeSceneText, type, activeScene, numbVideos) => {
  let _scenes = clone(scenes);

  if (activeSceneText !== null) {
    let scene = textProcessing(activeSceneText, type);

    _scenes[activeScene].videoDefinition = scene;
    _scenes[activeScene].text = convertTextArrayToText(scene);
  }

  let numberVideos = maxNumVideos(_scenes, numbVideos);

  _scenes.map((s) => {
    const videoDefData = textProcessing(s.text, type);
    let _videoDefinition = [];
    for (let j = 0; j < numberVideos; j++) {
      _videoDefinition[j] = shiftPushArray(videoDefData);
    }
    s.videoDefinition = _videoDefinition;
  });

  return _scenes;
};

export const addEmptyText = (text, cursor) => {
  let data = '';
  let texts = [];

  if (cursor) {
    const _text = text.slice(0, cursor) + 'woxo-empty' + text.slice(cursor);

    const textArray = _text.split(/\n/gm).filter((t) => t !== '');
    textArray.map((t) => {
      if (t.indexOf('woxo-empty') !== -1) texts.push({ text: '<empty>' });
      else texts.push({ text: t });
    });

    data = convertTextArrayToText(texts);
  } else {
    data = text + '\n\n<empty>';
  }

  return data;
};

/**
 * --- VERSION 1
 */
export const textProcessingV1 = (text, type) => {
  const videosArray = text.split(/---/gm).filter((t) => t !== '');
  let videos = [];
  videosArray.map((v) => {
    const textArray = v.split(/\n/gm).filter((t) => t !== '');
    let data = [];

    textArray.map((t) => {
      data.push({ text: t !== '<empty>' ? t : '', type: type });
    });

    videos.push({ text: '', type: type, v1: data });
  });

  return videos;
};

export const convertTextArrayToTextV1 = (data) => {
  let _data = '';
  data.map((d, i) => {
    d.v1.map((dat, idx) => {
      _data += dat.text === '' ? '<empty>' : dat.text;
      if (idx < d.v1.length - 1) _data += '\n\n';
    });
    if (i < data.length - 1) _data += '\n\n---\n\n';
  });

  return _data;
};

const maxNumVideosV1 = (scenes, numbVideos) => {
  let numberMax = 0;
  if (numbVideos === 0) {
    scenes.map((s) => {
      const realVideosTexts = s.text.split(/---/gm).filter((t) => t !== '');
      const realVideosMedias = s.medias;
      const realVideos =
        realVideosTexts.length > realVideosMedias.length
          ? realVideosTexts.length
          : realVideosMedias.length;
      if (realVideos > numberMax) numberMax = realVideos;
    });
  } else numberMax = numbVideos;

  return numberMax;
};

export const scenesProcessingV1 = (scenes, activeSceneText, type, activeScene, numbVideos) => {
  let _scenes = clone(scenes);

  if (activeSceneText !== null) {
    let scene = textProcessingV1(activeSceneText, type);

    _scenes[activeScene].videoDefinition = scene;
    _scenes[activeScene].text = convertTextArrayToTextV1(scene);
  }

  let numberVideos = maxNumVideosV1(_scenes, numbVideos);

  _scenes.map((s) => {
    const videoDefData = textProcessingV1(s.text, type);
    let _videoDefinition = [];
    for (let j = 0; j < numberVideos; j++) {
      _videoDefinition[j] = shiftPushArray(videoDefData);
    }
    s.videoDefinition = _videoDefinition;
  });

  return _scenes;
};
