export const sampleMusics = [
  {
    mood: 'None',
    music: ['']
  },
  {
    mood: 'Happy',
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399420/woxo-video/audios-v2/Happy/Train_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399417/woxo-video/audios-v2/Happy/Palm_trees_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399389/woxo-video/audios-v2/Happy/Circus_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399374/woxo-video/audios-v2/Happy/Elephants_on_Parade_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399192/woxo-video/audios-v2/Happy/toucans_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647813644/woxo-video/audios-v2/Happy/Smile_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647813528/woxo-video/audios-v2/Happy/Fun_ideas_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647812021/woxo-video/audios-v2/Happy/Let_s_stay_together_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647231293/woxo-video/audios-v2/Happy/WHEN_I_M_WITH_YOU_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230918/woxo-video/audios-v2/Happy/IN_LOVE_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230880/woxo-video/audios-v2/Happy/UNCLE_BROWN_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230873/woxo-video/audios-v2/Happy/Silly_Clowns_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028465/woxo-video/audios-v2/Happy/Smooth_Sail_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028456/woxo-video/audios-v2/Happy/Joy_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028442/woxo-video/audios-v2/Happy/Sunshine_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028425/woxo-video/audios-v2/Happy/Happy_Monkeys_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028401/woxo-video/audios-v2/Happy/Fun_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028350/woxo-video/audios-v2/Happy/Summer_breeze_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028299/woxo-video/audios-v2/Happy/I_am_Ready_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028255/woxo-video/audios-v2/Happy/New_Day_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028221/woxo-video/audios-v2/Happy/Shake_it_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028176/woxo-video/audios-v2/Happy/Feels_Good_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028066/woxo-video/audios-v2/Happy/Dancing_Night_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028059/woxo-video/audios-v2/Happy/Misfits_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028037/woxo-video/audios-v2/Happy/Sundays_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624040500/woxo-video/audios-v2/Happy/Sunshine_in_My_Heart_-_Jingle_Punks.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624040495/woxo-video/audios-v2/Happy/Morning_Walk_-_Jingle_Punks.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624040492/woxo-video/audios-v2/Happy/For_Mimi_-_Twin_Musicom.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624040491/woxo-video/audios-v2/Happy/Beach_Party_-_Kevin_MacLeod.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624040491/woxo-video/audios-v2/Happy/B-Roll_ska_-_Islandesque_-_Kevin_MacLeod.mp3'
    ]
  },
  {
    mood: 'Inspirational',
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399538/woxo-video/audios-v2/Inspirational/now_is_the_time.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399531/woxo-video/audios-v2/Inspirational/Always_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399519/woxo-video/audios-v2/Inspirational/WINDMILLS_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399491/woxo-video/audios-v2/Inspirational/Night_Sounds_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399163/woxo-video/audios-v2/Inspirational/Rock_it_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647812033/woxo-video/audios-v2/Inspirational/FINALLY_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647811861/woxo-video/audios-v2/Inspirational/unbreakable_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647811786/woxo-video/audios-v2/Inspirational/Beyond_time_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647231517/woxo-video/audios-v2/Inspirational/WHEN_I_M_WITH_YOU_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647231017/woxo-video/audios-v2/Inspirational/Hot_Air_Balloons_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230880/woxo-video/audios-v2/Inspirational/Now_is_the_time_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230878/woxo-video/audios-v2/Inspirational/Same_old_blues_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027063/woxo-video/audios-v2/Inspirational/The_Ride_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027056/woxo-video/audios-v2/Inspirational/Morning_Song_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Hope_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027025/woxo-video/audios-v2/Inspirational/Shine_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027025/woxo-video/audios-v2/Inspirational/In_the_Sky_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026989/woxo-video/audios-v2/Inspirational/Alive_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624042456/woxo-video/audios-v2/Inspirational/Bottomless_Cup_-_Silent_Partner.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624042418/woxo-video/audios-v2/Inspirational/Barton_Springs_-_Bird_Creek.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624042382/woxo-video/audios-v2/Inspirational/Bad_Love_Niwel_Remix_-_Niwel.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624042356/woxo-video/audios-v2/Inspirational/Aurora_Borealis_-_Bird_Creek.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624042340/woxo-video/audios-v2/Inspirational/Around_The_Sea_-_Scandinavianz.mp3'
    ]
  },
  {
    mood: 'Romantic',
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399620/woxo-video/audios-v2/Romantic/lovers_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647811886/woxo-video/audios-v2/Romantic/Dreaming_of_you_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647231636/woxo-video/audios-v2/Romantic/Things_I_have_Lost_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647231443/woxo-video/audios-v2/Romantic/Hot_Air_Balloons_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647231421/woxo-video/audios-v2/Romantic/Same_old_blues_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647231296/woxo-video/audios-v2/Romantic/IN_LOVE_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639029012/woxo-video/audios-v2/Romantic/Remember_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028996/woxo-video/audios-v2/Romantic/The_One_I_Love_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028992/woxo-video/audios-v2/Romantic/Together_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028886/woxo-video/audios-v2/Romantic/Missing_You_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028865/woxo-video/audios-v2/Romantic/Summer_breeze_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624041596/woxo-video/audios-v2/Romantic/Hounds_-_Silent_Partner.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624041587/woxo-video/audios-v2/Romantic/Highway_Wildflowers_-_Bird_Creek.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624041575/woxo-video/audios-v2/Romantic/Firework_-_Vendredi.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624041564/woxo-video/audios-v2/Romantic/Darling_Ranch_-_Jingle_Punks.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624041092/woxo-video/audios-v2/Romantic/As_We_Go_-_The_126ers.mp3'
    ]
  },
  {
    mood: 'Angry',
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648398842/woxo-video/audios-v2/Angry/Rock_it.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648398812/woxo-video/audios-v2/Angry/Grandpa_s_Farm.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648398804/woxo-video/audios-v2/Angry/the_calling.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647229985/woxo-video/audios-v2/Angry/High_Voltage_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647229945/woxo-video/audios-v2/Angry/Into_the_woods_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647229945/woxo-video/audios-v2/Angry/skaters_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026408/woxo-video/audios-v2/Angry/Don_t_say_a_word_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026404/woxo-video/audios-v2/Angry/Battle_set_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026385/woxo-video/audios-v2/Angry/Rage_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026370/woxo-video/audios-v2/Angry/Crossroads_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624054388/woxo-video/audios-v2/Angry/Cut_It_-_Silent_Partner.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624054308/woxo-video/audios-v2/Angry/Burnt_-_Jingle_Punks.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624046852/woxo-video/audios-v2/Angry/Battleground_-_Ethan_Meixsell.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624046763/woxo-video/audios-v2/Angry/Back_and_Forth_-_Silent_Partner.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624046674/woxo-video/audios-v2/Angry/A_Young_Man_-_ALBIS.mp3'
    ]
  },
  {
    mood: 'Bright',
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647811928/woxo-video/audios-v2/Bright/with_me_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230067/woxo-video/audios-v2/Bright/Orbits_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230061/woxo-video/audios-v2/Bright/Adrenaline_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647229984/woxo-video/audios-v2/Bright/Now_is_the_time_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026453/woxo-video/audios-v2/Bright/Follow_the_Light_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624046589/woxo-video/audios-v2/Bright/Cockpit_-_Silent_Partner.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624046520/woxo-video/audios-v2/Bright/ChaCha_Fontanez_-_Jimmy_Fontanez_Media_Right_Productions.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624046449/woxo-video/audios-v2/Bright/Beach_-_MBB.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624046394/woxo-video/audios-v2/Bright/All_Night_-_Ikson.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624046323/woxo-video/audios-v2/Bright/A_Long_Cold_-_Riot.mp3'
    ]
  },
  {
    mood: 'Calm',
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648398871/woxo-video/audios-v2/Calm/Palm_trees_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647812024/woxo-video/audios-v2/Calm/Distant_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230086/woxo-video/audios-v2/Calm/Down_the_river_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230056/woxo-video/audios-v2/Calm/Hot_Air_Balloons_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624045402/woxo-video/audios-v2/Calm/AM_-_Ikson.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624045329/woxo-video/audios-v2/Calm/Afternoon_Nap_-_Ghostrifter_Official.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624045264/woxo-video/audios-v2/Calm/after_the_rain_-_Rexlambo.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624045194/woxo-video/audios-v2/Calm/Acoustic_Folk_Instrumental_-_Hyde_-_Free_Instrumentals.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624045145/woxo-video/audios-v2/Calm/A_Little_High_-_GoSoundtrack.mp3'
    ]
  },
  {
    mood: 'Dark',
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648398928/woxo-video/audios-v2/Dark/the_stranger_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648398905/woxo-video/audios-v2/Dark/KEEP_GOING_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230224/woxo-video/audios-v2/Dark/skaters_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230211/woxo-video/audios-v2/Dark/GHOST_SHIP_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230064/woxo-video/audios-v2/Dark/Into_the_woods_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639029044/woxo-video/audios-v2/Dark/One_mission_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026758/woxo-video/audios-v2/Dark/Dark_Night_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026746/woxo-video/audios-v2/Dark/Backwards_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026660/woxo-video/audios-v2/Dark/Black_n_Black_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624044577/woxo-video/audios-v2/Dark/Bonjour_-_Peyruis.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624044512/woxo-video/audios-v2/Dark/Beginning_-_Audionautix.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624044450/woxo-video/audios-v2/Dark/Battle_Boss_-_BoxCat_Games.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624044390/woxo-video/audios-v2/Dark/Atlantean_Twilight_-_Kevin_MacLeod.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624044341/woxo-video/audios-v2/Dark/A_Magical_Journey_Through_Space_-_Leonell_Cassio.mp3'
    ]
  },
  {
    mood: 'Dramatic',
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399043/woxo-video/audios-v2/Dramatic/the_stranger_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399035/woxo-video/audios-v2/Dramatic/now_is_the_time_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399029/woxo-video/audios-v2/Dramatic/TEARS_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648398963/woxo-video/audios-v2/Dramatic/KEEP_GOING_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647811901/woxo-video/audios-v2/Dramatic/Mystery_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230733/woxo-video/audios-v2/Dramatic/Things_I_have_Lost_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647230644/woxo-video/audios-v2/Dramatic/LEAD_THE_WAY_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026611/woxo-video/audios-v2/Dramatic/Speedy_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026610/woxo-video/audios-v2/Dramatic/Moving_Fowards_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026605/woxo-video/audios-v2/Dramatic/Disco_Ball_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624044057/woxo-video/audios-v2/Dramatic/Cataclysmic_Molten_Core_-_Jingle_Punks.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624044003/woxo-video/audios-v2/Dramatic/Call_to_Adventure_-_Kevin_MacLeod.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624043959/woxo-video/audios-v2/Dramatic/Black_and_White_-_Max_Surla_Media_Right_Productions.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624043923/woxo-video/audios-v2/Dramatic/Action_Hero_-_Jingle_Punks.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624043878/woxo-video/audios-v2/Dramatic/Achilles_-_Kevin_MacLeod.mp3'
    ]
  },
  {
    mood: 'Sad',
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648399649/woxo-video/audios-v2/Sad/TEARS_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647231708/woxo-video/audios-v2/Sad/Things_I_have_Lost_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647231645/woxo-video/audios-v2/Sad/GHOST_SHIP_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1647231242/woxo-video/audios-v2/Sad/Down_the_river_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639029092/woxo-video/audios-v2/Sad/Old_pictures_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027569/woxo-video/audios-v2/Sad/Nostalgia_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027558/woxo-video/audios-v2/Sad/Goodbye_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027551/woxo-video/audios-v2/Sad/Remember_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027518/woxo-video/audios-v2/Sad/NOCTURNE_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027516/woxo-video/audios-v2/Sad/After_the_storm_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027507/woxo-video/audios-v2/Sad/Empty_Room_-_MOODHALO.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624041974/woxo-video/audios-v2/Sad/Desert_Sky_-_Silent_Partner.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624041951/woxo-video/audios-v2/Sad/Days_Are_Long_-_Silent_Partner.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624041939/woxo-video/audios-v2/Sad/Called_Upon_-_Silent_Partner.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624041926/woxo-video/audios-v2/Sad/Almost_A_Year_Ago_-_John_Deley_and_the_41_Players.mp3',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1624041912/woxo-video/audios-v2/Sad/500_AM_-_Peter_Rudenko.mp3'
    ]
  }
];
