import { check, tags } from '../../services/video.service';
import { clone, isUrl, isColor, isGradientType, isMood } from '../../helpers/javascript';
import bulkNormalize from '../useBulkNormalize';
import Presets from '../../data/template-generator/presets';
import Textaligns from '../../data/template-generator/textaligns';

export const getVideoDataFromAutoText = (text, textAttr = 'Title') => {
  const videoData = {};
  if (text && text.startsWith('/auto')) {
    const textWithoutBrs = text.replace(/<br>/g, '');
    const chunks = textWithoutBrs.split('- ');
    // extract scenes medias
    // extract scenes text and titles
    let mediasRaw = '/auto [mountains]';

    if (chunks.length > 1) {
      mediasRaw = chunks.shift();

      // extracting the text into scenes
      chunks.forEach((element, index) => {
        videoData[`${textAttr} ${index + 1}`] = element;
      });
      const medias =
        mediasRaw.match(/\/(video|image) \[[A-Za-z0-9, ]+\]/gm) ||
        mediasRaw.match(/\[[A-Za-z0-9, ]+\]/gm);

      const ct = (medias && medias[0].match(/\/(video|image)/g)) || ['/video'];
      const keywords = medias && medias[0].replace(/\/(video|image)/g, '').match(/[A-Za-z0-9]+/g);

      let Music = mediasRaw.match(/\/Music [A-Za-z]+/gm);
      Music = Music && Music[0].replace('/Music ', '').match(/[A-Za-z]+/gm);

      let TextPosition = mediasRaw.match(/\/TextPosition [A-Za-z]+/gm);
      TextPosition =
        TextPosition && TextPosition[0].replace('/TextPosition ', '').match(/[A-Za-z]+/gm);

      let TextStyle = mediasRaw.match(/\/TextStyle [A-Za-z]+/gm);
      TextStyle = TextStyle && TextStyle[0].replace('/TextStyle ', '').match(/[A-Za-z]+/gm);

      if (Music && Array.isArray(Music)) Object.assign(videoData, { Music: Music[0] });
      else Object.assign(videoData, { Music: 'random' });
      if (TextPosition && Array.isArray(TextPosition)) {
        Object.assign(videoData, { TextPosition: TextPosition[0] });
      } else Object.assign(videoData, { TextPosition: 'random' });
      if (TextStyle && Array.isArray(TextStyle)) {
        Object.assign(videoData, { TextStyle: TextStyle[0] });
      } else Object.assign(videoData, { TextStyle: 'random' });

      if (keywords && Array.isArray(keywords) && keywords.length > 0) {
        chunks.forEach((key, index) => {
          videoData[`Media ${index + 1}`] = keywords[index]
            ? `${ct[0]} ${keywords[index]}`
            : `${ct[0]} ${keywords[keywords.length - 1]}`;
        });

        return videoData;
      }

      // getting the media out
    } else {
      console.log('Must have at least an scene');
    }
  }
  return false;
};
