import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import Loader from '../Loader';
import ActionAlert from '../Alert';
import RemoteAutoComplete from '../RemoteAutoComplete';
import { types } from '../../services/entity.services';
import { setProfile } from '../../services/security.service';
import translate from '../../data/translations';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { API_HOST } from '~/services/app.config';
import CustomDialog from '../Dialog';
import { useAuthentication } from '~/hooks/useAuthentication';
import MailchimpSurvey from '~/components/MailchimpSurvey/index';

const UserProfessionJob = ({ forwardRef, onChange }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const dialogRef = React.useRef(null);
  const { user } = useAuthentication();

  const handleEscape = () => {
    if (dialogRef && dialogRef.current) dialogRef.current.hide();
  };

  React.useImperativeHandle(forwardRef, () => ({
    show: () => {
      dialogRef && dialogRef.current && dialogRef.current.show();
      const url = new URL(window.location.href);
      url.searchParams.set('signupSurvey', true);
      window.history.pushState({ path: url.href }, '', url.href);
    },
    hide: () => {
      dialogRef && dialogRef.current && dialogRef.current.hide();
    }
  }));

  return (
    <CustomDialog
      forwardRef={dialogRef}
      fullWidth
      className={classes.root}
      maxWidth="md"
      onEscapeKeyDown={handleEscape}
      useClose
      isLoading={loading}
      onClose={handleEscape}
      loaderBackgroundColor="rgba(255, 255, 255, 15%)"
    >
      <MailchimpSurvey user={user} handleEscape={handleEscape} />
    </CustomDialog>
  );
};

UserProfessionJob.propTypes = {
  forwardRef: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func
};

UserProfessionJob.defaultProps = {
  forwardRef: {},
  onChange: () => {}
};

export default UserProfessionJob;
