export const sampleGradients = [
  'linear (#4BC0C8, #C779D0, #FEAC5E) animated',
  'radial (#FF81B6, #7D42FF, #08FCFF) animated',
  'undulations (#845EC2, #D65DB1, #FF6F91, #FF9671, #FFC75F, #F9F871) animated',
  'bows (#F9F871, #FFC75F, #FF9671, #FF6F91, #D65DB1) animated',
  'peaks (#845EC2, #009EFA, #00D2FC, #4FFBDF) animated',
  'waves (#FFCC70, #C850C0, #4158D0) animated',
  'squares (#FA8BFF, #2BD2FF, #2BFF88) animated',
  'clouds (#FF5EF7, #02F5FF) animated'
];
