import { v4 } from 'uuid';

export const sampleRestaurantMenuArtboardVideoData = [
  {
    videoDefinition: [
      {
        text: 'Celebrate with...',
        type: 'Menu'
      },
      {
        text: 'This is how we do it...',
        type: 'Menu'
      },
      {
        text: 'Eat to your heart’s content!',
        type: 'Menu'
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/397970946.hd.mp4?s=bdd1e35e2ba95d7b673491e4c6bbc4571edcf293&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F397970946.sd.mp4%3Fs%3D4c9ad02237b31d13dc11d2292089af5dc5bbd3ef%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3944327%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/397970946.hd.mp4?s=bdd1e35e2ba95d7b673491e4c6bbc4571edcf293&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F397970946.sd.mp4%3Fs%3D4c9ad02237b31d13dc11d2292089af5dc5bbd3ef%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3944327%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/464577768.hd.mp4?s=7baf6e4784d196f1be2e83c9f81d8b997a2bbeac&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F464577768.sd.mp4%3Fs%3Dd471d95c6f6c3b0bb0b360eee66e2fee239b9689%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5515577%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/464577768.hd.mp4?s=7baf6e4784d196f1be2e83c9f81d8b997a2bbeac&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F464577768.sd.mp4%3Fs%3Dd471d95c6f6c3b0bb0b360eee66e2fee239b9689%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5515577%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/567357811.hd.mp4?s=83ff07c353cc71deb123437508cc5e61eac35e2e&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F567357811.sd.mp4%3Fs%3D86a604d842b46dabdae8dba3556bda3d2d6d0261%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8477264%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/567357811.hd.mp4?s=83ff07c353cc71deb123437508cc5e61eac35e2e&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F567357811.sd.mp4%3Fs%3D86a604d842b46dabdae8dba3556bda3d2d6d0261%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8477264%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'Celebrate with...\n\nThis is how we do it...\n\nEat to your heart’s content!\n\nLet us show you how we do it...',
    type: 'Menu',
    id: v4()
  }
];
