import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Navbar from '../../components/MainAppBar';
import Footer from '../../components/Footer';
import ErrorAlertBoundary from '../../components/ErrorAlertBoundary';
import { Toaster } from 'react-hot-toast';
import { withAudience } from '~/hooks/audience';
import UserProfessionJob from '~/components/UserProfessionJob';
import { getAccessToken } from '~/pages/_app';
import { useTeam } from '~/services/team.services';
import { getProfile } from '~/services/security.service';
import { useAuthentication } from '~/hooks/useAuthentication';

const useStyles = makeStyles((theme) => ({
  content: { overflow: 'hidden' },
  container: {
    backgroundColor: '#f2f2f2'
  },
  footer: {}
}));

const Main = ({
  children,
  lang,
  navbarContent,
  endNavbarContent,
  navbarClassName,
  containerClassName,
  showLanguageMenu,
  showSignUp,
  showLogin,
  loginRedirect,
  signUpLabel,
  showFooter,
  showUserMenu,
  showNotificationPanel,
  showTutorials,
  audience,
  showSmallFooter,
  showLinks
}) => {
  const classes = useStyles();
  const dialogRef = React.useRef(null);
  const { isAuthenticated, user } = useAuthentication();
  const team = useTeam();

  React.useEffect(() => {
    //Hidden user profile mailchimp form
    // isAuthenticated &&
    //   getAccessToken().then((token) => {
    //     getProfile(token, team._id).then((res) => {
    //       (res.profile && (res.profile.profession || res.profile.entityType)) ||
    //         (dialogRef.current && dialogRef.current.show());
    //     });
    //   });

    if (user && localStorage.getItem('userLogin')) {
      localStorage.removeItem('userLogin');
      window.dataLayer.push({
        event: 'user',
        email: user.email,
        name: user.name || user.username || user.nickname
      });

      // TODO: find another way to do this for intercom
      localStorage.setItem(
        'user',
        JSON.stringify({
          email: user.email,
          name: user.name,
          username: user.username || user.nickname
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorAlertBoundary>
      <Box className={clsx(classes.container, containerClassName)}>
        <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 6000 }} />
        <Navbar
          lang={lang}
          className={navbarClassName || ''}
          showSignUp={showSignUp}
          showLogin={showLogin}
          loginRedirect={loginRedirect}
          showLanguageMenu={showLanguageMenu}
          signUpLabel={signUpLabel}
          showUserMenu={showUserMenu}
          showNotificationPanel={showNotificationPanel}
          showTutorials={showTutorials}
          endContent={endNavbarContent}
          showLinks={showLinks}
          audience={audience}
        >
          {navbarContent}
        </Navbar>
        <Box className={classes.content} id="woxo-main-container">
          {children}
        </Box>
        {showFooter && <Footer className={classes.footer} showSmallFooter={showSmallFooter} />}
      </Box>
      <UserProfessionJob forwardRef={dialogRef} />
    </ErrorAlertBoundary>
  );
};

Main.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  showFooter: PropTypes.bool,
  lang: PropTypes.oneOf(['', 'pt', 'es']),
  navbarContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  endNavbarContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  navbarClassName: PropTypes.string,
  showSignUp: PropTypes.bool,
  showLogin: PropTypes.bool,
  loginRedirect: PropTypes.bool,
  showLanguageMenu: PropTypes.bool,
  showUserMenu: PropTypes.bool,
  signUpLabel: PropTypes.string,
  containerClassName: PropTypes.string,
  showNotificationPanel: PropTypes.bool,
  showTutorials: PropTypes.bool,
  audience: PropTypes.string,
  showSmallFooter: PropTypes.bool,
  showLinks: PropTypes.bool
};

Main.defaultProps = {
  children: null,
  lang: '',
  navbarContent: null,
  endNavbarContent: null,
  navbarClassName: null,
  showLanguageMenu: true,
  showSignUp: true,
  showLogin: true,
  loginRedirect: true,
  signUpLabel: null,
  showFooter: true,
  showUserMenu: true,
  containerClassName: null,
  showNotificationPanel: true,
  showTutorials: true,
  audience: 'widget',
  showSmallFooter: false,
  showLinks: true
};

export default withAudience(Main);
