export const shuffle = (numberItems, presets = []) => {
  let styles = presets.sort(() => Math.random() - 0.5);
  let data = [];
  let j = 0;

  if (!!styles.length) {
    for (let i = 0; i < numberItems; i++) {
      if (styles.length <= j) j = 0;
      const { Font, BackgroundColor, TextColor, TextStyle, Music } = styles[j];
      j++;

      data.push({
        font: Font,
        backgroundColor: BackgroundColor,
        textColor: TextColor,
        textStyle: TextStyle,
        music: Music
      });
    }
  }

  return data;
};
