import React from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tutorial: {
    color: '#4686FF',
    textDecoration: 'underline #4686FF',
    cursor: 'pointer'
  }
}));

const Tutorial = ({ text, className }) => {
  const classes = useStyles();

  return (
    <>
      <span
        className="wistia_embed wistia_async_w6vijm3rmc popover=true popoverAnimateThumbnail=true popoverContent=link"
        style={{ display: 'inline', position: 'relative' }}
      >
        <span className={clsx(classes.tutorial, className)}>{text}</span>
      </span>
      <Script
        url="https://fast.wistia.com/embed/medias/w6vijm3rmc.jsonp"
        attributes={{ async: true }}
      />
      <Script url="https://fast.wistia.com/assets/external/E-v1.js" attributes={{ async: true }} />
    </>
  );
};

Tutorial.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

Tutorial.defaultProps = {
  text: 'tutorial.',
  className: null
};

export default Tutorial;
