import { v4 } from 'uuid';

export const sampleRestaurantAboutUsArtboardVideoData = [
  {
    videoDefinition: [
      {
        text: 'Nothing more deliciosus than...',
        type: 'About Us'
      },
      {
        text: 'Enjoy delicious food!',
        type: 'About Us'
      },
      {
        text: 'Eat. Drink. Celebrate.',
        type: 'About Us'
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/541805125.hd.mp4?s=11ef1ff2c9909ff8f637b41de79b5e8fa3170e8e&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F541805125.sd.mp4%3Fs%3D19d822437a7c7219c7356eedd522397870baefe6%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7662734%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/541805125.hd.mp4?s=11ef1ff2c9909ff8f637b41de79b5e8fa3170e8e&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F541805125.sd.mp4%3Fs%3D19d822437a7c7219c7356eedd522397870baefe6%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7662734%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/578571572.hd.mp4?s=561a23bb3b3a7abc5c62821850353f0b6365b5ab&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F578571572.sd.mp4%3Fs%3D4d105de51d9773a69fa3922e20a997abdef76d73%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D338&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8879534%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/578571572.hd.mp4?s=561a23bb3b3a7abc5c62821850353f0b6365b5ab&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F578571572.sd.mp4%3Fs%3D4d105de51d9773a69fa3922e20a997abdef76d73%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D338&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8879534%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/556344582.hd.mp4?s=ddef1f05272ac771e84dccf7d99c2149519df97c&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F556344582.sd.mp4%3Fs%3D129c9deb6964931e131f08324d7df6400e31a7ba%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8106285%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/556344582.hd.mp4?s=ddef1f05272ac771e84dccf7d99c2149519df97c&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F556344582.sd.mp4%3Fs%3D129c9deb6964931e131f08324d7df6400e31a7ba%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8106285%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'Nothing more deliciosus than...\n\nEnjoy delicious food!\n\nEat. Drink. Celebrate.\n\nNothing more satisfying than good food...',
    type: 'About Us',
    id: v4()
  }
];
