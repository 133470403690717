import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import clsx from 'clsx';
import Router from 'next/router';

const VideosMenu = ({ items }) => {
  const classes = useStyles();

  const handleVideoMenuItemClick = ({ name, to }) => {
    if (name === 'Text to Videos') {
      window.location.href = to;
    } else {
      Router.push(to);
    }
  };

  return (
    <>
      {items.map((item) => (
        <Box
          key={item.name}
          className={clsx(classes.templateContent, 'videoContent')}
          onClick={() => {
            handleVideoMenuItemClick(item);
          }}
        >
          <Box className={classes.templateImgBoxVideo}>
            <img src={item.image} alt={`${item.name} video thumbnail`} />
          </Box>
          <Box className={classes.categoriesBox}>
            <Typography className={classes.categoryName}>{item.name}</Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default VideosMenu;
