import { v4 } from 'uuid';
import { sampleRestaurantReservationsArtboardVideoData as version_0_1 } from './v0_1/sampleRestaurantReservations';
import { sampleRestaurantReservationsArtboardVideoData as version_1 } from './v1/sampleRestaurantReservations';
import { sampleRestaurantReservationsArtboardVideoData as version_2 } from './v2/sampleRestaurantReservations';

const version_0 = [
  {
    videoDefinition: [
      {
        text: 'Tired of waiting to be seated?',
        type: 'Reservations'
      },
      {
        text: 'No more waiting for your table to be ready...',
        type: 'Reservations'
      },
      {
        text: 'Tired of waiting for your table?',
        type: 'Reservations'
      },
      {
        text: 'Waiting to be seated is not fun...',
        type: 'Reservations'
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/578367094.hd.mp4?s=750ac47a229deb1751cb1f4d2fa9568c872fb459&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F578367094.sd.mp4%3Fs%3Dcf170144f6c77f2eea255bacdd2b91f548d3cc20%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8871286%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/578367094.hd.mp4?s=750ac47a229deb1751cb1f4d2fa9568c872fb459&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F578367094.sd.mp4%3Fs%3Dcf170144f6c77f2eea255bacdd2b91f548d3cc20%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8871286%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/559960987.hd.mp4?s=e4a7cd904378750d5ec5fae2e24a547c112a1ce8&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F559960987.sd.mp4%3Fs%3D324a7f478eefb57438117fdcca448e864c57a695%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8246929%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/559960987.hd.mp4?s=e4a7cd904378750d5ec5fae2e24a547c112a1ce8&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F559960987.sd.mp4%3Fs%3D324a7f478eefb57438117fdcca448e864c57a695%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8246929%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/444196978.hd.mp4?s=65b2a2fd7d0a19c8b83e965c4f535896053d6f32&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F444196978.sd.mp4%3Fs%3D6e35387dabde762ce0e72c1ba8f43e9b5a3e4bf7%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5019033%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/444196978.hd.mp4?s=65b2a2fd7d0a19c8b83e965c4f535896053d6f32&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F444196978.sd.mp4%3Fs%3D6e35387dabde762ce0e72c1ba8f43e9b5a3e4bf7%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5019033%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-4.jpeg',
        preview:
          'https://player.vimeo.com/external/540532482.hd.mp4?s=80418831faa1958a25b7e2bc062bac379016400a&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F540532482.sd.mp4%3Fs%3D79092c73cb7e50aefb46d71eade5c32adc57163b%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7618408%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/540532482.hd.mp4?s=80418831faa1958a25b7e2bc062bac379016400a&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F540532482.sd.mp4%3Fs%3D79092c73cb7e50aefb46d71eade5c32adc57163b%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7618408%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'Tired of waiting to be seated?\n\nNo more waiting for your table to be ready...\n\nTired of waiting for your table?\n\nWaiting to be seated is not fun...',
    type: 'Reservations',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: 'Reserve your table now!',
        type: 'Reservations'
      },
      {
        text: 'Make your online reservation today.',
        type: 'Reservations'
      },
      {
        text: "We're open for reservations.",
        type: 'Reservations'
      },
      {
        text: "That's why we're now open for reservations!",
        type: 'Reservations'
      }
    ],
    medias: [
      {
        path: 'Fpreview-11.jpeg',
        preview:
          'https://player.vimeo.com/external/483728047.hd.mp4?s=8c819a74c22a19d722468c7a3ea5aa7db152a49f&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F483728047.sd.mp4%3Fs%3D0722aca27eab9965fcf4768c29d24b52d119c537%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5989765%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/483728047.hd.mp4?s=8c819a74c22a19d722468c7a3ea5aa7db152a49f&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F483728047.sd.mp4%3Fs%3D0722aca27eab9965fcf4768c29d24b52d119c537%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5989765%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-22.jpeg',
        preview:
          'https://player.vimeo.com/external/412359597.hd.mp4?s=9f9da5e8573b49127a6e3cae0a6f81babd6704bd&profile_id=173&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F412359597.sd.mp4%3Fs%3Df40f6ebf6f263dc3c873f5b0c3ace90e0e5e0084%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4254304%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/412359597.hd.mp4?s=9f9da5e8573b49127a6e3cae0a6f81babd6704bd&profile_id=173&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F412359597.sd.mp4%3Fs%3Df40f6ebf6f263dc3c873f5b0c3ace90e0e5e0084%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4254304%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-33.jpeg',
        preview:
          'https://player.vimeo.com/external/499152431.hd.mp4?s=ad2dff2f665d3ce733128dbf13cebac2b7f8cb3d&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F499152431.sd.mp4%3Fs%3D0c12968c941c5093f144dfb8d1ddcf700710c0df%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6466344%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/499152431.hd.mp4?s=ad2dff2f665d3ce733128dbf13cebac2b7f8cb3d&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F499152431.sd.mp4%3Fs%3D0c12968c941c5093f144dfb8d1ddcf700710c0df%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6466344%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-44.jpeg',
        preview:
          'https://player.vimeo.com/external/504678480.hd.mp4?s=d0e7290d2268654dccaa419191ab1466da9d90f0&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F504678480.sd.mp4%3Fs%3Dbb758670faf8899119480bed2d266e7b4e975f6f%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6602248%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/504678480.hd.mp4?s=d0e7290d2268654dccaa419191ab1466da9d90f0&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F504678480.sd.mp4%3Fs%3Dbb758670faf8899119480bed2d266e7b4e975f6f%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6602248%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "Reserve your table now!\n\nMake your online reservation today.\n\nWe're open for reservations.\n\nThat's why we're now open for reservations!",
    type: 'Reservations',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: "Like this post if you're ready to make a reservation!",
        type: 'Reservations'
      },
      {
        text: 'SAVE this post and avoid the wait!',
        type: 'Reservations'
      },
      {
        text: "Tag the person you'd like to make a reservation with!",
        type: 'Reservations'
      },
      {
        text: 'Drop a 😋 if you agree!',
        type: 'Reservations'
      }
    ],
    medias: [
      {
        path: 'Fpreview-111.jpeg',
        preview:
          'https://player.vimeo.com/external/314613173.hd.mp4?s=db8fde1feddc0e962723d80fc078261ceafe3d61&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F314613173.sd.mp4%3Fs%3D93f9817d7fa48e43847dbbdcfb05d080a96f6d17%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F1858094%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/314613173.hd.mp4?s=db8fde1feddc0e962723d80fc078261ceafe3d61&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F314613173.sd.mp4%3Fs%3D93f9817d7fa48e43847dbbdcfb05d080a96f6d17%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F1858094%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-222.jpeg',
        preview:
          'https://player.vimeo.com/external/512797460.hd.mp4?s=d973d1163725a1caf23c55319bdaa96843ff5859&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F512797460.sd.mp4%3Fs%3D5426b351e8c148c87a0e5359d959eed49afc2ee1%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6839669%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/512797460.hd.mp4?s=d973d1163725a1caf23c55319bdaa96843ff5859&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F512797460.sd.mp4%3Fs%3D5426b351e8c148c87a0e5359d959eed49afc2ee1%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6839669%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-333.jpeg',
        preview:
          'https://player.vimeo.com/external/490788903.hd.mp4?s=e82376e0b5719d16f5b4f38cb9d7bde4dc1839c1&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F490788903.sd.mp4%3Fs%3Df21f70c2564cb180207de7cd4d3c3c4d6fa4daeb%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6185445%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/490788903.hd.mp4?s=e82376e0b5719d16f5b4f38cb9d7bde4dc1839c1&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F490788903.sd.mp4%3Fs%3Df21f70c2564cb180207de7cd4d3c3c4d6fa4daeb%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6185445%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-444.jpeg',
        preview:
          'https://player.vimeo.com/external/496856419.hd.mp4?s=e183741bb77159869f326c76710b26b256432ff6&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F496856419.sd.mp4%3Fs%3D45095270647c286cea4437fc2838779416aa4d85%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6395999%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/496856419.hd.mp4?s=e183741bb77159869f326c76710b26b256432ff6&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F496856419.sd.mp4%3Fs%3D45095270647c286cea4437fc2838779416aa4d85%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6395999%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "Like this post if you're ready to make a reservation!\n\nSAVE this post and avoid the wait!\n\nTag the person you'd like to make a reservation with!\n\nDrop a 😋 if you agree!",
    type: 'Reservations',
    id: v4()
  }
];

export const sampleRestaurantReservationsArtboardVideoData = (version = null) => {
  let data = [];
  switch (version) {
    case '0_1':
      data = version_0_1;
      break;
    case '1':
      data = version_1;
      break;
    case '2':
      data = version_2;
      break;
    default:
      data = version_0;
      break;
  }

  return data;
};
