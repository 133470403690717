import React from 'react';
import PropTypes from 'prop-types';
import MuiLink from '@material-ui/core/Link';
import translate from '../../data/translations';
import { makeStyles } from '@material-ui/core/styles';

const useLoginStyles = makeStyles({
  root: {
    // color: 'inherit',
    // paddingLeft: '16px',
    // paddingRight: '16px',
    fontWeight: 500,
    flexShrink: 0,
    borderRadius: 45,
    // color: '#fff',
    color: '#414042',
    // padding: '4px 36px',
    padding: '7px 36px',
    marginRight: 5,
    textTransform: 'none',
    fontSize: 16,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      // backgroundColor: 'rgba(255,255,255,.2)'
      backgroundColor: '#EEEEEE'
    }
  }
});

const LoginButton = ({ onClick, label, lang, props }) => {
  const classes = useLoginStyles();
  return (
    <MuiLink
      component="button"
      onClick={onClick}
      className={classes.root}
      variant="body1"
      underline="always"
      {...props}
      id="loginButton"
    >
      {label || translate('logIn', lang, 'Log in')}
    </MuiLink>
  );
};

LoginButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  lang: PropTypes.string
};

LoginButton.defaultProps = {
  onClick: () => {},
  lang: '',
  label: null
};

export default LoginButton;
