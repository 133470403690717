import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import clsx from 'clsx';
import useStyles from './styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import WidgetsMenu from './WidgetsMenu';
import VideosMenu from './VideosMenu';

const widgetCategories = [
  {
    name: 'Instagram',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1624588223/Woxo/Videos_Widgets/Widgets/ig-carousel.png',
    categories: [
      {
        name: 'Carousels',
        value: 'carousel'
      },
      {
        name: 'Grids',
        value: 'grid'
      },
      {
        name: 'Hero',
        value: 'hero'
      },
      {
        name: 'Small Businesses',
        value: 'small-business'
      },
      {
        name: 'Bloggers',
        value: 'bloggers'
      },
      {
        name: 'Non-profits',
        value: 'non-profits'
      },
      {
        name: 'Influencers',
        value: 'influencers'
      }
    ],
    to: '/instagram-widget'
  },
  {
    name: 'TikTok',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1632148897/Woxo/Videos_Widgets/Widgets/tk-grid-1.png',
    categories: [
      {
        name: 'Carousels',
        value: 'carousel'
      },
      {
        name: 'Grids',
        value: 'grid'
      }
    ],
    to: '/tiktok-widget'
  },
  {
    name: 'YouTube',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1624588223/Woxo/Videos_Widgets/Widgets/yt-carousel.png',
    categories: [
      {
        name: 'Carousels',
        value: 'carousel'
      },
      {
        name: 'Grids',
        value: 'grid'
      },
      {
        name: 'Music',
        value: 'music'
      },
      {
        name: 'Sports',
        value: 'sports'
      },
      {
        name: 'News',
        value: 'news'
      },
      {
        name: 'Popular',
        value: 'popular'
      }
    ],
    to: '/youtube-widget'
  },
  {
    name: 'Twitter',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1624588223/Woxo/Videos_Widgets/Widgets/tw-carousel.png',
    categories: [
      {
        name: 'Carousels',
        value: 'carousel'
      },
      {
        name: 'Grids',
        value: 'grid'
      },
      {
        name: 'Small Businesses',
        value: 'small-business'
      },
      {
        name: 'Bloggers',
        value: 'bloggers'
      },
      {
        name: 'Non-profits',
        value: 'non-profits'
      },
      {
        name: 'Influencers',
        value: 'influencers'
      }
    ],
    to: '/twitter-widget'
  },
  {
    name: 'Facebook',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1624651068/Woxo/Videos_Widgets/Widgets/Facebook/fc-carousel.png',
    categories: [
      {
        name: 'Carousel',
        value: 'carousel',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1624650024/Woxo/Videos_Widgets/Widgets/Facebook/fc-carousel.png'
      },
      {
        name: 'Grid',
        value: 'grid',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1624650024/Woxo/Videos_Widgets/Widgets/Facebook/fc-grid.png'
      }
    ],
    to: '/facebook-widget'
  }
];

const videoCategoriesAI = [
  {
    name: 'Idea to Videos',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1684208172/Woxo/Idea%20to%20Videos/main_og_image.png',
    to: '/idea-to-video/app'
  },
  {
    name: 'Text to Videos',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1674494543/Woxo/Video_Tools/short.png',
    to: '/text-to-video'
  },
  {
    name: 'ChatGPT Plugin',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1687397237/Woxo/Video_Tools/chatgpt2.png',
    to: '/vidgpt'
  }
];
const videoCategories = [
  {
    name: 'Bulk Creator',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1674494544/Woxo/Video_Tools/text2video.png',
    to: '/bulk-creator'
  },
  {
    name: 'Sheet to Videos',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1674494546/Woxo/Video_Tools/sheet2video.png',
    to: '/video/templates'
  },
  {
    name: 'Template to Videos',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1674494544/Woxo/Video_Tools/template2video.png',
    to: '/sheet-to-video/templates'
  }
];

const ExpandedMenu = ({ type }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [showSkeleton, setShowSkeleton] = React.useState(true);

  const rootRef = React.useRef(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = () => {
    setAnchorEl(rootRef.current);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (type === 'videoAI') setItems(videoCategoriesAI);
    if (type === 'widget') setItems(widgetCategories);
    if (type === 'video') setItems(videoCategories);
    setTimeout(() => {
      setShowSkeleton(false);
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: dependencies should be included
  }, []);

  return (
    <>
      <a ref={rootRef} onClick={handlePopoverOpen} className={classes.menuLink}>
        {type === 'videoAI' && 'AI Video Tools'}
        {type === 'widget' && 'Widgets'}
        {type === 'video' && 'Video tools'}
        {open ? (
          <KeyboardArrowUpIcon style={{ color: '#414042' }} />
        ) : (
          <KeyboardArrowDownIcon style={{ color: '#414042' }} />
        )}
      </a>
      <Popover
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper: type === 'video' ? classes.paperVideo : classes.paperWidget
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        className={clsx(classes.popper)}
      >
        <Box className={clsx(classes.popoverContent, type === 'video' ? 'video' : 'widgets')}>
          <div className={classes.btnCloseBox}>
            <IconButton onClick={handlePopoverClose} className={classes.closeIcon}>
              <ClearIcon />
            </IconButton>
          </div>
          {showSkeleton ? (
            <>
              {[1, 2, 3, 4].map((v) => (
                <Box key={v} className={classes.templateContent}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    height={160}
                    style={{ borderRadius: '8px' }}
                  />
                </Box>
              ))}
            </>
          ) : type === 'video' ? (
            <VideosMenu items={items} />
          ) : type === 'widgets' ? (
            <WidgetsMenu items={items} />
          ) : (
            <VideosMenu items={items} />
          )}
        </Box>
      </Popover>
    </>
  );
};

ExpandedMenu.propTypes = {
  type: PropTypes.string
};

ExpandedMenu.defaultProps = {
  type: 'video'
};

export default ExpandedMenu;
