import {
  isUrl,
  isTextAlign,
  isString,
  isTextStyle,
  isColor,
  isTransition,
  isVideoShape,
  isTextAnimation,
  isMood,
  isAudioUrl,
  isFontUrl,
  isMediaUrl,
  clone
} from './javascript';
import Presets from '../data/template-generator/presets';
import Textaligns from '../data/template-generator/textaligns';
import { text } from '~/components/DataSourceGrid/editor/styles';

const presets = Presets.sort(() => Math.random() - 0.5);
const textaligns = Textaligns.sort(() => Math.random() - 0.5);

const getOrientation = (videoShape) => {
  let orientation = 'portrait';
  if (videoShape === '16:9') {
    orientation = 'landscape';
  } else if (videoShape === '1:1' || videoShape === '4:5') {
    orientation = 'square';
  }
  return orientation;
};
const parseValidator = (value, { type, name }, index, whereFrom) => {
  if (/^\{\{[A-Za-z0-9_-]+\}\}$/g.test(value)) return { isValid: true };

  switch (type) {
    case 'text':
      return {
        isValid: isString(value),
        message: `Video #${index}.  Value of "${name}" not accepted, must be a String`
      };

    case 'media':
      return {
        isValid: whereFrom === 'templates' || (isUrl(value) && isMediaUrl(value)),
        message:
          typeof value === 'object'
            ? `Video #${index}. Value of "${name}" not accepted, keyword not found in Pexels.`
            : `Video #${index}. Value of "${name}" not accepted.`
      };
    case 'brand':
      return {
        isValid: value === '' || isUrl(value),
        message: `Video #${index}. Value of "${name}" not accepted.`
      };
    case 'music':
      return {
        isValid:
          whereFrom === 'templates' || (isUrl(value) && isAudioUrl(value)) || value === '/original',
        message: `Video #${index}. Value of "${name}" not accepted, input a valid audio URL.`
      };
    case 'font':
      return {
        isValid: whereFrom === 'templates' || (isUrl(value) && isFontUrl(value)),
        message: `Video #${index}. Value of "${name}" not accepted, invalid url.`
      };
    case 'color':
      return {
        isValid: isColor(value),
        message: `Video #${index}. Value of "${name}" not accepted.`
      };
    case 'text-style':
      return {
        isValid: isTextStyle(value),
        message: `Video #${index}. Value of "${name}" not accepted.`
      };

    case 'text-position':
      return {
        isValid: isTextAlign(value),
        message: `Video #${index}. Value of "${name}" not accepted.`
      };
    case 'text-animation':
      return {
        isValid: isTextAnimation(value),
        message: `Video #${index}. Value of "${name}" not accepted.`
      };
    case 'transition':
      return {
        isValid: isTransition(value),
        message: `Video #${index}. Value of "${name}" not accepted.`
      };
    case 'gradient':
      return {
        isValid: value.includes('(') && value.includes(')'),
        message: `Video #${index}. Value of "${name}" not accepted.`
      };
    default:
      return { isValid: true };
  }
};

export const dataValidate = (_data, whereFrom = 'editor', idxTemplate = 0) => {
  const errors = [];
  const warnings = [];
  const validData = [];

  _data.map((d, i) => {
    let totalDuration = 0;
    const { data, schema, template } = d;
    template.clips.map((c) => {
      totalDuration += c.duration;
    });

    let isValid = true;
    if (totalDuration > 60) {
      isValid = false;
      errors.push({
        isValid,
        message: `Video #${
          i + 1
        }. Estimate duration of video is greater than 60s. Reduces number of scenes`,
        index: i,
        name: 'general'
      });
    }

    let medias = [];
    let texts = [];
    let titles = [];

    Object.keys(data).forEach((key, index) => {
      const mediaSplit = key.split(' ');
      const titleSplit = key.split(' ');
      const textSplit = key.split(' ');

      if (mediaSplit[0] === 'Media' && Number(mediaSplit[1])) {
        medias.push(mediaSplit[1]);
      }
      if (titleSplit[0] === 'Title' && Number(titleSplit[1])) {
        texts.push(titleSplit[1]);
      }
      if (textSplit[0] === 'Text' && Number(textSplit[1])) {
        titles.push(textSplit[1]);
      }
    });

    if (
      !medias.every((media, mindex) => {
        if (mindex === 0 && media === '1') return true;
        else return Number(media) - 1 === Number(medias[mindex - 1]);
      }) ||
      !texts.every((media, mindex) => {
        if (mindex === 0 && media === '1') return true;
        else return Number(media) - 1 === Number(medias[mindex - 1]);
      }) ||
      !titles.every((media, mindex) => {
        if (mindex === 0 && media === '1') return true;
        else return Number(media) - 1 === Number(medias[mindex - 1]);
      })
    ) {
      isValid = false;
      errors.push({
        isValid,
        message: `Video #${i + 1}. The data has errors due to missing required column labels`,
        index: i,
        name: 'general'
      });
      return false;
    }

    if (
      (!data['Media 1'] || data['Media 1' === '']) &&
      (!data['BackgroundGradient'] || data['BackgroundGradient' === ''])
    ) {
      isValid = false;
      errors.push({
        isValid,
        message: `Video #${
          i + 1
        }. The data was pulled with errors due to missing required column labels, such as Media 1 or BackroundGradient.`,
        index: i,
        name: 'general'
      });
    }

    if (
      data.TextAnimation.toLowerCase() !== 'default' &&
      data.TextAnimation.toLowerCase() !== 'typewriter'
    ) {
      data.TextAnimation = 'Default';
      warnings.push({
        isValid,
        message: `Video #${
          i + 1
        }. Value of TextAnimation not accepted, default value has been set automatically.`,
        title: `Warning`,
        index: i,
        name: 'Text Animation'
      });
    } else {
      if (data.TextAnimation.toLowerCase() === 'default') data.TextAnimation = 'Default';
      if (data.TextAnimation.toLowerCase() === 'typewriter') data.TextAnimation = 'Typewriter';
    }

    if (data.ScenesLength) {
      let arraySC = Array.isArray(data.ScenesLength)
        ? clone(data.ScenesLength)
        : clone(data.ScenesLength.split(','));
      let ScenesLengthWarning = false;

      arraySC = arraySC.map((sc) => {
        if (sc === '0' || (sc !== 'smart' && (!Number.isInteger(Number(sc)) || Number(sc) <= 0))) {
          if (ScenesLengthWarning) return 'smart';
          ScenesLengthWarning = true;

          warnings.push({
            isValid,
            message: `Video #${
              i + 1
            }. Value of ScenesLength not accepted, default value has been set automatically.`,
            title: `Warning`,
            index: i,
            name: 'Scene Length'
          });

          return 'smart';
        }
        return sc;
      });

      data.ScenesLength = clone(arraySC);
    }

    if (!isTextStyle(data.TextStyle)) {
      const pop = presets.shift();
      data.TextStyle = pop.name;
      presets.push(pop);
      warnings.push({
        isValid,
        message: `Video #${
          i + 1
        }. Value of TextStyle not accepted, default value has been set automatically.`,
        title: `Warning`,
        index: i,
        name: 'Text Style'
      });
    }

    if (!isTextAlign(data.TextPosition)) {
      const pop = textaligns.shift();
      data.TextPosition = pop.name;
      textaligns.push(pop);
      warnings.push({
        isValid,
        message: `Video #${
          i + 1
        }. Value of TextPosition not accepted, default value has been set automatically.`,
        title: `Warning`,
        index: i,
        name: 'Text Position'
      });
    }

    if (!isTransition(data.Transition)) {
      data.Transition = 'random';
      warnings.push({
        isValid,
        message: `Video #${
          i + 1
        }. Value of Transition not accepted, default value has been set automatically.`,
        title: `Warning`,
        index: i,
        name: 'Transition'
      });
    }

    if (
      (data.FontSize && !Number(data.FontSize)) ||
      Number(data.FontSize) < 5 ||
      Number(data.FontSize) > 28
    ) {
      data.FontSize = '12';
      warnings.push({
        isValid,
        message: `Video #${
          i + 1
        }. Value of FontSize not accepted, default value has been set automatically.`,
        title: `Warning`,
        index: i,
        name: 'Font Size'
      });
    }

    if (!isColor(data.TextColor)) {
      data.TextColor = '#fff';
      warnings.push({
        isValid,
        message: `Video #${
          i + 1
        }. Value of TextColor not accepted, default value has been set automatically.`,
        title: `Warning`,
        index: i,
        name: 'Text Color'
      });
    }

    if (!isColor(data.BackgroundColor)) {
      data.TextColor = 'rgba(0, 0, 0, .75)';
      warnings.push({
        isValid,
        message: `Video #${
          i + 1
        }. Value of BackgroundColor not accepted, default value has been set automatically.`,
        title: `Warning`,
        index: i,
        name: 'Background Color'
      });
    }

    if (
      (data.TextStroke !== '0' && !Number(data.TextStroke)) ||
      Number(data.TextStroke) < 0 ||
      Number(data.TextStroke) > 25
    ) {
      data.TextStroke = '0';
      warnings.push({
        isValid,
        message: `Video #${
          i + 1
        }. Value of TextStroke not accepted,default value has been set automatically.`,
        title: `Warning`,
        index: i,
        name: 'Text Stroke'
      });
    }

    if (
      (data.TextShadow !== '0' && !Number(data.TextShadow)) ||
      Number(data.TextShadow) < 0 ||
      Number(data.TextShadow) > 60
    ) {
      data.TextShadow = '0';
      warnings.push({
        isValid,
        message: `Video #${
          i + 1
        }. Value of TextShadow not accepted, default value has been set automatically.`,
        title: `Warning`,
        index: i,
        name: 'Text Shadow'
      });
    }

    if (data['Video Shape']) {
      const VideoShape = data['Video Shape'];

      if (VideoShape.includes('1:01')) {
        data['Video Shape'] = '1:1';
      }

      if (VideoShape.includes('16:09')) {
        data['Video Shape'] = '16:9';
      }

      if (VideoShape.includes('4:05')) {
        data['Video Shape'] = '4:5';
      }

      if (!isVideoShape(VideoShape)) {
        data['Video Shape'] = '9:16';
        warnings.push({
          isValid,
          message: `Video #${
            i + 1
          }. Value of Video Shape not accepted, default value has been set automatically.`,
          title: `Warning`,
          index: i,
          name: 'Video Shape'
        });
      }
    }

    schema.map((s) => {
      const value = data[s.name];
      const parse = value === '' ? { isValid: true } : parseValidator(value, s, i + 1, whereFrom);
      if (!parse.isValid) {
        isValid = false;
        errors.push({ ...parse, index: i, name: s.name, value, idxTemplate });
      }
    });

    if (isValid) validData.push(d);
  });

  return { errors, warnings, validData };
};

export const dataDefaults = (_data) => {
  const defaults = ['color', 'text-style', 'text-color'];
  const validData = [];
  _data.map((d, i) => {
    const { data, schema } = d;
    let isValid = true;
    schema.map((s) => {
      const value = data[s.name];
      const parse = parseValidator(value, s, i + 1);
      if (!parse.isValid) {
        isValid = false;
        errors.push({ ...parse, index: i, name: s.name });
      }
    });
    if (isValid) validData.push(d);
  });
  return { errors, validData };
};
