import * as yup from 'yup';

const emailSchema = yup.string().trim().email().required();
const usernameSchema = yup.string().trim().min(3).max(40).matches(/^\S+$/).required();

export function validateEmail(value: string) {
  return emailSchema.isValidSync(value);
}

export function validateUsername(value: string) {
  return usernameSchema.isValidSync(value);
}
