import { v4 } from 'uuid';
import { sampleRestaurantMenuArtboardVideoData as version_0_1 } from './v0_1/sampleRestaurantMenu';
import { sampleRestaurantMenuArtboardVideoData as version_1 } from './v1/sampleRestaurantMenu';
import { sampleRestaurantMenuArtboardVideoData as version_2 } from './v2/sampleRestaurantMenu';

const version_0 = [
  {
    videoDefinition: [
      {
        text: 'Celebrate with...',
        type: 'Menu'
      },
      {
        text: 'This is how we do it...',
        type: 'Menu'
      },
      {
        text: 'Eat to your heart’s content!',
        type: 'Menu'
      },
      {
        text: 'Let us show you how we do it...',
        type: 'Menu'
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/397970946.hd.mp4?s=bdd1e35e2ba95d7b673491e4c6bbc4571edcf293&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F397970946.sd.mp4%3Fs%3D4c9ad02237b31d13dc11d2292089af5dc5bbd3ef%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3944327%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/397970946.hd.mp4?s=bdd1e35e2ba95d7b673491e4c6bbc4571edcf293&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F397970946.sd.mp4%3Fs%3D4c9ad02237b31d13dc11d2292089af5dc5bbd3ef%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3944327%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/464577768.hd.mp4?s=7baf6e4784d196f1be2e83c9f81d8b997a2bbeac&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F464577768.sd.mp4%3Fs%3Dd471d95c6f6c3b0bb0b360eee66e2fee239b9689%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5515577%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/464577768.hd.mp4?s=7baf6e4784d196f1be2e83c9f81d8b997a2bbeac&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F464577768.sd.mp4%3Fs%3Dd471d95c6f6c3b0bb0b360eee66e2fee239b9689%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5515577%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/567357811.hd.mp4?s=83ff07c353cc71deb123437508cc5e61eac35e2e&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F567357811.sd.mp4%3Fs%3D86a604d842b46dabdae8dba3556bda3d2d6d0261%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8477264%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/567357811.hd.mp4?s=83ff07c353cc71deb123437508cc5e61eac35e2e&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F567357811.sd.mp4%3Fs%3D86a604d842b46dabdae8dba3556bda3d2d6d0261%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8477264%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-4.jpeg',
        preview:
          'https://player.vimeo.com/external/376190872.hd.mp4?s=e44da73c27ec3ebc506a9fe6417c6f836db5ea78&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F376190872.sd.mp4%3Fs%3D13acd68ea5b8e0a0325fe7c0321a0930952568c3%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3298012%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/376190872.hd.mp4?s=e44da73c27ec3ebc506a9fe6417c6f836db5ea78&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F376190872.sd.mp4%3Fs%3D13acd68ea5b8e0a0325fe7c0321a0930952568c3%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3298012%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'Celebrate with...\n\nThis is how we do it...\n\nEat to your heart’s content!\n\nLet us show you how we do it...',
    type: 'Menu',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: 'Have fun, taste good food.',
        type: 'Menu'
      },
      {
        text: 'Find. Explore. Taste',
        type: 'Menu'
      },
      {
        text: 'Great times. Great food.',
        type: 'Menu'
      },
      {
        text: 'Enjoy the taste of good food!',
        type: 'Menu'
      }
    ],
    medias: [
      {
        path: 'Fpreview-11.jpeg',
        preview:
          'https://player.vimeo.com/external/400923361.hd.mp4?s=9df8d6de36a683e495f1c2584bf1678457e7eeae&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F400923361.sd.mp4%3Fs%3De7a40fa4c7afa1d154be0596bf958b0bea8bf228%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4008536%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/400923361.hd.mp4?s=9df8d6de36a683e495f1c2584bf1678457e7eeae&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F400923361.sd.mp4%3Fs%3De7a40fa4c7afa1d154be0596bf958b0bea8bf228%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4008536%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-22.jpeg',
        preview:
          'https://player.vimeo.com/external/530988181.hd.mp4?s=5372956a5d7d1c433f0ef424a59e1eb2cf79d3c1&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F530988181.sd.mp4%3Fs%3D9be9b4e7f6232998d5ff0e6e4758adc55838f602%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7329493%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/530988181.hd.mp4?s=5372956a5d7d1c433f0ef424a59e1eb2cf79d3c1&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F530988181.sd.mp4%3Fs%3D9be9b4e7f6232998d5ff0e6e4758adc55838f602%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7329493%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-33.jpeg',
        preview:
          'https://player.vimeo.com/external/472397468.hd.mp4?s=4845a519789ebc5ebb99e8652616bea264c3436f&profile_id=173&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F472397468.sd.mp4%3Fs%3D8703cfdb00d790ae3339e940628bc7e1cba6fdec%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5706352%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/472397468.hd.mp4?s=4845a519789ebc5ebb99e8652616bea264c3436f&profile_id=173&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F472397468.sd.mp4%3Fs%3D8703cfdb00d790ae3339e940628bc7e1cba6fdec%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5706352%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-44.jpeg',
        preview:
          'https://player.vimeo.com/external/544862622.hd.mp4?s=30374dee537072bdd5becb2ab4f4210a0d4ff915&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F544862622.sd.mp4%3Fs%3Deb9e5d489a29d0a7af88be78c57d0bb3f0ed317f%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7772235%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/544862622.hd.mp4?s=30374dee537072bdd5becb2ab4f4210a0d4ff915&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F544862622.sd.mp4%3Fs%3Deb9e5d489a29d0a7af88be78c57d0bb3f0ed317f%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7772235%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'Have fun, taste good food.\n\nFind. Explore. Taste\n\nGreat times. Great food.\n\nEnjoy the taste of good food!',
    type: 'Menu',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: 'Like if you want to see more delicious food!',
        type: 'Menu'
      },
      {
        text: "Gimme a 😋 if you'd like to see more posts like this!",
        type: 'Menu'
      },
      {
        text: "Tag the person you'd like to share good mood food with!",
        type: 'Menu'
      },
      {
        text: 'SAVE this post to remember to order this on your next visit!',
        type: 'Menu'
      }
    ],
    medias: [
      {
        path: 'Fpreview-111.jpeg',
        preview:
          'https://player.vimeo.com/external/487635379.hd.mp4?s=1d2ddb70fe692606399854a17c3596aaa01d9e2d&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F487635379.sd.mp4%3Fs%3D22bb6d7cf341643adcf0bafecc0f82907323d7f3%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6092984%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/487635379.hd.mp4?s=1d2ddb70fe692606399854a17c3596aaa01d9e2d&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F487635379.sd.mp4%3Fs%3D22bb6d7cf341643adcf0bafecc0f82907323d7f3%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6092984%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-222.jpeg',
        preview:
          'https://player.vimeo.com/external/468865638.hd.mp4?s=9b3e729666748ba26ab2d88b7831e76fa7362d2e&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F468865638.sd.mp4%3Fs%3Dff71f2fb428237b3237db2e67c5c67cc18b590c3%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5615195%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/468865638.hd.mp4?s=9b3e729666748ba26ab2d88b7831e76fa7362d2e&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F468865638.sd.mp4%3Fs%3Dff71f2fb428237b3237db2e67c5c67cc18b590c3%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5615195%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-333.jpeg',
        preview:
          'https://player.vimeo.com/external/411351152.hd.mp4?s=dfa2ec894d111ad8eca3f2fdab31143203f654fa&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F411351152.sd.mp4%3Fs%3Dc82df6f0a235f2db0fd3f4fc8a1d744cba5fabb3%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4230556%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/411351152.hd.mp4?s=dfa2ec894d111ad8eca3f2fdab31143203f654fa&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F411351152.sd.mp4%3Fs%3Dc82df6f0a235f2db0fd3f4fc8a1d744cba5fabb3%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4230556%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-444.jpeg',
        preview:
          'https://player.vimeo.com/external/480128260.hd.mp4?s=842529b2934111cbbb1cefbf4bc933850cee84a9&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F480128260.sd.mp4%3Fs%3D0b4bc0a4578fb5b0af0b7556d047f239b5ad8781%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5904803%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/480128260.hd.mp4?s=842529b2934111cbbb1cefbf4bc933850cee84a9&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F480128260.sd.mp4%3Fs%3D0b4bc0a4578fb5b0af0b7556d047f239b5ad8781%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5904803%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "Like if you want to see more delicious food!\n\nGimme a 😋 if you'd like to see more posts like this!\n\nTag the person you'd like to share good mood food with!\n\nSAVE this post to remember to order this on your next visit!",
    type: 'Menu',
    id: v4()
  }
];

export const sampleRestaurantMenuArtboardVideoData = (version = null) => {
  let data = [];
  switch (version) {
    case '0_1':
      data = version_0_1;
      break;
    case '1':
      data = version_1;
      break;
    case '2':
      data = version_2;
      break;
    default:
      data = version_0;
      break;
  }

  return data;
};
