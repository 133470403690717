module.exports = [
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506176/woxo-video-fonts-presets/Open_Sans/OpenSans-Regular.ttf',
    BackgroundColor: 'rgba(0, 0, 0, 1)',
    TextColor: 'rgba(255, 255, 255, 1)',
    TextStyle: 'Solid',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639026611/woxo-video/audios-v2/Dramatic/Speedy_-_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506176/woxo-video-fonts-presets/Open_Sans/OpenSans-Regular.ttf',
    BackgroundColor: 'rgba(0, 0, 0, 1)',
    TextColor: 'rgba(255, 255, 255, 1)',
    TextStyle: 'Criss Cross',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028442/woxo-video/audios-v2/Happy/Sunshine_-_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506176/woxo-video-fonts-presets/Open_Sans/OpenSans-Regular.ttf',
    BackgroundColor: 'rgba(0, 0, 0, 1)',
    TextColor: 'rgba(255, 255, 255, 1)',
    TextStyle: 'Step Center',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028992/woxo-video/audios-v2/Romantic/Together_-_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506176/woxo-video-fonts-presets/Open_Sans/OpenSans-Regular.ttf',
    BackgroundColor: 'rgba(0, 0, 0, 1)',
    TextColor: 'rgba(255, 255, 255, 1)',
    TextStyle: 'Line Up',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639028992/woxo-video/audios-v2/Romantic/Together_-_MOODHALO.mp3'
  }
];
