import quotes from './quotes';
import all from './default';
import aboutUs from './restaurantAboutUs';
import cateringEvents from './restaurantCateringEvents';
import deliveryServices from './restaurantDeliveryServices';
import menu from './restaurantMenu';
import reservations from './restaurantReservations';

export const presets = (category = 'Quotes') => {
  let data = [];
  switch (category) {
    case 'Quotes':
      data = quotes;
      break;
    case 'Restaurants_About Us':
      data = aboutUs;
      break;
    case 'Restaurants_Catering & Events':
      data = cateringEvents;
      break;
    case 'Restaurants_Delivery Services':
      data = deliveryServices;
      break;
    case 'Restaurants_Menu':
      data = menu;
      break;
    case 'Restaurants_Reservations':
      data = reservations;
      break;
    default:
      data = all;
      break;
  }
  return data;
};
