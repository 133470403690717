import React from 'react';
import ReactPlayer from 'react-player/lazy';
import LazyLoad from 'react-lazyload';
import { logoBase64Img } from '../../images/landingBase64';
import { useStylesTutorial } from './styles.js';
import { Box } from '@material-ui/core';

const VideoPlayerRaw = ({ url, isVideoReady, changeVideoReady }) => {
  const classes = useStylesTutorial();
  return (
    <>
      <Box className={classes.videoBox}>
        {isVideoReady === false && (
          <>
            <div className={classes.videoBlurredPreview}>
              <img src={logoBase64Img} alt="Loading Video" width="auto" height="80%" />
            </div>
            <div className={classes.videoBlankPaper} />
          </>
        )}
        <LazyLoad style={{ width: '100%' }} offset={100}>
          <div className={classes.videoPlayerDiv}>
            <ReactPlayer
              url={url}
              playing={true}
              muted={false}
              controls
              loop={false}
              width="100%"
              height="100%"
              onReady={() => {
                changeVideoReady();
              }}
            />
          </div>
        </LazyLoad>
      </Box>
    </>
  );
};

export default VideoPlayerRaw;
