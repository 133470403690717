import Toast from '../../../helpers/notification';
import translate from '../../../data/translations';
import { clone } from '~/helpers/javascript';
import Router from 'next/router';
import { findProjects, project, rename } from '~/services/video.service';
import { updateScenes } from '~/hooks/useScenesUpdate';
import { generateContentVideoData } from '../helpers/generateContentVideoData';
import { sampleContentVideoData } from '../data/sampleContentVideoData';
import { sampleQuotesArtboardVideoData } from '../data/sampleQuotes';

const replaceLocalMedias = (_woxoVideoData, _scenes) =>
  _woxoVideoData.map((s, index) => {
    let _data = s.data;
    for (const key in _data) {
      if (Object.hasOwnProperty.call(_data, key)) {
        const element = _data[key];
        if (key.startsWith('Media') && element.startsWith('blob')) {
          const scIdx = key.split(' ')[1];
          _data[key] = _scenes[Number(scIdx) - 1]?.medias[index]?.asset?.secure_url || element;
        }
      }
    }
    return { ...s, data: _data };
  });

export const useProjectStore = (set, get) => ({
  // state
  project: null,
  projectName: 'Untitled Project',
  projectCode: null,
  projectStatus: 'unsaved', // loading | unsaved | saving | saved | loaded
  projectVersions: [],
  // projectVersions: [{artboardVideoData,
  //  definitionVideoData: {textPosition, videoShape, videoShapeUse, fontSize, textColors, music ,
  //  contentVideoData: [{font, backgroundColor, textColor, textStyle, music, gradient}]},
  //  category, numberVideos}]
  activeProjectVersion: 0,
  undoActive: false,
  redoActive: false,
  undoApplied: false,

  // actions
  loadProjectAction: async (code) => {
    set({ projectCode: code, projectStatus: 'loading', loading: true, activeScene: 0 });
    const response = await findProjects({ code });
    if (response?.data) {
      const [_project] = response.data;
      const _woxoVideoData = [];
      const { videos, bulkConfig } = _project.meta;
      const { alias } = _project;

      for (let index = 0; index < videos.length; index++) {
        const { meta, ...suggestion } = videos[index];
        _woxoVideoData.push(suggestion);
      }

      const _editlyVideoData = [];

      updateScenes(
        _woxoVideoData,
        (videos) => {
          _editlyVideoData.push(videos);
        },
        { overrideID: true }
      );

      const _artboardVideoData = bulkConfig.artboardVideoData;
      const _definitionVideoData = bulkConfig.definitionVideoData;
      const _category = bulkConfig.category;
      const _numberVideos = bulkConfig.numberVideos;

      // --- ***** Work with versions ***** ---
      if (bulkConfig.version) {
        get().setVersionAction(bulkConfig.version);

        if ('URLSearchParams' in window) {
          var searchParams = new URLSearchParams(window.location.search);
          searchParams.set('v', bulkConfig.version);
          var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
          history.pushState(null, '', newRelativePathQuery);
        }
      } else get().setVersionAction(null);
      // ---------------------------------------

      set({
        editlyVideoData: _editlyVideoData,
        woxoVideoData: _woxoVideoData,
        project: _project,
        projectName: alias,
        projectStatus: 'loaded'
      });
      get().videosLoadingAction();

      if (_artboardVideoData) set({ artboardVideoData: _artboardVideoData });
      else set({ artboardVideoData: bulkConfig });

      if (_category) set({ category: _category });

      if (_numberVideos) set({ numberVideos: _numberVideos });
      else set({ numberVideos: 0 });

      if (_definitionVideoData) {
        set({
          textPosition: _definitionVideoData.textPosition,
          videoShape: _definitionVideoData.videoShape,
          videoShapeUse: _definitionVideoData.videoShapeUse,
          fontSize: _definitionVideoData.fontSize,
          textColors: _definitionVideoData.textColors,
          music: _definitionVideoData.music,
          contentVideoData: _definitionVideoData.contentVideoData
        });
      }

      if (_artboardVideoData && !_definitionVideoData?.contentVideoData) {
        const quantity = _artboardVideoData[0]?.videoDefinition?.length;
        const _contentVideoData = generateContentVideoData(0, quantity);
        set({ contentVideoData: _contentVideoData });
      }

      get().setupLoadingAction(false);
      get().buildVideosAction();
      set({ loading: false });
      get().addProjectVersionsAction();
    } else {
      set({ projectStatus: 'unsaved' });
    }
  },

  saveProjectAction: (initiator = 'user') => {
    if (!get().user) {
      if (initiator === 'user') {
        // TODO: Make a better handler of flags
        localStorage.setItem('bulkCreatorState', JSON.stringify(get()));
        set({ projectStatus: 'saving' });
        get().showSignUpmodalAction(window.location.pathname, 'signup');
      }
    } else if (get().uploading) {
      if (initiator === 'user') {
        alert('Uploading Media! Cant save the project now');
      }
    } else if (get().projectStatus === 'unsaved' && initiator === 'system') {
      // console.log('Do nothing cause the user dont wanna save');
    } else if (get().projectStatus === 'saved' || get().projectStatus === 'unsaved') {
      set({ projectStatus: 'saving' });

      const newTitle = get().projectName;
      const projectType = 'bulkCreator';

      let _artboardVideoData =
        get().projectVersions[get().projectVersions.length - 1]?.artboardVideoData;
      _artboardVideoData.map((sc) => {
        return {
          ...sc,
          medias: sc.medias.map((m) => {
            const remotePath = m.asset?.secure_url;
            return { ...m, preview: remotePath };
          })
        };
      });
      const _definitionVideoData =
        get().projectVersions[get().projectVersions.length - 1]?.definitionVideoData;
      const _category = get().projectVersions[get().projectVersions.length - 1]?.category;

      const actionType = get().projectCode === null ? 'new' : 'change';
      const videoData = replaceLocalMedias(get().woxoVideoData, _artboardVideoData);
      const meta = {
        projectType,
        bulkConfig: {
          artboardVideoData: _artboardVideoData,
          definitionVideoData: _definitionVideoData,
          category: _category,
          version: get().version,
          numberVideos: get().numberVideos
        }
      };

      project({
        videos: videoData,
        title: newTitle,
        videoCode: get().projectCode,
        action: actionType,
        meta
      })
        .then((response) => {
          const urlSearchParams = new URLSearchParams(window.location.search);
          if (response.code) {
            urlSearchParams.set('slug', response.code);
            Router.push(`/bulk-creator?${urlSearchParams.toString()}`);
            set({ projectStatus: 'saved', project: response, projectCode: response.code });
          }
        })
        .catch((error) => {
          console.log('Something when wrong');
          console.log(error);
        });
    }
  },

  changeProjectNameAction: (newName) => {
    if (!get().user) {
      get().showSignUpmodalAction(window.location.pathname, 'signup');
    } else if (get().project !== null) {
      set({ projectStatus: 'saving' });
      const project_id = get().project.id ? get().project.id : get().project._id;
      rename({ id: project_id, name: newName })
        .then((v) => {
          Toast.success('Project name has been changed successfully');
          set({ projectStatus: 'saved' });
        })
        .catch((e) => {
          Toast.error(translate('error.network'));
          set({ projectStatus: 'unsaved' });
        });
    }
    set({ projectName: newName });
  },

  setProjectNameAction: (projectName) => {
    set({ projectName });
    get().saveProjectAction('user');
    get().trackingAction({
      event: 'onButtonClick',
      category: 'bulk-creator',
      action: 'save-project'
    });
  },

  setProjectStatusAction: (projectStatus) => set({ projectStatus }),

  addProjectVersionsAction: () => {
    const versions = clone(get().projectVersions);
    let version = {
      artboardVideoData: clone(get().artboardVideoData),
      definitionVideoData: {
        textPosition: get().textPosition,
        videoShape: get().videoShape,
        videoShapeUse: get().videoShapeUse,
        fontSize: get().fontSize,
        textColors: clone(get().textColors),
        music: get().music,
        contentVideoData: clone(get().contentVideoData)
      },
      category: get().activeCategory,
      numberVideos: get().numberVideos
    };

    if (get().undoApplied) {
      versions.length = get().activeProjectVersion + 1;
      set({ undoApplied: false });
    }
    versions.push(version);
    set({
      projectVersions: versions,
      activeProjectVersion: versions.length - 1,
      redoActive: false
    });
    if (versions.length > 1) set({ undoActive: true });
    // console.log('VERSIONS', versions);
  },

  undoAction: () => {
    for (let i = 0; i < get().projectVersions.length; i++) {
      if (i === get().activeProjectVersion - 1) {
        if (i === 0) set({ undoActive: false });
        set({ activeProjectVersion: i, redoActive: true, undoApplied: true });
        set({
          artboardVideoData: get().projectVersions[i]?.artboardVideoData,
          textPosition: get().projectVersions[i]?.definitionVideoData.textPosition,
          videoShape: get().projectVersions[i]?.definitionVideoData.videoShape,
          videoShapeUse: get().projectVersions[i]?.definitionVideoData.videoShapeUse,
          fontSize: get().projectVersions[i]?.definitionVideoData.fontSize,
          textColors: get().projectVersions[i]?.definitionVideoData.textColors,
          music: get().projectVersions[i]?.definitionVideoData.music,
          contentVideoData: get().projectVersions[i]?.definitionVideoData.contentVideoData,
          activeCategory: get().projectVersions[i]?.category,
          numberVideos: get().projectVersions[i]?.numberVideos
        });
        get().buildVideosAction();
        break;
      }
    }
  },

  redoAction: () => {
    for (let i = 0; i < get().projectVersions.length; i++) {
      if (i - 1 === get().activeProjectVersion) {
        if (i === get().projectVersions.length - 1) set({ redoActive: false });
        set({ activeProjectVersion: i, undoActive: true });
        set({
          artboardVideoData: get().projectVersions[i]?.artboardVideoData,
          textPosition: get().projectVersions[i]?.definitionVideoData.textPosition,
          videoShape: get().projectVersions[i]?.definitionVideoData.videoShape,
          videoShapeUse: get().projectVersions[i]?.definitionVideoData.videoShapeUse,
          fontSize: get().projectVersions[i]?.definitionVideoData.fontSize,
          textColors: get().projectVersions[i]?.definitionVideoData.textColors,
          music: get().projectVersions[i]?.definitionVideoData.music,
          contentVideoData: get().projectVersions[i]?.definitionVideoData.contentVideoData,
          activeCategory: get().projectVersions[i]?.category,
          numberVideos: get().projectVersions[i]?.numberVideos
        });
        get().buildVideosAction();
        break;
      }
    }
  },

  createBlankProjectAction: async () => {
    set({ loading: true });
    const _data = sampleQuotesArtboardVideoData(get().version); // --- ***** Work with versions ***** ---
    const definitionVideoData = {
      textPosition: 'Top',
      videoShape: '9:16',
      videoShapeUse: 'TikTok',
      fontSize: 7,
      textColors: {
        text: { displayColorPicker: false, color: '#000' },
        background: { displayColorPicker: false, color: '#FFBC42' },
        activate: false
      },
      music: 'Inspirational',
      contentVideoData: sampleContentVideoData(_data)
    };

    set({
      activeScene: 0,
      artboardVideoData: _data,
      ...definitionVideoData,
      activeCategory: 'Quotes',
      numberVideos: 0,
      project: null,
      projectName: 'Untitled Project',
      projectCode: null,
      projectStatus: 'unsaved',
      projectVersions: [
        {
          artboardVideoData: _data,
          definitionVideoData: {
            ...definitionVideoData
          },
          category: 'Quotes',
          numberVideos: 0
        }
      ],
      activeProjectVersion: 0,
      undoActive: false,
      redoActive: false,
      undoApplied: false
    });
    await get().buildVideosAction();
    set({ loading: false });
  }
});
