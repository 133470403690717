import schema from './video-schema/schemaGeneric';

const defaultTitle = 'Your Landing Page Title';
const defaultDescription =
  'Your landing page description. Your landing page description. . Your landing page description. The Landing Page Title, Description, CTA Text and CTA Link can be customized adding it as columns to the spreadsheet. Here a [small tutorial](https://support.woxo.tech/un-link-del-tutorial) with the steps to follow to do it.';

const defaultCtaText = 'Your CTA Text';
const defaultCtaLink = 'https://woxo.tech/video-maker';

const json = {
  outPath: './output/generic.mp4',
  width: 1080,
  height: 1920,
  fps: 24,
  customFont: {
    path: '{{Font}}',
    fontFamily: '@font'
  },
  keepSourceAudio: true,
  allowRemoteRequests: true,
  audioFilePath: '{{Music}}',
  clips: [
    {
      transition: {
        duration: 0.25,
        name: '{{Transition}}'
      },
      duration: 0,
      layers: [
        {
          type: 'image',
          path: '{{Media #}}',
          zoomDirection: 'out',
          resizeMode: 'cover'
        },
        {
          type: 'woxo-itext',
          text: '{{Title #}}\n{{Text #}}',
          width: 1000,
          style: { ___: '@preset', angle: 0 },
          y: '@alignment'
        },
        {
          type: 'woxo-image',
          path: '{{Brand}}',
          y: '@brand-v-alignment',
          x: '@brand-h-alignment'
        }
      ]
    }
  ],
  outputVolume: '-10dB'
};

const templateDefinition = {
  schema,
  json,
  defaultTitle,
  defaultCtaLink,
  defaultCtaText,
  defaultDescription
};
export default templateDefinition;
