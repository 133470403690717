import { v4 } from 'uuid';

export const sampleQuotesArtboardVideoData = [
  {
    videoDefinition: [
      {
        text: "Life is beautiful in all its colors, even the darker ones, they're here for a reason.",
        type: 'Quotes'
      },
      {
        text: "I'd rather be a comma than a full stop.",
        type: 'Quotes'
      },
      {
        text: 'Nothing matters except life and the love you make.',
        type: 'Quotes'
      },
      {
        text: 'I believe in God. How can you look at anything and not be overwhelmed by its miraculousness of it.',
        type: 'Quotes'
      },
      {
        text: "We're here to put a dent in the universe. Otherwise, why else even be here?",
        type: 'Quotes'
      },
      {
        text: "You can't just ask customers what they want and then try to give that to them. By the time you get it built, they'll want something new.",
        type: 'Quotes'
      },
      {
        text: 'Innovation distinguishes between a leader and a follower.',
        type: 'Quotes'
      },
      {
        text: 'Stay hungry. Stay foolish.',
        type: 'Quotes'
      }
    ],
    medias: [
      {
        path: 'Quotes_1.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1657054710/woxo-video-sample-medias/quotes/Quotes_Martin_1.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1657054710/woxo-video-sample-medias/quotes/Quotes_Martin_1.mp4'
        }
      },
      {
        path: 'Quotes_2.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1657054710/woxo-video-sample-medias/quotes/Quotes_Martin_2.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1657054710/woxo-video-sample-medias/quotes/Quotes_Martin_2.mp4'
        }
      },
      {
        path: 'Quotes_3.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1657054710/woxo-video-sample-medias/quotes/Quotes_Martin_3.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1657054710/woxo-video-sample-medias/quotes/Quotes_Martin_3.mp4'
        }
      },
      {
        path: 'Quotes_4.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1657054710/woxo-video-sample-medias/quotes/Quotes_Martin_4.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1657054710/woxo-video-sample-medias/quotes/Quotes_Martin_4.mp4'
        }
      },
      {
        path: 'Quotes_5.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1657054521/woxo-video-sample-medias/quotes/Quotes_Steve_Jobs_1.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1657054521/woxo-video-sample-medias/quotes/Quotes_Steve_Jobs_1.mp4'
        }
      },
      {
        path: 'Quotes_6.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1657054521/woxo-video-sample-medias/quotes/Quotes_Steve_Jobs_2.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1657054521/woxo-video-sample-medias/quotes/Quotes_Steve_Jobs_2.mp4'
        }
      },
      {
        path: 'Quotes_7.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1657054521/woxo-video-sample-medias/quotes/Quotes_Steve_Jobs_3.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1657054521/woxo-video-sample-medias/quotes/Quotes_Steve_Jobs_3.mp4'
        }
      },
      {
        path: 'Quotes_8.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1657054521/woxo-video-sample-medias/quotes/Quotes_Steve_Jobs_4.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1657054521/woxo-video-sample-medias/quotes/Quotes_Steve_Jobs_4.mp4'
        }
      }
    ],
    text: "Life is beautiful in all its colors, even the darker ones, they're here for a reason.\n\nI'd rather be a comma than a full stop.\n\nNothing matters except life and the love you make.\n\nI believe in God. How can you look at anything and not be overwhelmed by its miraculousness of it.\n\nWe're here to put a dent in the universe. Otherwise, why else even be here?\n\nYou can't just ask customers what they want and then try to give that to them. By the time you get it built, they'll want something new.\n\nInnovation distinguishes between a leader and a follower.\n\nStay hungry. Stay foolish.",
    type: 'Quotes',
    id: v4()
  }
];
