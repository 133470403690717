module.exports = [
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506520/woxo-video-fonts-presets/Varela_Round/VarelaRound-Regular.ttf',
    BackgroundColor: '#B5179E',
    TextColor: '#FFF',
    TextStyle: 'Criss Cross',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/FINALLY_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506516/woxo-video-fonts-presets/Titillium_Web/TitilliumWeb-Regular.ttf',
    BackgroundColor: '#FFBC42',
    TextColor: '#000',
    TextStyle: 'Basic',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/unbreakable_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506499/woxo-video-fonts-presets/Source_Sans_Pro/SourceSansPro-Regular.ttf',
    BackgroundColor: '#7209B7',
    TextColor: '#FFF',
    TextStyle: 'Dream Meme',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Beyond_time_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506491/woxo-video-fonts-presets/Squada_One/SquadaOne-Regular.ttf',
    BackgroundColor: '#99C1DE',
    TextColor: '#000',
    TextStyle: 'Pop Out',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/WHEN_I_M_WITH_YOU_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506436/woxo-video-fonts-presets/Roboto_Condensed/RobotoCondensed-Bold.ttf',
    BackgroundColor: '#480CA8',
    TextColor: '#FFF',
    TextStyle: 'Solid',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Hot_Air_Balloons_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506387/woxo-video-fonts-presets/Roboto/Roboto-Regular.ttf',
    BackgroundColor: '#73D2DE',
    TextColor: '#000',
    TextStyle: 'Trade Mark',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Now_is_the_time_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506358/woxo-video-fonts-presets/Raleway/Raleway-VariableFont_wght.ttf',
    BackgroundColor: '#3F37C9',
    TextColor: '#FFF',
    TextStyle: 'Boundary',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Same_old_blues_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506350/woxo-video-fonts-presets/Roboto/Roboto-Bold.ttf',
    BackgroundColor: '#BCD4E6',
    TextColor: '#000',
    TextStyle: 'Center Line',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/The_Ride_-_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506328/woxo-video-fonts-presets/PT_Sans_Narrow/PTSansNarrow-Regular.ttf',
    BackgroundColor: '#3a0ca3',
    TextColor: '#FFF',
    TextStyle: 'Honor',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Morning_Song_-_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506309/woxo-video-fonts-presets/Poppins/Poppins-Regular.ttf',
    BackgroundColor: '#5BC8AF',
    TextColor: '#000',
    TextStyle: 'Line Up',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Shine_-_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506228/woxo-video-fonts-presets/Open_Sans_Condensed/OpenSansCondensed-Bold.ttf',
    BackgroundColor: '#B7094C',
    TextColor: '#FFF',
    TextStyle: 'Simple and Clean',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/In_the_Sky_-_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506223/woxo-video-fonts-presets/Patua_One/PatuaOne-Regular.ttf',
    BackgroundColor: '#D6E2E9',
    TextColor: '#000',
    TextStyle: 'Step Center',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Alive_-_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506216/woxo-video-fonts-presets/Open_Sans_Condensed/OpenSansCondensed-Light.ttf',
    BackgroundColor: '#A01A58',
    TextColor: '#FFF',
    TextStyle: 'Step Up',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Hope_-_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506192/woxo-video-fonts-presets/Noto_Sans/NotoSans-Regular.ttf',
    BackgroundColor: '#FFCB77',
    TextColor: '#000',
    TextStyle: 'Roll Out',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/FINALLY_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506176/woxo-video-fonts-presets/Open_Sans/OpenSans-Regular.ttf',
    BackgroundColor: '#2E6F95',
    TextColor: '#FFF',
    TextStyle: 'Criss Cross',
    TextPosition: 'Bottom',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/unbreakable_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506161/woxo-video-fonts-presets/Montserrat/Montserrat-Regular.ttf',
    BackgroundColor: '#FEF9EF',
    TextColor: '#000',
    TextStyle: 'Basic',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Beyond_time_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506065/woxo-video-fonts-presets/Merriweather/Merriweather-Regular.ttf',
    BackgroundColor: '#1780A1',
    TextColor: '#FFF',
    TextStyle: 'Dream Meme',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/WHEN_I_M_WITH_YOU_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618506028/woxo-video-fonts-presets/Libre_Baskerville/LibreBaskerville-Regular.ttf',
    BackgroundColor: '#F0EFEB',
    TextColor: '#000',
    TextStyle: 'Pop Out',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Hot_Air_Balloons_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618505994/woxo-video-fonts-presets/Lato/Lato-Regular.ttf',
    BackgroundColor: '#5C4D7D',
    TextColor: '#FFF',
    TextStyle: 'Solid',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Now_is_the_time_MOODHALO.mp3'
  },
  {
    Font: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1618505987/woxo-video-fonts-presets/Gentium_Basic/GentiumBasic-Regular.ttf',
    BackgroundColor: '#EDDCD2',
    TextColor: '#000',
    TextStyle: 'Trade Mark',
    Music:
      'https://res.cloudinary.com/dakp804eh/video/upload/v1639027045/woxo-video/audios-v2/Inspirational/Same_old_blues_MOODHALO.mp3'
  }
];
