import { v4 } from 'uuid';

export const sampleRestaurantMenuArtboardVideoData = [
  {
    videoDefinition: [
      {
        text: '',
        type: 'Menu',
        v1: [
          {
            text: 'Celebrate with...',
            type: 'Menu'
          },
          {
            text: 'Have fun, taste good food.',
            type: 'Menu'
          },
          {
            text: 'Like if you want to see more delicious food!',
            type: 'Menu'
          }
        ]
      },
      {
        text: '',
        type: 'Menu',
        v1: [
          {
            text: 'This is how we do it...',
            type: 'Menu'
          },
          {
            text: 'Find. Explore. Taste',
            type: 'Menu'
          },
          {
            text: "Gimme a 😋 if you'd like to see more posts like this!",
            type: 'Menu'
          }
        ]
      },
      {
        text: '',
        type: 'Menu',
        v1: [
          {
            text: 'Eat to your heart’s content!',
            type: 'Menu'
          },
          {
            text: 'Great times. Great food.',
            type: 'Menu'
          },
          {
            text: "Tag the person you'd like to share good mood food with!",
            type: 'Menu'
          }
        ]
      },
      {
        text: '',
        type: 'Menu',
        v1: [
          {
            text: 'Let us show you how we do it...',
            type: 'Menu'
          },
          {
            text: 'Enjoy the taste of good food!',
            type: 'Menu'
          },
          {
            text: 'SAVE this post to remember to order this on your next visit!',
            type: 'Menu'
          }
        ]
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/397970946.hd.mp4?s=bdd1e35e2ba95d7b673491e4c6bbc4571edcf293&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F397970946.sd.mp4%3Fs%3D4c9ad02237b31d13dc11d2292089af5dc5bbd3ef%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3944327%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/397970946.hd.mp4?s=bdd1e35e2ba95d7b673491e4c6bbc4571edcf293&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F397970946.sd.mp4%3Fs%3D4c9ad02237b31d13dc11d2292089af5dc5bbd3ef%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3944327%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/464577768.hd.mp4?s=7baf6e4784d196f1be2e83c9f81d8b997a2bbeac&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F464577768.sd.mp4%3Fs%3Dd471d95c6f6c3b0bb0b360eee66e2fee239b9689%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5515577%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/464577768.hd.mp4?s=7baf6e4784d196f1be2e83c9f81d8b997a2bbeac&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F464577768.sd.mp4%3Fs%3Dd471d95c6f6c3b0bb0b360eee66e2fee239b9689%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5515577%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/567357811.hd.mp4?s=83ff07c353cc71deb123437508cc5e61eac35e2e&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F567357811.sd.mp4%3Fs%3D86a604d842b46dabdae8dba3556bda3d2d6d0261%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8477264%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/567357811.hd.mp4?s=83ff07c353cc71deb123437508cc5e61eac35e2e&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F567357811.sd.mp4%3Fs%3D86a604d842b46dabdae8dba3556bda3d2d6d0261%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8477264%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-4.jpeg',
        preview:
          'https://player.vimeo.com/external/376190872.hd.mp4?s=e44da73c27ec3ebc506a9fe6417c6f836db5ea78&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F376190872.sd.mp4%3Fs%3D13acd68ea5b8e0a0325fe7c0321a0930952568c3%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3298012%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/376190872.hd.mp4?s=e44da73c27ec3ebc506a9fe6417c6f836db5ea78&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F376190872.sd.mp4%3Fs%3D13acd68ea5b8e0a0325fe7c0321a0930952568c3%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3298012%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "Celebrate with...\n\nHave fun, taste good food.\n\nLike if you want to see more delicious food!\n\n---\n\nThis is how we do it...\n\nFind. Explore. Taste\n\nGimme a 😋 if you'd like to see more posts like this!\n\n---\n\nEat to your heart’s content!\n\nGreat times. Great food.\n\nTag the person you'd like to share good mood food with!\n\n---Let us show you how we do it...\n\nEnjoy the taste of good food!\n\nSAVE this post to remember to order this on your next visit!",
    type: 'Menu',
    id: v4()
  }
];
