import { clone } from '~/helpers/javascript';
import {
  scenesProcessing,
  addEmptyText,
  convertTextArrayToText,
  scenesProcessingV1,
  convertTextArrayToTextV1
} from '../helpers/artboardVideoDataProcessing';
import { sampleQuotesArtboardVideoData } from '../data/sampleQuotes';
import { generateContentVideoData } from '../helpers/generateContentVideoData';
import { v4 } from 'uuid';

export const useScenesStore = (set, get) => ({
  //states
  activeScene: 0,
  artboardVideoData: sampleQuotesArtboardVideoData(), // --- ***** Work with versions ***** ---
  numberVideos: 0,

  //actions
  selectScenesAction: (index) => {
    set({ activeScene: index });
    get().unsafeWaitForSceneAction();
    console.log('ACTIONS -> selectScenesAction - ', index + 1);
  },

  unsafeWaitForSceneAction: () => {
    get().editlyVideoData.map((v) => {
      v.map((d, i) => {
        get().videoRef[i]?.unsafeWaitForScene(get().activeScene + 1, true);
      });
    });
  },

  addScenesAction: () => {
    let _scene = {
      videoDefinition: [],
      medias: [],
      text: '',
      type: get().artboardVideoData[get().activeScene]?.type,
      id: v4()
    };
    get().artboardVideoData[get().artboardVideoData.length - 1]?.videoDefinition.map((v, i) => {
      _scene.videoDefinition.push({
        // --- ***** Work with versions ***** ---
        text:
          get().version === '1'
            ? `Add video ${i + 1}/scene ${
                get().artboardVideoData.length + 1
              } text here ✌️\n\n---\n\n`
            : `Add video ${i + 1}/scene ${get().artboardVideoData.length + 1} text here ✌️`,
        // --------------------------------------
        type: get().artboardVideoData[get().activeScene]?.type
      });
    });
    _scene.medias = get().artboardVideoData[get().artboardVideoData.length - 1]?.medias;
    // --- ***** Work with versions ***** ---
    _scene.text =
      get().version === '1'
        ? convertTextArrayToTextV1(_scene.videoDefinition)
        : convertTextArrayToText(_scene.videoDefinition);
    // --------------------------------------
    const artboardVideoData = clone(get().artboardVideoData);
    artboardVideoData.push(_scene);

    get().setArtboardVideoDataAction(artboardVideoData);
    get().addProjectVersionsAction();
    get().selectScenesAction(artboardVideoData.length - 1);
  },

  deleteScenesAction: (index) => {
    const artboardVideoData = clone(get().artboardVideoData);
    artboardVideoData.splice(index, 1);
    get().selectScenesAction(index - 1);
    const text = artboardVideoData[get().activeScene]?.text;
    // --- ***** Work with versions ***** ---
    const _artboardVideoData =
      get().version === '1'
        ? scenesProcessingV1(
            artboardVideoData,
            text,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          )
        : scenesProcessing(
            artboardVideoData,
            text,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          );
    // --------------------------------------
    get().setArtboardVideoDataAction(_artboardVideoData);
    get().addProjectVersionsAction();
  },

  addMediasAction: (newMedias, toUpload = false, replace = false) => {
    const artboardVideoData = clone(get().artboardVideoData);
    if (!replace) {
      set({ uploading: true });
      artboardVideoData[get().activeScene]?.medias.push(...newMedias);
      // --- ***** Work with versions ***** ---
      const _artboardVideoData =
        get().version === '1'
          ? scenesProcessingV1(
              artboardVideoData,
              null,
              get().activeCategory,
              get().activeScene,
              get().numberVideos
            )
          : scenesProcessing(
              artboardVideoData,
              null,
              get().activeCategory,
              get().activeScene,
              get().numberVideos
            );
      // --------------------------------------
      get().setArtboardVideoDataAction(_artboardVideoData);

      if (toUpload) {
        const options = {
          folder: `woxo-videos-user-gallery/${
            get().user
              ? (get().plan.userOwner && get().plan.userOwner.username) || get().user.username
              : 'public'
          }`
        };
        get().uploadingVideosAction(newMedias, get().handleUploadProgressAction, options);
      }
    } else {
      artboardVideoData[get().activeScene].medias = [...newMedias];
      // --- ***** Work with versions ***** ---
      const _artboardVideoData =
        get().version === '1'
          ? scenesProcessingV1(artboardVideoData, null, get().activeCategory, get().activeScene)
          : scenesProcessing(artboardVideoData, null, get().activeCategory, get().activeScene);
      // --------------------------------------
      get().setArtboardVideoDataAction(_artboardVideoData);
      get().addProjectVersionsAction();
    }
  },

  deleteMediaAction: (index) => {
    let artboardVideoData = clone(get().artboardVideoData);
    artboardVideoData[get().activeScene]?.medias?.splice(index, 1);
    // --- ***** Work with versions ***** ---
    const _artboardVideoData =
      get().version === '1'
        ? scenesProcessingV1(
            artboardVideoData,
            null,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          )
        : scenesProcessing(
            artboardVideoData,
            null,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          );
    // --------------------------------------
    get().setArtboardVideoDataAction(_artboardVideoData);
    get().addProjectVersionsAction();
  },

  clearMediasAction: () => {
    let artboardVideoData = clone(get().artboardVideoData);
    artboardVideoData[get().activeScene].medias = [];
    // --- ***** Work with versions ***** ---
    const _artboardVideoData =
      get().version === '1'
        ? scenesProcessingV1(
            artboardVideoData,
            null,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          )
        : scenesProcessing(
            artboardVideoData,
            null,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          );
    // --------------------------------------
    get().setArtboardVideoDataAction(_artboardVideoData);
    get().addProjectVersionsAction();
  },

  moveMediasAction: (medias) => {
    const artboardVideoData = clone(get().artboardVideoData);
    artboardVideoData[get().activeScene].medias = medias;
    get().setArtboardVideoDataAction(artboardVideoData);
    get().addProjectVersionsAction();
  },

  setArtboardVideoDataAction: async (artboardVideoData) => {
    set({ artboardVideoData });
    if (artboardVideoData[0]?.videoDefinition?.length === get().contentVideoData?.length) {
      get().buildVideosAction();
    } else if (artboardVideoData[0]?.videoDefinition?.length > get().contentVideoData?.length) {
      let _contentVideoData = clone(get().contentVideoData);
      const totalVideos = artboardVideoData[0]?.videoDefinition?.length;
      const quantity = totalVideos - _contentVideoData?.length;
      const newContentVideoData = generateContentVideoData(
        totalVideos,
        quantity,
        get().music,
        get().activeCategory
      );
      set({ contentVideoData: [..._contentVideoData, ...newContentVideoData] });
      await get().buildVideosAction();
      get().unsafeWaitForSceneAction();
    } else {
      let _contentVideoData = clone(get().contentVideoData);
      _contentVideoData.length = artboardVideoData[0]?.videoDefinition?.length;
      set({ contentVideoData: _contentVideoData });
      get().buildVideosAction();
    }
  },

  changeVideoRawInputDataAction: (data) => {
    // --- ***** Work with versions ***** ---
    const _artboardVideoData =
      get().version === '1'
        ? scenesProcessingV1(
            get().artboardVideoData,
            data,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          )
        : scenesProcessing(
            get().artboardVideoData,
            data,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          );
    // --------------------------------------
    get().trackingAction({
      event: 'onButtonClick',
      category: 'bulk-creator',
      action: `text-changed`
    });
    get().setArtboardVideoDataAction(_artboardVideoData);
    get().addProjectVersionsAction();
  },

  addEmptyTextAction: (data, cursor) => {
    const text = addEmptyText(data, cursor);
    // --- ***** Work with versions ***** ---
    const _artboardVideoData =
      get().version === '1'
        ? scenesProcessingV1(
            get().artboardVideoData,
            text,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          )
        : scenesProcessing(
            get().artboardVideoData,
            text,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          );
    // --------------------------------------
    get().setArtboardVideoDataAction(_artboardVideoData);
    get().addProjectVersionsAction();
  },

  createNewVideoTextAction: () => {
    const text =
      get().artboardVideoData[get().activeScene]?.text +
      // --- ***** Work with versions ***** ---
      (get().version === '1' ? '\n\n---\n\nInput text here ✌️' : '\n\nInput text here ✌️');
    const _artboardVideoData =
      get().version === '1'
        ? scenesProcessingV1(
            get().artboardVideoData,
            text,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          )
        : scenesProcessing(
            get().artboardVideoData,
            text,
            get().activeCategory,
            get().activeScene,
            get().numberVideos
          );
    // --------------------------------------
    get().setArtboardVideoDataAction(_artboardVideoData);
    get().addProjectVersionsAction();
  },

  setNumberVideosAction: (numberVideos, change = true) => {
    set({ numberVideos });
    if (change) {
      const artboardVideoData = clone(get().artboardVideoData);
      // --- ***** Work with versions ***** ---
      const _artboardVideoData =
        get().version === '1'
          ? scenesProcessingV1(
              artboardVideoData,
              null,
              get().activeCategory,
              get().activeScene,
              numberVideos
            )
          : scenesProcessing(
              artboardVideoData,
              null,
              get().activeCategory,
              get().activeScene,
              numberVideos
            );
      // --------------------------------------
      get().setArtboardVideoDataAction(_artboardVideoData);
      get().addProjectVersionsAction();
    }
  }
});
