import React, { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import VideoPlayer from './VideoPlayer';
import TutorialButton from './TutorialButton';
import TutorialModal from './TutorialModal';
import TutorialStepper from './TutorialStepper';
import { TutorialState } from './TutorialStateProvider';

export default function TutorialVideos({ isMobileView, isInVideoEditor }) {
  const [isOpen, setIsOpen] = useState(false);
  const { currentStep, setCurrentStep, videoList, setVideoList } = useContext(TutorialState);
  const { asPath } = useRouter();

  const [isVideoReady, setisVideoReady] = useState(false);

  useEffect(() => {
    setisVideoReady(false);
  }, [currentStep]);

  const changeVideoToReady = () => {
    setisVideoReady(true);
    setVideoList((prev) => {
      const newArr = prev.slice(0);
      newArr[currentStep] = {
        ...newArr[currentStep],
        completed: true
      };
      return newArr;
    });
  };

  if (
    asPath.startsWith('/sheet-to-video') ||
    asPath.startsWith('/idea-to-video') ||
    asPath.startsWith('/bulk-creator')
  ) {
    return null;
  }

  return (
    <>
      <TutorialButton
        isMobileView={isMobileView}
        isInVideoEditor={isInVideoEditor}
        onOpen={() => setIsOpen(true)}
      />
      <TutorialModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <TutorialStepper
          stepList={videoList}
          currentStep={currentStep}
          changeCurrentStep={setCurrentStep}
          videoReady={isVideoReady}
        />
        <VideoPlayer
          changeVideoReady={changeVideoToReady}
          url={videoList[currentStep].url}
          isVideoReady={isVideoReady}
        />
      </TutorialModal>
    </>
  );
}
