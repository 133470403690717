import { sampleQuotesArtboardVideoData } from '../data/sampleQuotes';
import { sampleRestaurantAboutUsArtboardVideoData } from '../data/sampleRestaurantAboutUs';
import { sampleRestaurantCateringEventsArtboardVideoData } from '../data/sampleRestaurantCateringEvents';
import { sampleRestaurantDeliveryServicesArtboardVideoData } from '../data/sampleRestaurantDeliveryServices';
import { sampleRestaurantMenuArtboardVideoData } from '../data/sampleRestaurantMenu';
import { sampleRestaurantReservationsArtboardVideoData } from '../data/sampleRestaurantReservations';
import { v4 } from 'uuid';
import { scenesProcessing, scenesProcessingV1 } from '../helpers/artboardVideoDataProcessing';

export const useCategoriesStore = (set, get) => ({
  //states
  categories: [
    { title: 'Quotes', types: [], open: false },
    {
      title: 'Restaurants',
      types: ['About Us', 'Catering & Events', 'Delivery Services', 'Menu', 'Reservations'],
      open: true
    }
    // { title: 'E-Commerce', types: [], open: false },
    // { title: 'Music', types: [], open: false },
    // { title: 'Travel', types: [], open: false },
    // { title: 'Finance', types: [], open: false },
    // { title: 'Pets', types: [], open: false }
  ],
  activeCategory: 'Quotes',

  //actions
  setCategoryAction: (category, index = 0) => {
    if (category.title) {
      if (category.types.length > 0) {
        let _categories = get().categories;
        _categories[index] = { ...category, open: !category.open };
        get().setCategoriesAction(_categories);
      } else {
        get().setActiveCategoryAction(category.title);
        let _categories = get().categories;
        _categories.map((c) => {
          c.open = false;
        });
        get().setCategoriesAction(_categories);
      }
    } else {
      get().setActiveCategoryAction(`${get().categories[index]?.title}_${category}`);
    }
  },

  setCategoriesAction: (categories) => {
    set((state) => ({
      categories: [...state.categories]
    }));
  },

  setActiveCategoryAction: (activeCategory) => {
    set({ activeCategory });
    switch (activeCategory) {
      case 'Quotes':
        const quotesData = sampleQuotesArtboardVideoData(get().version); // --- ***** Work with versions ***** ---
        get().selectScenesAction(0);
        // --- ***** Work with versions ***** ---
        const _artboardQuotesVideoData =
          get().version === '1'
            ? scenesProcessingV1(
                quotesData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              )
            : scenesProcessing(
                quotesData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              );
        // --------------------------------------
        get().setArtboardVideoDataAction(_artboardQuotesVideoData);
        get().addProjectVersionsAction();
        break;
      case 'Restaurants_About Us':
        const aboutUsData = sampleRestaurantAboutUsArtboardVideoData(get().version); // --- ***** Work with versions ***** ---
        get().selectScenesAction(0);
        // --- ***** Work with versions ***** ---
        const _artboardAboutUsVideoData =
          get().version === '1'
            ? scenesProcessingV1(
                aboutUsData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              )
            : scenesProcessing(
                aboutUsData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              );
        // --------------------------------------
        get().setArtboardVideoDataAction(_artboardAboutUsVideoData);
        get().addProjectVersionsAction();
        break;
      case 'Restaurants_Catering & Events':
        const cateringEventData = sampleRestaurantCateringEventsArtboardVideoData(get().version); // --- ***** Work with versions ***** ---
        get().selectScenesAction(0);
        // --- ***** Work with versions ***** ---
        const _artboardCateringEventVideoData =
          get().version === '1'
            ? scenesProcessingV1(
                cateringEventData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              )
            : scenesProcessing(
                cateringEventData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              );
        // --------------------------------------
        get().setArtboardVideoDataAction(_artboardCateringEventVideoData);
        get().addProjectVersionsAction();
        break;
      case 'Restaurants_Delivery Services':
        const deliveryServicesData = sampleRestaurantDeliveryServicesArtboardVideoData(
          get().version
        ); // --- ***** Work with versions ***** ---
        get().selectScenesAction(0);
        // --- ***** Work with versions ***** ---
        const _artboardDeliveryServicesVideoData =
          get().version === '1'
            ? scenesProcessingV1(
                deliveryServicesData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              )
            : scenesProcessing(
                deliveryServicesData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              );
        // --------------------------------------
        get().setArtboardVideoDataAction(_artboardDeliveryServicesVideoData);
        get().addProjectVersionsAction();
        break;
      case 'Restaurants_Menu':
        const menuData = sampleRestaurantMenuArtboardVideoData(get().version); // --- ***** Work with versions ***** ---
        get().selectScenesAction(0);
        // --- ***** Work with versions ***** ---
        const _artboardMenuVideoData =
          get().version === '1'
            ? scenesProcessingV1(
                menuData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              )
            : scenesProcessing(
                menuData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              );
        // --------------------------------------
        get().setArtboardVideoDataAction(_artboardMenuVideoData);
        get().addProjectVersionsAction();
        break;
      case 'Restaurants_Reservations':
        const reservationsData = sampleRestaurantReservationsArtboardVideoData(get().version); // --- ***** Work with versions ***** ---
        get().selectScenesAction(0);
        // --- ***** Work with versions ***** ---
        const _artboardReservationsVideoData =
          get().version === '1'
            ? scenesProcessingV1(
                reservationsData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              )
            : scenesProcessing(
                reservationsData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              );
        // --------------------------------------
        get().setArtboardVideoDataAction(_artboardReservationsVideoData);
        get().addProjectVersionsAction();
        break;
      default:
        // --- ***** Work with versions ***** ---
        let defaultData =
          get().version === '1'
            ? [
                {
                  videoDefinition: [
                    {
                      text: '',
                      type: activeCategory,
                      v1: [{ text: 'Video text here ✌️', type: activeCategory }]
                    }
                  ],
                  medias: [],
                  text: 'Video text here ✌️',
                  type: activeCategory,
                  id: v4()
                }
              ]
            : [
                {
                  videoDefinition: [{ text: 'Video text here ✌️', type: activeCategory }],
                  medias: [],
                  text: 'Video text here ✌️',
                  type: activeCategory,
                  id: v4()
                }
              ];
        get().selectScenesAction(0);
        const _artboardDefaultVideoData =
          get().version === '1'
            ? scenesProcessingV1(
                defaultData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              )
            : scenesProcessing(
                defaultData,
                null,
                get().activeCategory,
                get().activeScene,
                get().numberVideos
              );
        // --------------------------------------
        get().setArtboardVideoDataAction(_artboardDefaultVideoData);
        get().addProjectVersionsAction();
        break;
    }
    get().trackingAction({
      event: 'onButtonClick',
      category: 'bulk-creator',
      action: 'select-category',
      label: activeCategory
    });
  }
});
