import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  list: {
    maxHeight: 500,
    overflowY: 'auto'
  },
  buttonPopover: {
    color: '#fff',
    backgroundColor: '#bdbdbd',
    borderRadius: '50%',
    width: 28,
    height: 28,
    minWidth: 0,
    marginLeft: 20,
    '&.primary': {
      backgroundColor: '#0684FA',
      '&:hover': {
        backgroundColor: '#0684FA'
      }
    },
    '&:hover': {
      backgroundColor: '#bdbdbd'
    },
    '& svg': {
      width: 18,
      height: 18
    }
  },
  badge: {
    top: -8,
    backgroundColor: '#0684FA',
    borderRadius: '50%',
    width: 18,
    height: 18,
    color: '#fff',
    display: 'flex',
    fontSize: 11,
    fontWeight: 700,
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 2,
    right: -16,
    position: 'absolute'
  },
  menuContent: {
    '& .MuiPopover-paper': {
      marginTop: 14,
      background: 'transparent',
      boxShadow: 'none'
    }
  },
  menuItem: {
    cursor: 'pointer',
    color: '#666666',
    display: 'flex',
    alignItems: 'baseline',
    fontSize: 12,
    padding: '6px 12px',
    whiteSpace: 'unset',
    '& svg.dot': {
      height: 6,
      color: '#0584fa'
    },
    '& svg.trash': {
      height: 16,
      alignSelf: 'center',
      cursor: 'pointer',
      '&:hover': {
        color: '#b73115'
      }
    }
  },
  listPopoverContainer: { width: 300 },
  date: {
    color: '#0584fa',
    marginTop: 6,
    fontSize: 11
  }
}));
