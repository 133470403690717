const schema = [
  {
    type: 'media',
    name: 'Media #',
    title: 'Media',
    group: 'Scene #',
    groupIdx: 0
  },
  {
    type: 'text',
    name: 'Title #',
    title: 'Title',
    group: 'Scene #',
    groupIdx: 0
  },
  {
    type: 'text',
    name: 'Text #',
    title: 'Text',
    group: 'Scene #',
    groupIdx: 0
  },
  {
    type: 'brand',
    name: 'Brand',
    title: 'Brand',
    default: '',
    // 'https://res.cloudinary.com/dakp804eh/image/upload/v1617214228/Woxo/Logos/Logo_con_Background_224x77.png',
    group: 'Branding',
    opaque: true,
    groupIdx: 1
  },
  {
    type: 'font',
    name: 'Font',
    title: 'Font',
    default:
      'https://res.cloudinary.com/dakp804eh/raw/upload/v1618505979/woxo-video-fonts-presets/Caveat_Brush/CaveatBrush-Regular.ttf',
    group: 'Branding',
    opaque: true,
    groupIdx: 1
  },
  {
    type: 'color',
    name: 'BackgroundColor',
    title: 'Background color',
    default: 'rgba(0, 0, 0, .75)',
    group: 'Branding',
    opaque: true,
    groupIdx: 1
  },
  {
    type: 'color',
    name: 'TextColor',
    title: 'Text color',
    default: '#fff',
    group: 'Branding',
    opaque: true,
    groupIdx: 1
  },
  {
    type: 'font-size',
    name: 'FontSize',
    title: 'Font size',
    group: 'Text Styles',
    default: 12,
    opaque: true,
    groupIdx: 2
  },
  {
    type: 'text-style',
    name: 'TextStyle',
    title: 'Text style',
    default: 'Solid',
    group: 'Text Styles',
    opaque: true,
    groupIdx: 2
  },
  {
    type: 'text',
    name: 'TextShadow',
    title: 'Text shadow',
    default: '0',
    group: 'Text Styles',
    opaque: true,
    groupIdx: 2
  },
  {
    type: 'text',
    name: 'TextStroke',
    title: 'Text stroke',
    default: '0',
    group: 'Text Styles',
    opaque: true,
    groupIdx: 2
  },

  {
    type: 'text-position',
    name: 'TextPosition',
    title: 'Text position',
    default: 'Bottom',
    group: 'Text Styles',
    opaque: true,
    groupIdx: 2
  },
  {
    type: 'text-animation',
    name: 'TextAnimation',
    title: 'Text animation',
    default: 'Default',
    group: 'Text Styles',
    opaque: true,
    groupIdx: 2
  },
  {
    type: 'transition',
    name: 'Transition',
    title: 'Scenes transition',
    default: 'random',
    group: 'General',
    opaque: true,
    groupIdx: 3
  },

  {
    type: 'array',
    name: 'ScenesLength',
    title: 'Scenes length',
    default: '',
    group: 'General',
    opaque: true,
    groupIdx: 3
  },
  {
    type: 'music',
    name: 'Music',
    title: 'Music',
    default: '',
    // 'https://res.cloudinary.com/dakp804eh/video/upload/v1615481752/woxo-video/audios/LOOP_B_2019-05-04_-_I_Was_Joking_-_David_Fesliyan.mp3',
    group: 'General',
    opaque: true,
    groupIdx: 3
  },
  {
    type: 'music',
    name: 'VoiceOver',
    title: 'Voice over',
    default: '',
    group: 'General',
    opaque: true,
    groupIdx: 3
  },
  {
    type: 'gradient',
    name: 'BackgroundGradient',
    title: 'Background gradient',
    default: '',
    group: 'General',
    opaque: true,
    groupIdx: 3
  },
  {
    type: 'resolution',
    name: 'Video Shape',
    title: 'Video shape',
    default: '9:16',
    group: 'General',
    opaque: true,
    groupIdx: 3
  }
  /*{
    type: 'gradient',
    name: 'TextShadow',
    title: 'Text shadow',
    default: '0',
    group: 'Text Styles',
    opaque: true,
    groupIdx: 2
  },
  {
    type: 'gradient',
    name: 'TextStroke',
    title: 'Text stroke',
    default: '0',
    group: 'Text Styles',
    opaque: true,
    groupIdx: 2
  }*/
];

export default schema;
