import { v4 } from 'uuid';
import { sampleQuotesArtboardVideoData as version_0_1 } from './v0_1/sampleQuotes';
import { sampleQuotesArtboardVideoData as version_1 } from './v1/sampleQuotes';
import { sampleQuotesArtboardVideoData as version_2 } from './v2/sampleQuotes';

const version_0 = [
  {
    videoDefinition: [
      {
        text: "It's Monday - Wanna feel inspired? 💪",
        type: 'Quotes'
      },
      {
        text: 'Tuesday thoughts to keep you inspired',
        type: 'Quotes'
      },
      {
        text: 'It’s Wednesday! Just keep swimming. 😅',
        type: 'Quotes'
      },
      {
        text: 'Thursday quotes to motivate you',
        type: 'Quotes'
      }
    ],
    medias: [
      {
        path: 'pexels-vlada-karpovich-8045164.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323722/woxo-videos-user-gallery/darieladmin/pexels-vlada-karpovich-8045164_u6xq8p.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323722/woxo-videos-user-gallery/darieladmin/pexels-vlada-karpovich-8045164_u6xq8p.mp4'
        }
      },
      {
        path: 'pexels-stanislav-gaberov-5914351.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323774/woxo-videos-user-gallery/darieladmin/pexels-stanislav-gaberov-5914351_k6fern.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323774/woxo-videos-user-gallery/darieladmin/pexels-stanislav-gaberov-5914351_k6fern.mp4'
        }
      },
      {
        path: 'pexels-ben-mack-6775423.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323844/woxo-videos-user-gallery/darieladmin/pexels-ben-mack-6775423_zh0zer.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323844/woxo-videos-user-gallery/darieladmin/pexels-ben-mack-6775423_zh0zer.mp4'
        }
      },
      {
        path: 'pexels-mikita-yo-8644064.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323841/woxo-videos-user-gallery/darieladmin/pexels-mikita-yo-8644064_vwcndu.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323841/woxo-videos-user-gallery/darieladmin/pexels-mikita-yo-8644064_vwcndu.mp4'
        }
      }
    ],
    text: "It's Monday - Wanna feel inspired? 💪\n\nTuesday thoughts to keep you inspired\n\nIt’s Wednesday! Just keep swimming. 😅\n\nThursday quotes to motivate you",
    type: 'Quotes',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: '“All our dreams can come true, if we have the courage to pursue them.” – Walt Disney',
        type: 'Quotes'
      },
      {
        text: '“The secret of getting ahead is getting started.” – Mark Twain',
        type: 'Quotes'
      },
      {
        text: '“It’s hard to beat a person who never gives up.” – Babe Ruth',
        type: 'Quotes'
      },
      {
        text: '“It is never too late to be what you might have been.” – George Eliot',
        type: 'Quotes'
      }
    ],
    medias: [
      {
        path: 'pexels-vlada-karpovich-8045164.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323722/woxo-videos-user-gallery/darieladmin/pexels-vlada-karpovich-8045164_u6xq8p.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323722/woxo-videos-user-gallery/darieladmin/pexels-vlada-karpovich-8045164_u6xq8p.mp4'
        }
      },
      {
        path: 'pexels-stanislav-gaberov-5914351.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323774/woxo-videos-user-gallery/darieladmin/pexels-stanislav-gaberov-5914351_k6fern.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323774/woxo-videos-user-gallery/darieladmin/pexels-stanislav-gaberov-5914351_k6fern.mp4'
        }
      },
      {
        path: 'pexels-ben-mack-6775423.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323844/woxo-videos-user-gallery/darieladmin/pexels-ben-mack-6775423_zh0zer.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323844/woxo-videos-user-gallery/darieladmin/pexels-ben-mack-6775423_zh0zer.mp4'
        }
      },
      {
        path: 'pexels-mikita-yo-8644064.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323841/woxo-videos-user-gallery/darieladmin/pexels-mikita-yo-8644064_vwcndu.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323841/woxo-videos-user-gallery/darieladmin/pexels-mikita-yo-8644064_vwcndu.mp4'
        }
      }
    ],
    text: '“All our dreams can come true, if we have the courage to pursue them.” – Walt Disney\n\n“The secret of getting ahead is getting started.” – Mark Twain\n\n“It’s hard to beat a person who never gives up.” – Babe Ruth\n\n“It is never too late to be what you might have been.” – George Eliot',
    type: 'Quotes',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: 'Double tap if this makes your day 🤗',
        type: 'Quotes'
      },
      {
        text: 'Double tap if this makes your day 🤗',
        type: 'Quotes'
      },
      {
        text: 'Double tap if this makes your day 🤗',
        type: 'Quotes'
      },
      {
        text: 'Double tap if this makes your day 🤗',
        type: 'Quotes'
      }
    ],
    medias: [
      {
        path: 'pexels-vlada-karpovich-8045164.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323722/woxo-videos-user-gallery/darieladmin/pexels-vlada-karpovich-8045164_u6xq8p.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323722/woxo-videos-user-gallery/darieladmin/pexels-vlada-karpovich-8045164_u6xq8p.mp4'
        }
      },
      {
        path: 'pexels-stanislav-gaberov-5914351.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323774/woxo-videos-user-gallery/darieladmin/pexels-stanislav-gaberov-5914351_k6fern.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323774/woxo-videos-user-gallery/darieladmin/pexels-stanislav-gaberov-5914351_k6fern.mp4'
        }
      },
      {
        path: 'pexels-ben-mack-6775423.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323844/woxo-videos-user-gallery/darieladmin/pexels-ben-mack-6775423_zh0zer.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323844/woxo-videos-user-gallery/darieladmin/pexels-ben-mack-6775423_zh0zer.mp4'
        }
      },
      {
        path: 'pexels-mikita-yo-8644064.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323841/woxo-videos-user-gallery/darieladmin/pexels-mikita-yo-8644064_vwcndu.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323841/woxo-videos-user-gallery/darieladmin/pexels-mikita-yo-8644064_vwcndu.mp4'
        }
      }
    ],
    text: 'Double tap if this makes your day 🤗',
    type: 'Quotes',
    id: v4()
  }
];

export const sampleQuotesArtboardVideoData = (version = null) => {
  let data = [];
  switch (version) {
    case '0_1':
      data = version_0_1;
      break;
    case '1':
      data = version_1;
      break;
    case '2':
      data = version_2;
      break;
    default:
      data = version_0;
      break;
  }

  return data;
};
