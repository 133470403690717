import { v4 } from 'uuid';
import { sampleRestaurantAboutUsArtboardVideoData as version_0_1 } from './v0_1/sampleRestaurantAboutUs';
import { sampleRestaurantAboutUsArtboardVideoData as version_1 } from './v1/sampleRestaurantAboutUs';
import { sampleRestaurantAboutUsArtboardVideoData as version_2 } from './v2/sampleRestaurantAboutUs';

const version_0 = [
  {
    videoDefinition: [
      {
        text: 'Nothing more deliciosus than...',
        type: 'About Us'
      },
      {
        text: 'Enjoy delicious food!',
        type: 'About Us'
      },
      {
        text: 'Eat. Drink. Celebrate.',
        type: 'About Us'
      },
      {
        text: 'Nothing more satisfying than good food...',
        type: 'About Us'
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/541805125.hd.mp4?s=11ef1ff2c9909ff8f637b41de79b5e8fa3170e8e&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F541805125.sd.mp4%3Fs%3D19d822437a7c7219c7356eedd522397870baefe6%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7662734%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/541805125.hd.mp4?s=11ef1ff2c9909ff8f637b41de79b5e8fa3170e8e&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F541805125.sd.mp4%3Fs%3D19d822437a7c7219c7356eedd522397870baefe6%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7662734%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/578571572.hd.mp4?s=561a23bb3b3a7abc5c62821850353f0b6365b5ab&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F578571572.sd.mp4%3Fs%3D4d105de51d9773a69fa3922e20a997abdef76d73%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D338&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8879534%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/578571572.hd.mp4?s=561a23bb3b3a7abc5c62821850353f0b6365b5ab&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F578571572.sd.mp4%3Fs%3D4d105de51d9773a69fa3922e20a997abdef76d73%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D338&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8879534%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/556344582.hd.mp4?s=ddef1f05272ac771e84dccf7d99c2149519df97c&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F556344582.sd.mp4%3Fs%3D129c9deb6964931e131f08324d7df6400e31a7ba%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8106285%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/556344582.hd.mp4?s=ddef1f05272ac771e84dccf7d99c2149519df97c&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F556344582.sd.mp4%3Fs%3D129c9deb6964931e131f08324d7df6400e31a7ba%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8106285%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-4.jpeg',
        preview:
          'https://player.vimeo.com/external/487599353.hd.mp4?s=4421ac9256054bf3e9fe3dae4489f89d682ef561&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F487599353.sd.mp4%3Fs%3D87e618d3287987e495cd5d9672e48672d15b9d19%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6090500%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/487599353.hd.mp4?s=4421ac9256054bf3e9fe3dae4489f89d682ef561&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F487599353.sd.mp4%3Fs%3D87e618d3287987e495cd5d9672e48672d15b9d19%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6090500%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'Nothing more deliciosus than...\n\nEnjoy delicious food!\n\nEat. Drink. Celebrate.\n\nNothing more satisfying than good food...',
    type: 'About Us',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: 'Deliciously different every day!',
        type: 'About Us'
      },
      {
        text: 'Out of this world dishes.',
        type: 'About Us'
      },
      {
        text: 'Taste the difference in every bite!',
        type: 'About Us'
      },
      {
        text: 'Your cravings are our mission.',
        type: 'About Us'
      }
    ],
    medias: [
      {
        path: 'Fpreview-11.jpeg',
        preview:
          'https://player.vimeo.com/external/412306034.hd.mp4?s=67a93ffedd18e9e5c46fb05182ac8f4fc423c3cd&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F412306034.sd.mp4%3Fs%3Dac6a7908e7deec6c8c5d9ed21c0b95debe3251cb%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4253333%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/412306034.hd.mp4?s=67a93ffedd18e9e5c46fb05182ac8f4fc423c3cd&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F412306034.sd.mp4%3Fs%3Dac6a7908e7deec6c8c5d9ed21c0b95debe3251cb%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4253333%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-22.jpeg',
        preview:
          'https://player.vimeo.com/external/376205800.hd.mp4?s=ca5b85ed3cea95e600dfa9d608ee623227dfe938&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F376205800.sd.mp4%3Fs%3Dcf9caf23dd3d0fc32b2c6746fe2d86711b7744b2%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3298478%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/376205800.hd.mp4?s=ca5b85ed3cea95e600dfa9d608ee623227dfe938&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F376205800.sd.mp4%3Fs%3Dcf9caf23dd3d0fc32b2c6746fe2d86711b7744b2%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3298478%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-33.jpeg',
        preview:
          'https://player.vimeo.com/external/412257135.hd.mp4?s=3f116efec74cb166983af964dc75a7f926924de5&profile_id=173&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F412257135.sd.mp4%3Fs%3D1b9565ca3b57f13ea0c44b440914bdf2f3ebc061%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4252298%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/412257135.hd.mp4?s=3f116efec74cb166983af964dc75a7f926924de5&profile_id=173&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F412257135.sd.mp4%3Fs%3D1b9565ca3b57f13ea0c44b440914bdf2f3ebc061%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4252298%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-44.jpeg',
        preview:
          'https://player.vimeo.com/external/403295274.hd.mp4?s=7931bfcf1e8890f72b7c2d16242b570d9c398840&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F403295274.sd.mp4%3Fs%3D982a6b2a46be8631c7e117ff6fcb59b6bfaa45b5%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4057914%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/403295274.hd.mp4?s=7931bfcf1e8890f72b7c2d16242b570d9c398840&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F403295274.sd.mp4%3Fs%3D982a6b2a46be8631c7e117ff6fcb59b6bfaa45b5%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4057914%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'Deliciously different every day!\n\nOut of this world dishes.\n\nTaste the difference in every bite!\n\nYour cravings are our mission.',
    type: 'About Us',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: 'SAVE this post and visit us later!',
        type: 'About Us'
      },
      {
        text: "Drop a 😋 if you're down for good food!",
        type: 'About Us'
      },
      {
        text: "What's your favorite dish? Drop it below!",
        type: 'About Us'
      },
      {
        text: 'Share this post with other foodies you know!',
        type: 'About Us'
      }
    ],
    medias: [
      {
        path: 'Fpreview-111.jpeg',
        preview:
          'https://player.vimeo.com/external/412408434.hd.mp4?s=ad7918c9dcf17ecb1898e87cf83cfdd332567898&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F412408434.sd.mp4%3Fs%3D3e1dd266217ec2ddfc8035cb8b299172f2f2c328%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4255501%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/412408434.hd.mp4?s=ad7918c9dcf17ecb1898e87cf83cfdd332567898&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F412408434.sd.mp4%3Fs%3D3e1dd266217ec2ddfc8035cb8b299172f2f2c328%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4255501%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-222.jpeg',
        preview:
          'https://player.vimeo.com/external/374361731.hd.mp4?s=579cc26c0b9ee0a044ddfd14fffb2a4d4f9f29eb&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F374361731.sd.mp4%3Fs%3D421515ed426786e45fd107d1ea174bbecc1c9d50%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3256852%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/374361731.hd.mp4?s=579cc26c0b9ee0a044ddfd14fffb2a4d4f9f29eb&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F374361731.sd.mp4%3Fs%3D421515ed426786e45fd107d1ea174bbecc1c9d50%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3256852%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-333.jpeg',
        preview:
          'https://player.vimeo.com/external/400923981.hd.mp4?s=8b91a8081ae02a4ea050e9aca1d28fbd00c1fc8e&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F400923981.sd.mp4%3Fs%3D43985b29157d8cc3fed3769fc4308658cf594af5%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4008535%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/400923981.hd.mp4?s=8b91a8081ae02a4ea050e9aca1d28fbd00c1fc8e&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F400923981.sd.mp4%3Fs%3D43985b29157d8cc3fed3769fc4308658cf594af5%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4008535%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-444.jpeg',
        preview:
          'https://player.vimeo.com/external/420750659.hd.mp4?s=846515252ece28853cdca6180eb7ccd016c78399&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F420750659.sd.mp4%3Fs%3D3d29323003ed23120beab4ceb4c419d31a5240b4%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4443807%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/420750659.hd.mp4?s=846515252ece28853cdca6180eb7ccd016c78399&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F420750659.sd.mp4%3Fs%3D3d29323003ed23120beab4ceb4c419d31a5240b4%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4443807%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "SAVE this post and visit us later!\n\nDrop a 😋 if you're down for good food!\n\nWhat's your favorite dish? Drop it below!\n\nShare this post with other foodies you know!",
    type: 'About Us',
    id: v4()
  }
];

export const sampleRestaurantAboutUsArtboardVideoData = (version = null) => {
  let data = [];
  switch (version) {
    case '0_1':
      data = version_0_1;
      break;
    case '1':
      data = version_1;
      break;
    case '2':
      data = version_2;
      break;
    default:
      data = version_0;
      break;
  }

  return data;
};
