import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Typography, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import jsonp from 'jsonp';
import queryString from 'query-string';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Toast from '../../../../helpers/notification';
import { validateEmail } from '../../../../helpers/schemaValidation';

const useStyles = makeStyles((theme) => ({
  modalButton: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: '18px',
    backgroundColor: '#0684FA',
    border: '1px solid #0684FA',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 29px',
    textTransform: 'capitalize',
    letterSpacing: '0.8px',
    borderRadius: 45,
    '&:hover': {
      border: '1px solid #0060BF ',
      backgroundColor: '#0060BF',
      color: '#fff'
    }
  },
  modalTextHeader: {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: '40px',
    textAlign: 'center',
    color: '#414042',
    marginTop: 10,
    '@media only screen and (max-width: 425px)': {
      fontSize: 26,
      lineHeight: '20px'
    }
  },
  modalIconHeader: {
    fontSize: 25,
    marginRight: 5,
    position: 'relative',
    top: -2,
    '@media only screen and (max-width: 425px)': {
      fontSize: 20
    }
  },
  modalText: {
    marginBottom: 30,
    fontSize: 16,
    lineHeight: '28px',
    fontWeight: 400,
    maxWidth: 608,
    color: '#414042',
    textAlign: 'justify'
  },
  modalContentBox: {
    padding: '0 20px',
    '@media only screen and (max-width: 425px)': {
      padding: '0'
    }
  },
  modalButtonBox: {
    display: 'flex',
    width: '100%',
    padding: '0 20px 20px 20px',
    flexDirection: 'column',
    '@media only screen and (max-width: 425px)': {
      padding: '0 5px 10px 5px'
    }
  },
  formInput: {
    border: 'solid 1px #DFE3E7',
    width: '100%',
    borderRadius: 8,
    paddingLeft: 13,
    fontSize: 14,
    height: 44,
    color: '#414042',
    '&:focus': {
      outline: '1px solid #009FBF',
      borderRadius: 4
    },
    '&.invalid': {
      borderColor: '#ce3b0f',
      outline: 'none'
    },
    '&::-webkit-input-placeholder': {
      color: '#d0d0d0',
      fontSize: 14
    },
    '&:-ms-input-placeholder': {
      color: '#d0d0d0',
      fontSize: 14
    },
    '&::placeholder': {
      color: '#d0d0d0',
      fontSize: 14
    }
  },
  form: {
    marginTop: 24
  },
  formControl: {
    display: 'flex',
    marginBottom: 16,
    '& label': {
      width: '100%'
    }
  },
  label: {
    fontSize: 16,
    color: '#707070'
  },
  modalHeader: {
    fontSize: 16,
    color: '#414042',
    padding: '0 20px',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 40
  },
  error: {
    fontSize: 14,
    color: '#ce3b0f'
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const WaitingList = ({ forwardRef, onClose, type }) => {
  const classes = useStyles();

  const waitingListRef = React.useRef(null);

  const [openSubscribe, setOpenSubscribe] = React.useState(false);

  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [use, setUse] = React.useState('Agency');
  const [many, setMany] = React.useState('0 - 5');
  const [role, setRole] = React.useState('Marketer');
  const [other, setOther] = React.useState('');

  const [invalidName, setInvalidName] = React.useState(false);
  const [invalidNameMsg, setInvalidNameMsg] = React.useState('');
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidEmailMsg, setInvalidEmailMsg] = React.useState('');
  const [invalidCompany, setInvalidCompany] = React.useState(false);
  const [invalidCompanyMsg, setInvalidCompanyMsg] = React.useState('');

  React.useImperativeHandle(forwardRef, () => ({
    show: () => {
      setEmail('');
      setName('');
      setCompany('');
      setUse('Agency');
      setMany('0 - 5');
      setRole('Marketer');
      setOther('');
      setOpenSubscribe(true);
    },
    hide: () => {
      setOpenSubscribe(false);
    }
  }));

  const handleCloseSubscribe = () => {
    setOpenSubscribe(false);
    onClose();
  };

  const handleSubscribe = (e) => {
    e.preventDefault();

    let validForm = true;

    if (name === '') {
      validForm = false;
      setInvalidName(true);
      setInvalidNameMsg('The name field is mandatory');
    }
    if (email === '') {
      validForm = false;
      setInvalidEmail(true);
      setInvalidEmailMsg('The email field is mandatory');
    }
    if (type === 'Enterprise' && company === '') {
      validForm = false;
      setInvalidCompany(true);
      setInvalidCompanyMsg('The company field is mandatory');
    }

    if (validForm) {
      let formData = {};

      if (type === 'Enterprise') {
        formData = {
          EMAIL: email,
          NAME: name,
          COMPANY: company,
          MANY: many,
          ROLE: role,
          OTHER: other,
          USER_TYPE: type
        };
      } else {
        formData = {
          EMAIL: email,
          NAME: name,
          USE: use,
          MANY: many,
          ROLE: role,
          OTHER: other,
          USER_TYPE: type
        };
      }

      jsonp(
        `https://front10.us17.list-manage.com/subscribe/post-json?u=8adc856ac0510bf2f3f3eddac&amp;id=a11bc20fb9&${queryString.stringify(
          formData
        )}`,
        { param: 'c' },
        (err, data) => {
          // console.log('err:', err);
          // console.log('data:', data);
          if (data && data.result === 'success') {
            Toast.success('Thank you very much 😊');
            setEmail('');
            setName('');
            setCompany('');
            setUse('Agency');
            setMany('0 - 5');
            setRole('Marketer');
            setOther('');
          } else if (data.result === 'error') {
            Toast.error(data.msg);
          }
        }
      );
    }
  };

  const changeHandlerSubscribe = (event) => {
    const nam = event.target.name;
    const val = event.target.value;

    if (nam === 'name') {
      setName(val);
      if (val === '') {
        setInvalidName(true);
        setInvalidNameMsg('The name field is mandatory');
      } else {
        setInvalidName(false);
        setInvalidNameMsg('');
      }
    }

    if (nam === 'email') {
      setEmail(val);
      if (val === '') {
        setInvalidEmail(true);
        setInvalidEmailMsg('The email field is mandatory');
      } else if (!validateEmail(val)) {
        setInvalidEmail(true);
        setInvalidEmailMsg('Please enter an email address');
      } else {
        setInvalidEmail(false);
        setInvalidEmailMsg('');
      }
    }

    if (nam === 'company') {
      setCompany(val);
      if (val === '') {
        setInvalidCompany(true);
        setInvalidCompanyMsg('The company field is mandatory');
      } else {
        setInvalidCompany(false);
        setInvalidCompanyMsg('');
      }
    }

    if (nam === 'role') {
      setRole(val);
    }

    if (nam === 'use') {
      setUse(val);
    }

    if (nam === 'many') {
      setMany(val);
    }

    if (nam === 'other') {
      setOther(val);
    }
  };

  return (
    <Dialog
      onClose={handleCloseSubscribe}
      aria-labelledby="customized-dialog-title"
      open={openSubscribe}
      ref={waitingListRef}
    >
      <DialogTitle onClose={handleCloseSubscribe}>
        <Box className={classes.modalTextHeader}>
          {type === 'Enterprise' && (
            <>
              <span role="img" aria-label="Office Building" className={classes.modalIconHeader}>
                👋
              </span>
              <span>Get in touch</span>
            </>
          )}
          {type === 'Standard' && (
            <>
              <span role="img" aria-label="tada" className={classes.modalIconHeader}>
                👋
              </span>
              <span>Welcome</span>
            </>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.modalContentBox}>
          {type === 'Enterprise' && (
            <Typography gutterBottom className={classes.modalHeader}>
              Our business development team will contact you shortly.
            </Typography>
          )}
          {type === 'Standard' && (
            <Typography gutterBottom className={classes.modalHeader}>
              Help us to get in touch with you.
            </Typography>
          )}

          <form className={classes.form}>
            <Box className={classes.formControl}>
              <label htmlFor="name" className={classes.label}>
                <Box className={classes.formTitle}>Name</Box>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={changeHandlerSubscribe}
                  className={clsx(classes.formInput, invalidName === true ? 'invalid' : null)}
                  invalid={invalidName}
                />
                <span className={classes.error}>{invalidNameMsg}</span>
              </label>
            </Box>
            <Box className={classes.formControl}>
              <label htmlFor="email" className={classes.label}>
                <Box className={classes.formTitle}>Email</Box>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={changeHandlerSubscribe}
                  className={clsx(classes.formInput, invalidEmail === true ? 'invalid' : null)}
                  invalid={invalidEmail}
                />
                <span className={classes.error}>{invalidEmailMsg}</span>
              </label>
            </Box>
            {type === 'Enterprise' && (
              <Box className={classes.formControl}>
                <label htmlFor="company" className={classes.label}>
                  <Box className={classes.formTitle}>Company</Box>
                  <input
                    type="text"
                    name="company"
                    onChange={changeHandlerSubscribe}
                    className={clsx(classes.formInput, invalidCompany === true ? 'invalid' : null)}
                    invalid={invalidCompany}
                  />
                  <span className={classes.error}>{invalidCompanyMsg}</span>
                </label>
              </Box>
            )}
            <Box className={classes.formControl}>
              <label htmlFor="role" className={classes.label}>
                <Box className={classes.formTitle}>Your current role</Box>
                <select
                  name="role"
                  value={role}
                  onChange={changeHandlerSubscribe}
                  className={classes.formInput}
                >
                  <option value="Marketer">Marketer</option>
                  <option value="CEO">CEO</option>
                  <option value="Designer">Designer</option>
                  <option value="Small Business Owner">Small Business Owner</option>
                  <option value="Developer">Developer</option>
                </select>
              </label>
            </Box>
            {type === 'Standard' && (
              <Box className={classes.formControl}>
                <label htmlFor="use" className={classes.label}>
                  <Box className={classes.formTitle}>What will you be using WOXO for</Box>
                  <select
                    name="use"
                    value={use}
                    onChange={changeHandlerSubscribe}
                    className={classes.formInput}
                  >
                    <option value="Agency">Agency</option>
                    <option value="Freelancer">Freelancer</option>
                    <option value="Small Business">Small Business</option>
                    <option value="Large Company">Large Company</option>
                    <option value="Personal">Personal</option>
                    <option value="Student">Student</option>
                    <option value="Other">Other</option>
                  </select>
                </label>
              </Box>
            )}
            <Box className={classes.formControl}>
              <label htmlFor="many" className={classes.label}>
                <Box className={classes.formTitle}>
                  How many videos are you planning to generate a month
                </Box>
                <select
                  name="many"
                  value={many}
                  onChange={changeHandlerSubscribe}
                  className={classes.formInput}
                >
                  <option value="0 - 5">0 - 5</option>
                  <option value="5 - 50">5 - 50</option>
                  <option value="50 - 100">50 - 100</option>
                  <option value="100 - 300">100 - 300</option>
                  <option value="300 - 1000">300 - 1000</option>
                  <option value="More than 1000">More than 1000</option>
                </select>
              </label>
            </Box>
            <Box className={classes.formControl}>
              <label htmlFor="other" className={classes.label}>
                <Box className={classes.formTitle}>Additional comments</Box>
                <textarea
                  name="other"
                  value={other}
                  rows="3"
                  cols="10"
                  onChange={changeHandlerSubscribe}
                  className={classes.formInput}
                  style={{ height: 80, resize: 'none' }}
                />
              </label>
            </Box>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={classes.modalButtonBox}>
          <Button
            data-name={type === 'Standard' ? 'wl-standard-form-btn' : 'wl-enterp-form-btn'}
            data-type="btn-click"
            autoFocus
            onClick={handleSubscribe}
            className={classes.modalButton}
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

WaitingList.propTypes = {
  forwardRef: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
  type: PropTypes.string
};

WaitingList.defaultProps = {
  forwardRef: {},
  onClose: () => {},
  type: null
};

export default WaitingList;
