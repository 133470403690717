import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useStylesTutorial } from './styles';

const DialogTitle = ({ onClose }) => {
  const { closeButton, dialogTitle } = useStylesTutorial();
  return (
    <MuiDialogTitle disableTypography className={dialogTitle}>
      <Typography variant="h5">Watch Video</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = (props) => {
  const { dialogContent } = useStylesTutorial();
  return <MuiDialogContent classes={{ root: dialogContent }} {...props} />;
};

export default function CustomizedDialogs({ onClose, isOpen, children }) {
  const { backdropBackground, dialogPaper } = useStylesTutorial();

  //Don't close the dialog if clicked the backdrop
  const handleClose = (_, reason) => {
    if (reason !== 'backdropClick') onClose();
  };

  return (
    <Dialog
      BackdropProps={{ classes: { root: backdropBackground } }}
      onClose={handleClose}
      open={isOpen}
      classes={{ paper: dialogPaper }}
      scroll="body"
    >
      <DialogTitle onClose={onClose} />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
