import { v4 } from 'uuid';

export const sampleRestaurantDeliveryServicesArtboardVideoData = [
  {
    videoDefinition: [
      {
        text: '',
        type: 'Delivery Services',
        v1: [
          {
            text: 'From your over to your doorstep!',
            type: 'Delivery Services'
          },
          {
            text: 'Hot, tasty food will reach you.',
            type: 'Delivery Services'
          },
          {
            text: "Drop a 😋 if you're ready to order!",
            type: 'Delivery Services'
          }
        ]
      },
      {
        text: '',
        type: 'Delivery Services',
        v1: [
          {
            text: 'We are now delivering...',
            type: 'Delivery Services'
          },
          {
            text: 'Stay at home, we will deliver your food.',
            type: 'Delivery Services'
          },
          {
            text: 'Like this post if you want to order now!',
            type: 'Delivery Services'
          }
        ]
      },
      {
        text: '',
        type: 'Delivery Services',
        v1: [
          {
            text: "Don't starve, just order online",
            type: 'Delivery Services'
          },
          {
            text: 'Enjoy delicious food within minutes.',
            type: 'Delivery Services'
          },
          {
            text: "SAVE this post to remember to order when you're hungry!",
            type: 'Delivery Services'
          }
        ]
      },
      {
        text: '',
        type: 'Delivery Services',
        v1: [
          {
            text: 'Enjoy the food you love at home!',
            type: 'Delivery Services'
          },
          {
            text: 'No more waiting for your food to be ready. Just order online!',
            type: 'Delivery Services'
          },
          {
            text: 'Share this post and spread good mood food!',
            type: 'Delivery Services'
          }
        ]
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/427352838.hd.mp4?s=b8c4ea31f3414d84885661f5e3b5ef87b59de77b&profile_id=173&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427352838.sd.mp4%3Fs%3D50fa85cc46839e630e849ebcdc4765a85b0bc7f4%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605490%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/427352838.hd.mp4?s=b8c4ea31f3414d84885661f5e3b5ef87b59de77b&profile_id=173&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427352838.sd.mp4%3Fs%3D50fa85cc46839e630e849ebcdc4765a85b0bc7f4%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605490%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/598657445.hd.mp4?s=d178ef0461ba98ee7a1f332c2ccafbaf51c7317d&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F598657445.sd.mp4%3Fs%3D9c215c4202dc7f63c05c1852c551bca26fdf45d8%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F9460724%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/598657445.hd.mp4?s=d178ef0461ba98ee7a1f332c2ccafbaf51c7317d&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F598657445.sd.mp4%3Fs%3D9c215c4202dc7f63c05c1852c551bca26fdf45d8%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F9460724%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/427353831.hd.mp4?s=fc9c8ee9b21abab0207537af664c72a90c464db0&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427353831.sd.mp4%3Fs%3Da3503f0039a60270713377f47d46b5ce19f35dbd%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605488%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/427353831.hd.mp4?s=fc9c8ee9b21abab0207537af664c72a90c464db0&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427353831.sd.mp4%3Fs%3Da3503f0039a60270713377f47d46b5ce19f35dbd%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605488%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-4.jpeg',
        preview:
          'https://player.vimeo.com/external/543022463.hd.mp4?s=29ad49ccc6bc9f776167f9cd88303f688734b7bd&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F543022463.sd.mp4%3Fs%3D972fe903e8c9fb0fac88a34cfc80e72896a5deff%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D338&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7702052%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/543022463.hd.mp4?s=29ad49ccc6bc9f776167f9cd88303f688734b7bd&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F543022463.sd.mp4%3Fs%3D972fe903e8c9fb0fac88a34cfc80e72896a5deff%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D338&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7702052%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "From your over to your doorstep!\n\nHot, tasty food will reach you.\n\nDrop a 😋 if you're ready to order!\n\n---\n\nWe are now delivering...\n\nStay at home, we will deliver your food.\n\nLike this post if you want to order now!\n\n---\n\nDon't starve, just order online\n\nEnjoy delicious food within minutes.\n\nSAVE this post to remember to order when you're hungry!\n\n---\n\nEnjoy the food you love at home!\n\nNo more waiting for your food to be ready. Just order online!\n\nShare this post and spread good mood food!",
    type: 'Delivery Services',
    id: v4()
  }
];
