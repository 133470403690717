import dataVideoParse from './helpers/dataVideoParse';

export default function useDataVideoParse({
  setData,
  setTotalVideos,
  setGlobalError,
  setState,
  useToast,
  setIsLoading
}) {
  return dataVideoParse({
    setData,
    setTotalVideos,
    setGlobalError,
    setState,
    useToast,
    setIsLoading
  });
}
