import { v4 } from 'uuid';
import { sampleRestaurantDeliveryServicesArtboardVideoData as version_0_1 } from './v0_1/sampleRestaurantDeliveryServices';
import { sampleRestaurantDeliveryServicesArtboardVideoData as version_1 } from './v1/sampleRestaurantDeliveryServices';
import { sampleRestaurantDeliveryServicesArtboardVideoData as version_2 } from './v2/sampleRestaurantDeliveryServices';

const version_0 = [
  {
    videoDefinition: [
      {
        text: 'From your over to your doorstep!',
        type: 'Delivery Services'
      },
      {
        text: 'We are now delivering...',
        type: 'Delivery Services'
      },
      {
        text: "Don't starve, just order online",
        type: 'Delivery Services'
      },
      {
        text: 'Enjoy the food you love at home!',
        type: 'Delivery Services'
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/427352838.hd.mp4?s=b8c4ea31f3414d84885661f5e3b5ef87b59de77b&profile_id=173&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427352838.sd.mp4%3Fs%3D50fa85cc46839e630e849ebcdc4765a85b0bc7f4%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605490%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/427352838.hd.mp4?s=b8c4ea31f3414d84885661f5e3b5ef87b59de77b&profile_id=173&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427352838.sd.mp4%3Fs%3D50fa85cc46839e630e849ebcdc4765a85b0bc7f4%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605490%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/598657445.hd.mp4?s=d178ef0461ba98ee7a1f332c2ccafbaf51c7317d&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F598657445.sd.mp4%3Fs%3D9c215c4202dc7f63c05c1852c551bca26fdf45d8%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F9460724%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/598657445.hd.mp4?s=d178ef0461ba98ee7a1f332c2ccafbaf51c7317d&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F598657445.sd.mp4%3Fs%3D9c215c4202dc7f63c05c1852c551bca26fdf45d8%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F9460724%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/427353831.hd.mp4?s=fc9c8ee9b21abab0207537af664c72a90c464db0&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427353831.sd.mp4%3Fs%3Da3503f0039a60270713377f47d46b5ce19f35dbd%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605488%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/427353831.hd.mp4?s=fc9c8ee9b21abab0207537af664c72a90c464db0&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427353831.sd.mp4%3Fs%3Da3503f0039a60270713377f47d46b5ce19f35dbd%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605488%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-4.jpeg',
        preview:
          'https://player.vimeo.com/external/543022463.hd.mp4?s=29ad49ccc6bc9f776167f9cd88303f688734b7bd&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F543022463.sd.mp4%3Fs%3D972fe903e8c9fb0fac88a34cfc80e72896a5deff%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D338&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7702052%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/543022463.hd.mp4?s=29ad49ccc6bc9f776167f9cd88303f688734b7bd&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F543022463.sd.mp4%3Fs%3D972fe903e8c9fb0fac88a34cfc80e72896a5deff%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D338&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7702052%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "From your over to your doorstep!\n\nWe are now delivering...\n\nDon't starve, just order online\n\nEnjoy the food you love at home!",
    type: 'Delivery Services',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: 'Hot, tasty food will reach you.',
        type: 'Delivery Services'
      },
      {
        text: 'Stay at home, we will deliver your food.',
        type: 'Delivery Services'
      },
      {
        text: 'Enjoy delicious food within minutes.',
        type: 'Delivery Services'
      },
      {
        text: 'No more waiting for your food to be ready. Just order online!',
        type: 'Delivery Services'
      }
    ],
    medias: [
      {
        path: 'Fpreview-11.jpeg',
        preview:
          'https://player.vimeo.com/external/376205895.hd.mp4?s=f47fcdcdf13e1026cc1248f7ac680ae1436a5e6d&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F376205895.sd.mp4%3Fs%3D115c13e350baee3430d2d32bd6e52e845225349d%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3298479%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/376205895.hd.mp4?s=f47fcdcdf13e1026cc1248f7ac680ae1436a5e6d&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F376205895.sd.mp4%3Fs%3D115c13e350baee3430d2d32bd6e52e845225349d%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3298479%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-22.jpeg',
        preview:
          'https://player.vimeo.com/external/536618203.hd.mp4?s=3ea95d505a1f14000ddd1aeb27360d2226a37b43&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F536618203.sd.mp4%3Fs%3D34153b022d000fa674644446828b0daa3c7784ea%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7501791%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/536618203.hd.mp4?s=3ea95d505a1f14000ddd1aeb27360d2226a37b43&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F536618203.sd.mp4%3Fs%3D34153b022d000fa674644446828b0daa3c7784ea%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7501791%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-33.jpeg',
        preview:
          'https://player.vimeo.com/external/501633776.hd.mp4?s=91066978d3110d469d24153e94609f63a18eafb6&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F501633776.sd.mp4%3Fs%3Dac9ee20bd46a41da6db3834b75b7cdb4bc9bd6f7%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6529566%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/501633776.hd.mp4?s=91066978d3110d469d24153e94609f63a18eafb6&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F501633776.sd.mp4%3Fs%3Dac9ee20bd46a41da6db3834b75b7cdb4bc9bd6f7%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6529566%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-44.jpeg',
        preview:
          'https://player.vimeo.com/external/376190181.hd.mp4?s=a876bdfcae5cfde541d89b32237f28be9ff725cf&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F376190181.sd.mp4%3Fs%3D4ba1f177e3c6b3d2526b72bb5c11cd67e2629c02%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3298011%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/376190181.hd.mp4?s=a876bdfcae5cfde541d89b32237f28be9ff725cf&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F376190181.sd.mp4%3Fs%3D4ba1f177e3c6b3d2526b72bb5c11cd67e2629c02%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3298011%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'Hot, tasty food will reach you.\n\nStay at home, we will deliver your food.\n\nEnjoy delicious food within minutes.\n\nNo more waiting for your food to be ready. Just order online!',
    type: 'Delivery Services',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: "Drop a 😋 if you're ready to order!",
        type: 'Delivery Services'
      },
      {
        text: 'Like this post if you want to order now!',
        type: 'Delivery Services'
      },
      {
        text: "SAVE this post to remember to order when you're hungry!",
        type: 'Delivery Services'
      },
      {
        text: 'Share this post and spread good mood food!',
        type: 'Delivery Services'
      }
    ],
    medias: [
      {
        path: 'Fpreview-111.jpeg',
        preview:
          'https://player.vimeo.com/external/523094599.hd.mp4?s=8ae25750d190367c585567d121b301648b60912a&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F523094599.sd.mp4%3Fs%3D41ea9c311bbfb351f8eeb59b75e12685facd39a2%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7126074%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/523094599.hd.mp4?s=8ae25750d190367c585567d121b301648b60912a&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F523094599.sd.mp4%3Fs%3D41ea9c311bbfb351f8eeb59b75e12685facd39a2%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7126074%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-222.jpeg',
        preview:
          'https://player.vimeo.com/external/403301431.hd.mp4?s=431077f3f193578a51b16dfe307c64364f52b661&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F403301431.sd.mp4%3Fs%3De52e185ccadd3fa9c6f98fb5ebd5b955d0f9eb08%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4058072%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/403301431.hd.mp4?s=431077f3f193578a51b16dfe307c64364f52b661&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F403301431.sd.mp4%3Fs%3De52e185ccadd3fa9c6f98fb5ebd5b955d0f9eb08%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4058072%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-333.jpeg',
        preview:
          'https://player.vimeo.com/external/390191886.hd.mp4?s=09a9b4a85232eeb0f50bd26891891dc87da06f56&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F390191886.sd.mp4%3Fs%3Db72fe5a5b88dd39842e5b9fb3acd62ed60b58b9c%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3704909%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/390191886.hd.mp4?s=09a9b4a85232eeb0f50bd26891891dc87da06f56&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F390191886.sd.mp4%3Fs%3Db72fe5a5b88dd39842e5b9fb3acd62ed60b58b9c%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3704909%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-444.jpeg',
        preview:
          'https://player.vimeo.com/external/390281673.hd.mp4?s=ba99b616ed862782b695e4f2c076e0087fa4990b&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F390281673.sd.mp4%3Fs%3D69076673614b7fd6d515ab4bb692cdeed8003a49%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3709159%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/390281673.hd.mp4?s=ba99b616ed862782b695e4f2c076e0087fa4990b&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F390281673.sd.mp4%3Fs%3D69076673614b7fd6d515ab4bb692cdeed8003a49%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3709159%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "Drop a 😋 if you're ready to order!\n\nLike this post if you want to order now!\n\nSAVE this post to remember to order when you're hungry!\n\nShare this post and spread good mood food!",
    type: 'Delivery Services',
    id: v4()
  }
];

export const sampleRestaurantDeliveryServicesArtboardVideoData = (version = null) => {
  let data = [];
  switch (version) {
    case '0_1':
      data = version_0_1;
      break;
    case '1':
      data = version_1;
      break;
    case '2':
      data = version_2;
      break;
    default:
      data = version_0;
      break;
  }

  return data;
};
