import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Link, Typography } from '@material-ui/core';
// import Error404 from 'notfound/error404.png';

function ErrorAlert() {
  return (
    <Box role="alert" display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
      <img src="/notfound/oops.png" alt="Error image" width="40%" />
      <Typography variant="h6">
        Sorry, an error has ocurred.
        <Link href={window.location.href}> Try reloading the page.</Link>
      </Typography>
    </Box>
  );
}

export default function ErrorAlertBoundary(props) {
  return <ErrorBoundary FallbackComponent={ErrorAlert} {...props} />;
}
