import { Box, Button, Grid } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useFormFields, useMailChimpForm } from 'use-mailchimp-form';
import ActionAlert from '~/components/Alert';
import RemoteAutoComplete from '~/components/RemoteAutoComplete';
import useStyles from '~/components/UserProfessionJob/styles';
import { API_HOST } from '~/services/app.config';
import { types } from '~/services/entity.services';
import { setProfile } from '../../services/security.service';

const MailchimpSurvey = ({ user, handleEscape }) => {
  const url =
    'https://front10.us17.list-manage.com/subscribe/post?u=8adc856ac0510bf2f3f3eddac&amp;id=a11bc20fb9&amp;f_id=006ebde0f0';
  const [globalError, setGlobalError] = React.useState('');
  const [loadingList, setLoadingList] = React.useState(false);
  const [profileProfession, setProfileProfession] = React.useState('');
  const [profileType, setProfileType] = React.useState('');
  const [entityTypes, setEntityTypes] = React.useState([]);
  const [name, setName] = React.useState(user?.name || '');
  const [invalidName, setInvalidName] = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState('');
  const dialogRef = React.useRef(null);
  const { loading, error, success, message, handleSubmit } = useMailChimpForm(url);
  const { fields, handleFieldChange } = useFormFields({
    NAME: name,
    USE: profileType,
    ROLE: profileProfession,
    EMAIL: user?.email
  });
  const classes = useStyles();

  const ListSkeleton = () => {
    const classes = useStyles();
    const items = [1, 2, 3, 4, 5, 6, 7, 8];
    return (
      <Grid container className={classes.gridContainer}>
        {items.map((item) => (
          <Grid key={item} item xs={6} sm={6} lg={6} xl={6}>
            <Box className={clsx(classes.item, 'loader')}>...</Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  useEffect(() => {
    fields.EMAIL = user?.email;
    setLoadingList(true);
    user &&
      types()
        .then((response) => {
          setEntityTypes(response);
          setLoadingList(false);
        })
        .catch(() => {
          setLoadingList(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      handleEscape();
      setTimeout(() => {
        setProfile({
          name: name,
          username: user.username,
          email: user.email,
          profession: profileProfession,
          entityType: profileType
        })
          .then(() => {
            // onChange({ profession: profileProfession, entityType: profileType, name: user.name });
            dialogRef.current && dialogRef.current.hide();
            const url = new URL(window.location.href);
            url.searchParams.delete('signupSurvey');
            window.history.replaceState(null, null, url);
          })
          .catch((e) => {
            console.log('error', e);
          });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (error) {
      if (message.includes('timed out')) {
        setDisplayMessage('Please check your internet connection and try again.');
      } else if (message.includes('already subscribed')) {
        setDisplayMessage('You are already subscribed to this form.');
        setTimeout(() => {
          handleEscape();
        }, 2000);
      } else if (message.includes('invalid email address')) {
        setDisplayMessage('Please enter a valid email address.');
      } else if (message.includes('has too many recent signup requests')) {
        setDisplayMessage('Please try again later.');
        setTimeout(() => {
          handleEscape();
        }, 2000);
      } else {
        setDisplayMessage('Something went wrong. Please try again.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, message]);

  useEffect(() => {
    fields.EMAIL = user?.email;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const handleProfession = (e, value) => {
    setProfileProfession(value && value._id);
    fields.ROLE = value.name;
    fields.EMAIL = user?.email;
  };

  const handleType = (value, name) => {
    fields.USE = name;
    fields.EMAIL = user?.email;
    setProfileType(value);
  };

  const handleName = (e) => {
    setName(e.target.value);
    fields.NAME = e.target.value;
    fields.EMAIL = user.email;

    if (e.target.value !== '') {
      setInvalidName(false);
    } else {
      setInvalidName(true);
    }
  };

  return (
    <Box>
      <Box className={classes.title} textAlign="center">
        Welcome, Woxiam
        <span role="img" aria-label="Hi" style={{ marginLeft: 8 }}>
          👋
        </span>
      </Box>
      <Box className={classes.subTitle}>
        For the best experience possible, tell us a little about yourself.
      </Box>
      {globalError && <ActionAlert message={globalError} className={classes.errorAlert} />}
      <Box marginBottom="24px" className={classes.autoCompleteContainer}>
        <Box className={classes.label}>What should we call you?</Box>
        {invalidName && (
          <ActionAlert
            icon={<ReportProblemOutlinedIcon />}
            variant="text"
            message={'The First Name field is required'}
            className={classes.formAlert}
          />
        )}
        <input
          invalid={invalidName}
          className={clsx(classes.formInput, invalidName === true ? 'invalid' : null)}
          type="text"
          onChange={handleName}
          placeholder="Jane"
          required
          name="NAME"
        />
      </Box>
      <Box marginBottom="24px">
        <Box className={classes.label}>What kind of work do you do?</Box>
        {loadingList && <ListSkeleton />}
        {!loadingList && (
          <Grid container className={classes.gridContainer}>
            {entityTypes.map((item) => (
              <Grid key={item._id} item xs={6} sm={6} lg={6} xl={6}>
                <Box
                  className={clsx(classes.item, item._id === profileType ? 'selected' : '')}
                  onClick={() => handleType(item._id, item.name)}
                >
                  {item.name}
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Box marginBottom="24px" className={classes.autoCompleteContainer}>
        <Box className={classes.label}>What is you role?</Box>
        <RemoteAutoComplete
          inputSettings={{
            placeholder: 'Select a role'
          }}
          fieldLabel="name"
          url={`${API_HOST}/professions?_sort=order:ASC`}
          autocompleteSettings={{
            style: {
              width: '100%',
              display: 'inline-flex',
              marginRight: '10px'
            }
          }}
          onChange={handleProfession}
          value={profileProfession}
          className={classes.autocomplete}
        />
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          className={classes.startButton}
          disabled={loading || !profileType || !profileProfession || invalidName}
          data-label="post-signup-survey-completed"
          data-type="btn-click"
          onClick={(event) => {
            event.preventDefault();
            handleSubmit(fields);
          }}
        >
          Continue
        </Button>
      </Box>
      {loading && (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {error && (
        <ActionAlert
          icon={<ReportProblemOutlinedIcon />}
          variant="text"
          message={displayMessage}
          className={classes.formAlert}
        />
      )}
      {success && (
        <ActionAlert
          icon={<CheckCircleOutlineIcon />}
          variant="text"
          message={message}
          className={classes.formSuccess}
        />
      )}
    </Box>
  );
};

export default MailchimpSurvey;
