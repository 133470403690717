import { makeStyles } from '@material-ui/styles';

const previewCommonCssProps = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'absolute'
};

export const useStylesTutorial = makeStyles((theme) => ({
  //Styles of the button that opens the modal (on the nav bar)
  //In main page, desktop view
  menuButtonMain: {
    fontSize: 14,
    padding: 0,
    position: 'relative',
    letterSpacing: '1.4px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '19px',
    color: theme.palette.primary.light,
    textDecoration: 'none',
    marginRight: '5%',
    backgroundColor: 'transparent',
    transition: 'all 0.2s ease-in-out',
    '@media only screen and (max-width: 1024px)': {
      marginRight: '3%'
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      bottom: '-10px',
      width: 0,
      height: '1px',
      margin: '5px 0 0',
      opacity: 0,
      backgroundColor: theme.palette.primary.light,
      transition: 'all 0.2s ease-in-out',
      transitionDuration: '0.35s',
      left: '50%'
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      bottom: '-10px',
      width: 0,
      height: '1px',
      margin: '5px 0 0',
      opacity: 0,
      backgroundColor: theme.palette.primary.light,
      transition: 'all 0.2s ease-in-out',
      transitionDuration: '0.35s',
      right: '50%'
    },
    '&:hover:before': {
      left: '50%',
      width: '50%',
      opacity: 1
    },
    '&:hover:after': {
      right: '50%',
      width: '50%',
      opacity: 1
    }
  },
  //In main page, mobile view
  menuButtonMobile: {
    fontSize: 14,
    letterSpacing: '1.4px',
    backgroundColor: 'transparent',
    lineHeight: '19px',
    color: theme.palette.primary.light,
    textDecoration: 'none',
    marginBottom: '30px'
  },
  //In the video editor, templates and export pages (both mobile and desktop)
  menuButtonEditor: {
    fontWeight: 600,
    color: theme.palette.primary.light
  },
  //Modal backdrop
  backdropBackground: {
    backgroundColor: 'rgba(255,255,255,0.9)'
  },
  //Modal Close button
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.light,
    height: '50px',
    width: '50px',
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.light
    }
  },
  //Dialog root container
  dialogPaper: {
    borderRadius: '15px',
    width: 'calc( 100% - 40px )',
    margin: '20px',
    padding: 0,
    maxWidth: 'none',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginTop: 'max(10px, 1%)',
      marginBottom: 'max(10px, 1%)',
      marginLeft: '50px',
      marginRight: '50px',
      width: 'min(calc( 100% - 100px ), 2400px)',
      maxWidth: 'none'
    }
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    paddingBottom: 0
  },
  dialogContent: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      flexDirection: 'row'
    }
  },
  //Box container of videos titles and control buttons
  stepBoxRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      width: '320px'
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      width: '280px'
    }
  },
  //Videos titles container
  stepRoot: {
    width: '100%',
    margin: '0 0 10px 0',
    padding: 0
  },
  //Video title and icon container
  stepComp: ({ isFirstVideo, isLastVideo }) => ({
    cursor: 'pointer',
    padding: isFirstVideo ? '0 0 8px 0' : isLastVideo ? '8px 0 0 0 ' : '8px 0',
    '&:hover .MuiSvgIcon-root:not(.current-tuturial)': {
      scale: 1.5
    }
  }),
  //Line between videos titles
  stepConnector: {
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    minHeight: 12,
    marginLeft: '16px',
    borderColor: theme.palette.grey[300]
  },
  //Video title
  stepLabelSelected: {
    fontWeight: 600,
    color: theme.palette.grey[700]
  },
  //Current video icon
  activeStepIcon: {
    marginLeft: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  //Rest of videos icons
  stepIcon: {
    marginLeft: '4px',
    transition: theme.transitions.create(['scale'], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut
    })
  },
  //Each control button
  stepControlButton: {
    borderRadius: '30px',
    width: '50%',
    margin: '10px 0'
  },
  //Video player and loading preview container
  videoBox: {
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginTop: 0,
      marginLeft: '20px'
    }
  },
  /*A white surface that appears on top of the video player
   while is still loading, completely covering it*/
  videoBlankPaper: {
    ...previewCommonCssProps,
    content: '""',
    zIndex: 200,
    backgroundColor: 'white'
  },
  /*A blurred element on top of the white surface, it contains
  an img with the app logo*/
  videoBlurredPreview: {
    ...previewCommonCssProps,
    borderRadius: 12,
    zIndex: 300,
    display: 'flex',
    borderWidth: '6px',
    borderStyle: 'solid',
    borderImage: 'linear-gradient(to right, blue, purple, pink, yellow, green, orange, red) 1',
    alignItems: 'center',
    justifyContent: 'center',
    WebkitFilter: 'blur(15px)',
    MozFilter: 'blur(15px)',
    OFilter: 'blur(15px)',
    MsFilter: 'blur(15px)',
    filter: 'blur(15px)'
  },
  //Video player container
  videoPlayerDiv: {
    width: '100%',
    height: 'calc( 56.25vw - 50px )',
    borderRadius: 12,
    overflow: 'hidden',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      height: 'calc( 56.25vw - 275px )'
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      height: 'min(calc( 56.25vw - 252px ), 1155px)'
    }
  }
}));
