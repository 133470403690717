import React from 'react';
import PropTypes, { element } from 'prop-types';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import ObjectPath from 'object-path';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { find } from '../../services/concept.service';
import ComingSoon from '../VideoGenerator/components/ComingSoon';
import { isArray } from '../../helpers/javascript';

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    '& input': {
      height: 25
    },
    '& .Mui-focused': {
      '& fieldset': {
        borderWidth: '1px!important',
        borderColor: '#009FBF!important'
      }
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: '#DFE3E7'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'solid 1px #DFE3E7'
    },
    width: '280px',
    '@media only screen and (max-width: 425px)': {
      width: '86vw'
    },
    '@media only screen and (max-width: 320px)': {
      width: '90vw'
    }
  }
}));

const RemoteAutoComplete = ({
  url,
  method,
  headers,
  fieldLabel,
  onChange,
  autocompleteSettings,
  inputSettings,
  disabled,
  fieldId,
  value,
  forwardRef,
  autoSelect,
  hardAutoSelect,
  renderOption,
  className,
  defaultOptions,
  cssClasses,
  openOnFocus,
  inputRef,
  renderInput
}) => {
  const classes = useStyles();
  const [elements, setElements] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const pendingChanges = React.useRef(false);
  const comingSoonRef = React.useRef(null);

  const setValue = ({ value, elements }) => {
    if (value) {
      const newSelected = elements.find((e) => ObjectPath.get(e, fieldId) === value);
      if (newSelected) {
        setSelected(newSelected);
        if (pendingChanges.current) {
          pendingChanges.current = false;
          onChange(null, newSelected);
        }
      } else setSelected(elements[0]);
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => ObjectPath.get(option, fieldLabel)
  });

  const findElements = ({ url, method, headers, defaultOptions }) => {
    setIsLoading(true);
    if (isArray(defaultOptions)) {
      setElements([...defaultOptions]);
      let index = -1;
      if (autoSelect === 'last') index = defaultOptions.length - 1;
      else if (autoSelect === 'first') index = 0;
      else if (Number(autoSelect)) index = Number(autoSelect);
      setIsLoading(false);
      if (index !== -1 && defaultOptions[index]) {
        setSelected(defaultOptions[index]);
        if (hardAutoSelect) onChange(null, defaultOptions[index]);
      }
    } else {
      find({ url, method })
        .then((response) => {
          setElements([...response]);
          let index = -1;
          if (autoSelect === 'last') index = response.length - 1;
          else if (autoSelect === 'first') index = 0;
          else if (Number(autoSelect)) index = Number(autoSelect);
          setIsLoading(false);
          if (index !== -1 && response[index]) {
            setSelected(response[index]);
            if (hardAutoSelect) onChange(null, response[index]);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  React.useImperativeHandle(forwardRef, () => ({
    reload: () => findElements({ url, method, headers, defaultOptions }),
    pendingChange: () => {
      pendingChanges.current = true;
    }
  }));

  React.useEffect(() => {
    setValue({ value, elements });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME setValue should be included
  }, [value, elements]);

  React.useEffect(() => {
    findElements({ url, method, headers, defaultOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME defaultOptions, findElements and headers should be included
  }, [url, method]);

  const handleClickOpenComingSoon = () => {
    comingSoonRef.current.show();
  };

  const handleChange = (e, val) => {
    if (val.settings?.toDo) {
      handleClickOpenComingSoon();
    } else {
      setSelected(val);
      onChange(e, val);
    }
  };

  const handleRenderOption = (option) => renderOption(option, fieldLabel);

  return (
    <>
      <Autocomplete
        classes={cssClasses}
        disableClearable
        selectOnFocus={false}
        className={clsx(classes.autoComplete, className)}
        filterOptions={filterOptions}
        size="small"
        openOnFocus={openOnFocus}
        options={elements}
        loading={isLoading}
        getOptionLabel={(option) => ObjectPath.get(option, fieldLabel)}
        disabled={disabled}
        value={selected}
        renderOption={handleRenderOption}
        renderInput={
          renderInput ||
          ((params) => (
            <TextField
              variant="outlined"
              fullWidth
              {...params}
              {...inputSettings}
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          ))
        }
        onChange={handleChange}
        {...autocompleteSettings}
      />
      <ComingSoon forwardRef={comingSoonRef} />
    </>
  );
};

RemoteAutoComplete.propTypes = {
  // Url for request
  url: PropTypes.string.isRequired,
  // Method for url request
  method: PropTypes.oneOf(['POST', 'GET']),
  // Header for request
  headers: PropTypes.objectOf(PropTypes.any),
  // Label to show in checkbox
  fieldLabel: PropTypes.string,
  // Id of each element in list
  fieldId: PropTypes.string,
  // Function fire when is selected or unselected elements
  onChange: PropTypes.func,
  // Setting for autocomplete
  autocompleteSettings: PropTypes.objectOf(PropTypes.any),
  // Setting for input autocomplete
  inputSettings: PropTypes.objectOf(PropTypes.any),
  // Enable or disabled this autocomplete
  disabled: PropTypes.bool,
  // Value of the component
  value: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
    PropTypes.number
  ]),
  // Autos elect index
  autoSelect: PropTypes.string,
  // Define if onChange function is fired when autoSelect prop is defined
  hardAutoSelect: PropTypes.bool,
  disableClearable: PropTypes.bool,
  renderOption: PropTypes.func,
  renderInput: PropTypes.func,
  forwardRef: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.func]),
  className: PropTypes.string,
  // In case you want to use a hardcoded response
  defaultOptions: PropTypes.objectOf(PropTypes.any),
  cssClasses: PropTypes.objectOf(PropTypes.any),
  openOnFocus: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.func])
};

RemoteAutoComplete.defaultProps = {
  method: 'GET',
  headers: {},
  fieldLabel: 'label',
  fieldId: '_id',
  onChange: () => {},
  autocompleteSettings: {
    style: {
      width: 250,
      display: 'inline-flex',
      marginRight: '10px'
    }
  },
  inputSettings: {},
  disabled: false,
  value: null,
  autoSelect: null,
  hardAutoSelect: true,
  disableClearable: false,
  renderOption: (option, fieldLabel) => <span>{ObjectPath.get(option, fieldLabel)}</span>,
  renderInput: null,
  forwardRef: () => {},
  className: null,
  defaultOptions: null,
  cssClasses: null,
  openOnFocus: false,
  inputRef: () => {}
};

export default React.memo(RemoteAutoComplete, (prevProps, nextProps) => {
  return (
    prevProps.url === nextProps.url &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.value === nextProps.value
  );
});
