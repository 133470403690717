import React from 'react';
import { clone } from '../helpers/javascript';

const getCurrentLength = ({ item, template }) => {
  if (item.ScenesLength) return item.ScenesLength.length;
  else {
    template.clips.filter(
      (c, index) =>
        item[`Media ${index + 1}`] || item[`Title ${index + 1}`] || item[`Text ${index + 1}`]
    ).length;
  }
};

const normalize = () => {
  return (_data) => {
    const data = clone(_data);
    let max = 0;
    let maxLength = 0;

    data.map((d) => {
      const currentLength = getCurrentLength({
        item: d.data,
        template: d.template
      });

      if (currentLength > maxLength) maxLength = currentLength;
      if (d.template.clips.length > max) max = d.template.clips.length;
      return d;
    });

    data.map((d, index) => {
      data[index].meta = data[index].meta || {};
      data[index].meta.maxLength = maxLength;
      data[index].meta.currentLength = getCurrentLength({
        item: data[index].data,
        template: data[index].template
      });

      /* while (data[index].template.clips.length && data[index].template.clips.length < max) {
        data[index].template.clips.push({
          duration: 0,
          durationMode: 'smart',
          layers: [
            {
              ...data[index].template.clips[data[index].template.clips.length - 1].layers[0],
              path: `{{Media ${data[index].template.clips.length + 1}}}`
            },
            {
              ...data[index].template.clips[data[index].template.clips.length - 1].layers[1],
              text: `{{Title ${data[index].template.clips.length + 1}}}\n{{Text ${
                data[index].template.clips.length + 1
              }}}`
            },
            { ...data[index].template.clips[data[index].template.clips.length - 1].layers[2] }
          ],
          transition: {
            duration: 0.25,
            name: '{{Transition}}'
          }
        });
      }
      */
    });
    return data;
  };
};

export default normalize;
