import JsZip from 'jszip';
import FileSaver from 'file-saver';

const fetchBlobs = (videos, signal, downloadPopoverRef, selectedType) => {
  return Promise.allSettled(
    videos.map(
      ({ downloadLink, name, index, isFirst, landing }) =>
        new Promise((resolve, reject) => {
          let patchImageLink = `${downloadLink}?crossorigin`;
          //  if (selectedType !== 'Video') patchImageLink = downloadLink.replace(/^https:\/\//i, '//');

          fetch(patchImageLink, { signal })
            .then((resp) => {
              return resp.blob();
            })
            .then((blob) => {
              let extension = 'mp4';
              if (blob.type === 'image/jpg') extension = 'jpg';
              if (blob.type === 'image/gif') extension = 'gif';

              blob.name = `${index ? `${index}-` : ''}${name}.${extension}`;
              blob.downloadLink = downloadLink;
              blob.landing = landing;
              if (
                (blob.type === 'video/mp4' ||
                  blob.type === 'image/jpg' ||
                  blob.type === 'image/gif') &&
                !isFirst
              ) {
                downloadPopoverRef.current.update();
              }
              resolve(blob);
            })
            .catch((e) => {
              let extension = 'mp4';
              if (selectedType === 'Image') extension = 'jpg';
              if (selectedType === 'Gif') extension = 'gif';

              if (e instanceof DOMException) reject(e);
              else {
                resolve({
                  type: 'application/xml',
                  name: `${index ? `${index}-` : ''}${name}.${extension}`,
                  downloadLink,
                  landing
                });
              }
            });
        })
    )
  );
};

const pack = (blobs, downloadPopoverRef, failure) => {
  const zip = new JsZip();
  blobs.forEach((blob) => {
    if (blob.status === 'fulfilled') {
      if (
        blob.value.type === 'video/mp4' ||
        blob.value.type === 'image/jpg' ||
        blob.value.type === 'image/gif'
      ) {
        zip.file(blob.value.name, blob.value);
      } else {
        failure.push({
          downloadLink: blob.value.downloadLink,
          name: blob.value.name,
          landing: blob.value.landing
        });
      }
    } else throw new Error();
  });
  return zip.generateAsync({ type: 'blob' });
};

export const downloadAndZip = async ({ videos, name, signal, selectedType, downloadPopoverRef }) =>
  new Promise((resolve, reject) => {
    const failure = [];
    fetchBlobs(videos, signal, downloadPopoverRef, selectedType)
      .then((responses) => pack(responses, downloadPopoverRef, failure))
      .then((zipFile) => {
        if (failure.length < videos.length) FileSaver.saveAs(zipFile, name);
        resolve(failure);
      })
      .catch((e) => {
        reject(e);
      });
  });
