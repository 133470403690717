import { v4 } from 'uuid';

export const sampleRestaurantReservationsArtboardVideoData = [
  {
    videoDefinition: [
      {
        text: '',
        type: 'Reservations',
        v1: [
          {
            text: 'Tired of waiting to be seated?',
            type: 'Reservations'
          },
          {
            text: 'Reserve your table now!',
            type: 'Reservations'
          },
          {
            text: "Like this post if you're ready to make a reservation!",
            type: 'Reservations'
          }
        ]
      },
      {
        text: '',
        type: 'Reservations',
        v1: [
          {
            text: 'No more waiting for your table to be ready...',
            type: 'Reservations'
          },
          {
            text: 'Make your online reservation today.',
            type: 'Reservations'
          },
          {
            text: 'SAVE this post and avoid the wait!',
            type: 'Reservations'
          }
        ]
      },
      {
        text: '',
        type: 'Reservations',
        v1: [
          {
            text: 'Tired of waiting for your table?',
            type: 'Reservations'
          },
          {
            text: "We're open for reservations.",
            type: 'Reservations'
          },
          {
            text: "Tag the person you'd like to make a reservation with!",
            type: 'Reservations'
          }
        ]
      },
      {
        text: '',
        type: 'Reservations',
        v1: [
          {
            text: 'Waiting to be seated is not fun...',
            type: 'Reservations'
          },
          {
            text: "That's why we're now open for reservations!",
            type: 'Reservations'
          },
          {
            text: 'Drop a 😋 if you agree!',
            type: 'Reservations'
          }
        ]
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/578367094.hd.mp4?s=750ac47a229deb1751cb1f4d2fa9568c872fb459&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F578367094.sd.mp4%3Fs%3Dcf170144f6c77f2eea255bacdd2b91f548d3cc20%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8871286%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/578367094.hd.mp4?s=750ac47a229deb1751cb1f4d2fa9568c872fb459&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F578367094.sd.mp4%3Fs%3Dcf170144f6c77f2eea255bacdd2b91f548d3cc20%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8871286%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/559960987.hd.mp4?s=e4a7cd904378750d5ec5fae2e24a547c112a1ce8&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F559960987.sd.mp4%3Fs%3D324a7f478eefb57438117fdcca448e864c57a695%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8246929%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/559960987.hd.mp4?s=e4a7cd904378750d5ec5fae2e24a547c112a1ce8&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F559960987.sd.mp4%3Fs%3D324a7f478eefb57438117fdcca448e864c57a695%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8246929%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/444196978.hd.mp4?s=65b2a2fd7d0a19c8b83e965c4f535896053d6f32&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F444196978.sd.mp4%3Fs%3D6e35387dabde762ce0e72c1ba8f43e9b5a3e4bf7%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5019033%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/444196978.hd.mp4?s=65b2a2fd7d0a19c8b83e965c4f535896053d6f32&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F444196978.sd.mp4%3Fs%3D6e35387dabde762ce0e72c1ba8f43e9b5a3e4bf7%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5019033%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-4.jpeg',
        preview:
          'https://player.vimeo.com/external/540532482.hd.mp4?s=80418831faa1958a25b7e2bc062bac379016400a&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F540532482.sd.mp4%3Fs%3D79092c73cb7e50aefb46d71eade5c32adc57163b%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7618408%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/540532482.hd.mp4?s=80418831faa1958a25b7e2bc062bac379016400a&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F540532482.sd.mp4%3Fs%3D79092c73cb7e50aefb46d71eade5c32adc57163b%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7618408%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "Tired of waiting to be seated?\n\nReserve your table now!\n\nLike this post if you're ready to make a reservation!\n\n---\n\nNo more waiting for your table to be ready...\n\nMake your online reservation today.\n\nSAVE this post and avoid the wait!\n\n---\n\nTired of waiting for your table?\n\nWe're open for reservations.\n\nTag the person you'd like to make a reservation with!\n\n---Waiting to be seated is not fun...\n\nThat's why we're now open for reservations!\n\nDrop a 😋 if you agree!",
    type: 'Reservations',
    id: v4()
  }
];
