import { v4 } from 'uuid';

export const sampleRestaurantCateringEventsArtboardVideoData = [
  {
    videoDefinition: [
      {
        text: 'A special place for your special day!',
        type: 'Catering & Events'
      },
      {
        text: 'Make any ocassion memorable...',
        type: 'Catering & Events'
      },
      {
        text: 'Make any ocassision unforgettable...',
        type: 'Catering & Events'
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/664425036.hd.mp4?s=689372ee73406953e138bd95455ab56f7d4d444b&profile_id=175&oauth_token_id=57447761&h=1918&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F664425036.sd.mp4%3Fs%3Dd3f724472e04e360dd411cfe0367eef4c4fdbb27%26profile_id%3D139%26oauth_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F10811236%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/664425036.hd.mp4?s=689372ee73406953e138bd95455ab56f7d4d444b&profile_id=175&oauth_token_id=57447761&h=1918&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F664425036.sd.mp4%3Fs%3Dd3f724472e04e360dd411cfe0367eef4c4fdbb27%26profile_id%3D139%26oauth_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F10811236%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/477518771.hd.mp4?s=59a4f5be8c06003be5c4a295bf7bdcee323c59d9&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F477518771.sd.mp4%3Fs%3Daebda9107d6e6187df5f777f80eae760e25dc0a0%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5848517%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/477518771.hd.mp4?s=59a4f5be8c06003be5c4a295bf7bdcee323c59d9&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F477518771.sd.mp4%3Fs%3Daebda9107d6e6187df5f777f80eae760e25dc0a0%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5848517%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/575390143.hd.mp4?s=d8f3d72e9f8d11e7e3d251ea672ce41918350b47&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F575390143.sd.mp4%3Fs%3D2656d90bfe88022c55a70d97a0904ca508266f59%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8765271%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/575390143.hd.mp4?s=d8f3d72e9f8d11e7e3d251ea672ce41918350b47&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F575390143.sd.mp4%3Fs%3D2656d90bfe88022c55a70d97a0904ca508266f59%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8765271%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'A special place for your special day!\n\nMake any ocassion memorable...\n\nMake any ocassision unforgettable...\n\nWe make your dream party come true!',
    type: 'Catering & Events',
    id: v4()
  }
];
