import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& .MuiPaper-root': {
      width: 636,
      padding: 30,
      borderRadius: 12,
      boxShadow: '0px 3px 18px #00000029',
      '@media only screen and (max-width: 650px)': {
        margin: 0,
        height: '100%',
        maxHeight: '100%',
        paddingTop: '50px'
      }
    }
  },
  subTitle: {
    // fontSize: 16,
    // color: '#707070',
    // fontWeight: '400',
    // textAlign: 'center',
    // marginBottom: 20

    fontSize: 16,
    color: '#525252',
    padding: '0 20px',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 44,
    marginTop: 6
  },
  title: {
    // fontWeight: 700,
    // fontSize: 20,
    // margin: '0 6px',
    // color: '#000',
    // '@media only screen and (max-width: 768px)': {
    //   margin: '0 0px'
    // },
    // '@media only screen and (max-width: 650px)': {
    //   fontSize: 18,
    //   margin: '0 10px'
    // }
    fontSize: 30,
    fontWeight: 700,
    lineHeight: '40px',
    textAlign: 'center',
    color: '#525252',
    marginTop: 10,
    '@media only screen and (max-width: 425px)': {
      fontSize: 26,
      lineHeight: '20px'
    }
  },
  errorAlert: { marginBottom: 24 },
  label: {
    fontSize: 14,
    marginBottom: 10
  },
  item: {
    border: 'solid 1px #ddd',
    marginLeft: 6,
    marginRight: 6,
    marginBottom: 14,
    display: 'flex',
    minHeight: 44,
    textAlign: 'center',
    borderRadius: 8,
    '&:not(.loader)': {
      cursor: 'pointer'
    },
    alignItems: 'center',
    justifyContent: 'center',
    '&:not(.loader):not(.selected):hover': {
      backgroundColor: '#ececec'
    },
    '&.selected': {
      backgroundColor: '#3399FF',
      color: '#fff'
    }
  },
  gridContainer: {
    marginLeft: -6,
    width: 'calc(100% + 12px)'
  },
  startButton: {
    width: 192,
    fontSize: 18,
    padding: '6px 0px',
    borderRadius: 45,
    fontWeight: 400,
    // backgroundColor: '#0086FF',
    backgroundImage: '-webkit-linear-gradient(180deg, #8656da, #0082f9)',
    maxWidth: 280,
    color: '#fff',
    '&:disabled': {
      // color: '#1111',
      // backgroundColor: '#ececec'
      opacity: '.3'
    },
    '&:hover': {
      // backgroundColor: '#0060BF'
      backgroundImage: '-webkit-linear-gradient(180deg, #764dbd, #026cce)'
    }
  },
  autoCompleteContainer: {
    '& .MuiOutlinedInput-root': {
      height: 44,
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
      borderRadius: 8
    },
    '& input': {
      '&::-webkit-input-placeholder': {
        color: '#CACACA',
        fontSize: 14
      },
      '&:-ms-input-placeholder': {
        color: '#CACACA',
        fontSize: 14
      },
      '&::placeholder': {
        color: '#CACACA',
        fontSize: 14
      }
    },
    '& .Mui-focused fieldset': {
      borderColor: '#0086FF !important',
      borderRadius: 8
    }
  },
  formInput: {
    border: 'solid 1px #DFE3E7',
    width: '100%',
    borderRadius: 8,
    paddingLeft: 13,
    fontSize: 16,
    height: 44,
    '&:focus': {
      outline: 'none',
      border: '1px solid #009FBF',
      borderRadius: 8
    },
    '&.invalid': {
      borderColor: '#ce3b0f',
      outline: 'none'
    },
    '&::-webkit-input-placeholder': {
      color: '#d0d0d0',
      fontSize: 14
    },
    '&:-ms-input-placeholder': {
      color: '#d0d0d0',
      fontSize: 14
    },
    '&::placeholder': {
      color: '#d0d0d0',
      fontSize: 14
    }
  },
  formAlert: {
    marginBottom: 6,
    marginTop: 15,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto'
  },
  formSuccess: {
    marginTop: 15,
    textAlign: 'center',
    color: 'green !important',
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto'
  },

  infoText: {
    fontSize: 18,
    lineHeight: '28px',
    color: '#525252',
    fontWeight: 500,
    marginBottom: 20,
    marginTop: 20,
    '& span': {
      fontWeight: 700
    }
  },
  infoText__2: {
    fontSize: 18,
    lineHeight: '28px',
    color: '#525252'
  }
}));
