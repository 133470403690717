import React from 'react';
import { Button } from '@material-ui/core';

import { useStylesTutorial } from './styles';

const TutorialButton = ({ isMobileView, onOpen, isInVideoEditor }) => {
  const { menuButtonMain, menuButtonMobile, menuButtonEditor } = useStylesTutorial();

  return (
    <Button
      className={
        isInVideoEditor ? menuButtonEditor : isMobileView ? menuButtonMobile : menuButtonMain
      }
      disableRipple
      disableFocusRipple
      disableTouchRipple
      onClick={onOpen}
      style={{ backgroundColor: 'transparent' }}
      variant="text"
    >
      Watch Video
    </Button>
  );
};

export default TutorialButton;
