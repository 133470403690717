import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  const color = theme.palette.background.paper;
  return {
    popoverRoot: {
      boxShadow: 'none',
      border: 'solid 1px #d1d1d1',
      backgroundColor: color,
      maxWidth: 1000,
      borderRadius: 12
    },
    popper: {
      zIndex: 2000,
      marginTop: 12,
      '&[x-placement*="bottom"] $arrow': {
        top: 1,
        left: 0,
        marginTop: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%'
        }
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0'
        }
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.71em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%'
        }
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.71em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0'
        }
      }
    },
    arrow: {
      overflow: 'hidden',
      position: 'absolute',
      width: '1em',
      height: '0.71em',
      boxSizing: 'border-box',
      color,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)',
        borderLeft: 'solid 1px #d1d1d1',
        borderTop: 'solid 1px #d1d1d1',
        boxShadow: 'none',
        background: '#fff'
      }
    },
    closeButton: {
      position: 'absolute',
      right: -12,
      top: 0,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  };
});
