import { v4 } from 'uuid';

export const sampleQuotesArtboardVideoData = [
  {
    videoDefinition: [
      {
        text: "It's Monday - Wanna feel inspired? 💪",
        type: 'Quotes'
      },
      {
        text: 'Tuesday thoughts to keep you inspired',
        type: 'Quotes'
      },
      {
        text: 'It’s Wednesday! Just keep swimming. 😅',
        type: 'Quotes'
      }
    ],
    medias: [
      {
        path: 'pexels-vlada-karpovich-8045164.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323722/woxo-videos-user-gallery/darieladmin/pexels-vlada-karpovich-8045164_u6xq8p.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323722/woxo-videos-user-gallery/darieladmin/pexels-vlada-karpovich-8045164_u6xq8p.mp4'
        }
      },
      {
        path: 'pexels-stanislav-gaberov-5914351.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323774/woxo-videos-user-gallery/darieladmin/pexels-stanislav-gaberov-5914351_k6fern.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323774/woxo-videos-user-gallery/darieladmin/pexels-stanislav-gaberov-5914351_k6fern.mp4'
        }
      },
      {
        path: 'pexels-ben-mack-6775423.mp4',
        preview:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1652323844/woxo-videos-user-gallery/darieladmin/pexels-ben-mack-6775423_zh0zer.mp4',
        asset: {
          secure_url:
            'https://res.cloudinary.com/dakp804eh/video/upload/v1652323844/woxo-videos-user-gallery/darieladmin/pexels-ben-mack-6775423_zh0zer.mp4'
        }
      }
    ],
    text: "It's Monday - Wanna feel inspired? 💪\n\nTuesday thoughts to keep you inspired\n\nIt’s Wednesday! Just keep swimming. 😅",
    type: 'Quotes',
    id: v4()
  }
];
