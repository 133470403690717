import { v4 } from 'uuid';

export const sampleRestaurantDeliveryServicesArtboardVideoData = [
  {
    videoDefinition: [
      {
        text: 'From your over to your doorstep!',
        type: 'Delivery Services'
      },
      {
        text: 'We are now delivering...',
        type: 'Delivery Services'
      },
      {
        text: "Don't starve, just order online",
        type: 'Delivery Services'
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/427352838.hd.mp4?s=b8c4ea31f3414d84885661f5e3b5ef87b59de77b&profile_id=173&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427352838.sd.mp4%3Fs%3D50fa85cc46839e630e849ebcdc4765a85b0bc7f4%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605490%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/427352838.hd.mp4?s=b8c4ea31f3414d84885661f5e3b5ef87b59de77b&profile_id=173&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427352838.sd.mp4%3Fs%3D50fa85cc46839e630e849ebcdc4765a85b0bc7f4%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605490%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/598657445.hd.mp4?s=d178ef0461ba98ee7a1f332c2ccafbaf51c7317d&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F598657445.sd.mp4%3Fs%3D9c215c4202dc7f63c05c1852c551bca26fdf45d8%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F9460724%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/598657445.hd.mp4?s=d178ef0461ba98ee7a1f332c2ccafbaf51c7317d&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F598657445.sd.mp4%3Fs%3D9c215c4202dc7f63c05c1852c551bca26fdf45d8%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F9460724%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/427353831.hd.mp4?s=fc9c8ee9b21abab0207537af664c72a90c464db0&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427353831.sd.mp4%3Fs%3Da3503f0039a60270713377f47d46b5ce19f35dbd%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605488%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/427353831.hd.mp4?s=fc9c8ee9b21abab0207537af664c72a90c464db0&profile_id=172&oauth2_token_id=57447761&h=4096&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F427353831.sd.mp4%3Fs%3Da3503f0039a60270713377f47d46b5ce19f35dbd%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D226&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F4605488%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "From your over to your doorstep!\n\nWe are now delivering...\n\nDon't starve, just order online\n\nEnjoy the food you love at home!",
    type: 'Delivery Services',
    id: v4()
  }
];
