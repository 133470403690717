import { useRouter } from 'next/router';
import { Button as BaseButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import * as React from 'react';
import WaitingList from '../VideoGenerator/components/WaitingList';

import { Crown } from '../Icon';
import { usePaymentsModal } from '../PaymentsModal';
import { gtmFireEvent } from '~/helpers/gtm';
import getWoxoLocationFromPath from '~/hooks/helpers/signUpHelper';

const Button = styled(BaseButton)({
  borderRadius: '45px',
  backgroundImage: '-webkit-linear-gradient(180deg, #8656da, #0082f9)',
  height: '38px',
  textTransform: 'capitalize',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    backgroundImage: '-webkit-linear-gradient(180deg, #764dbd, #026cce)'
  },
  '& svg': {
    width: 32,
    height: 32,
    position: 'relative',
    top: 4,
    left: 5
  }
});

export function UpgradeButton({
  className,
  category = 'video',
  tryWoxoPro = false,
  showCategorySelector = true
}) {
  const paymentsModal = usePaymentsModal();
  const dialogRef = React.useRef();

  const handleUpgrade = React.useCallback(() => {
    gtmFireEvent({
      event: 'onButtonClick',
      category: getWoxoLocationFromPath(),
      action: 'upgrade-click',
      label: 'Upgrade plan action'
    });

    paymentsModal.show({ defaultCategory: category, showCategorySelector });
  }, [category, paymentsModal, showCategorySelector]);

  return (
    <>
      <Button
        className={className}
        variant="contained"
        color="primary"
        onClick={handleUpgrade}
        id="btn__upgrade"
      >
        {tryWoxoPro ? (
          <span style={{ paddingRight: 32, display: 'flex', alignItems: 'center' }}>
            <Crown />
            Try WOXO Pro
          </span>
        ) : (
          <span style={{ paddingRight: 32, display: 'flex', alignItems: 'center' }}>
            <Crown />
            Upgrade
          </span>
        )}
      </Button>

      <WaitingList forwardRef={dialogRef} type="Enterprise" />
    </>
  );
}
