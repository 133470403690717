import axios from './axios';
import { API_HOST } from './app.config';
import { isArray } from '../helpers/javascript';
import api from './api';

export const types = () =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/entity-types`
    })
      .then((response) => {
        if (isArray(response.data)) resolve(response.data);
        else reject();
      })
      .catch(() => {
        reject();
      });
  });
