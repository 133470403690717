import { sampleContentVideoData } from '../data/sampleContentVideoData';
import { sampleQuotesArtboardVideoData } from '../data/sampleQuotes';
import { sampleMusics } from '../data/music';
import { clone } from '~/helpers/javascript';

export const useStylesStore = (set, get) => ({
  //states
  textPosition: 'Top',
  videoShape: '9:16',
  videoShapeUse: 'TikTok',
  fontSize: 7,
  textColors: {
    text: { displayColorPicker: false, color: '#000' },
    background: { displayColorPicker: false, color: '#FFBC42' },
    activate: false
  },
  music: 'Inspirational',
  contentVideoData: sampleContentVideoData(sampleQuotesArtboardVideoData()),

  //action
  setTextPositionAction: (textPosition) => {
    set({ textPosition });
    get().trackingAction({
      event: 'onButtonClick',
      category: 'bulk-creator',
      action: `text-position-selection`,
      label: `${textPosition}`
    });
    get().buildVideosAction();
    get().addProjectVersionsAction();
  },

  setVideoShapeAction: (videoShape) => {
    if (videoShape !== get().videoShape) set({ loadingVideos: true });
    set({ videoShape });
    get().trackingAction({
      event: 'onButtonClick',
      category: 'bulk-creator',
      action: `video-shape-selection`,
      label: `${videoShape}`
    });
    get().buildVideosAction();
    get().addProjectVersionsAction();
  },

  setVideoShapeUseAction: (videoShapeUse) => {
    set({ videoShapeUse });
    get().trackingAction({
      event: 'onButtonClick',
      category: 'bulk-creator',
      action: `video-shape-use-selection`,
      label: `${videoShapeUse}`
    });
  },

  setFontSizeAction: (fontSize) => {
    set({ fontSize });
    get().buildVideosAction();
    get().addProjectVersionsAction();
  },

  setTextColorsAction: (data) => {
    set((state) => ({
      textColors: {
        ...state.textColors,
        text: {
          ...state.textColors.text,
          color: data.text.color,
          displayColorPicker: data.text.displayColorPicker
        },
        background: {
          ...state.textColors.background,
          background: data.background.color,
          displayColorPicker: data.background.displayColorPicker
        },
        textColors: data
      }
    }));

    get().buildVideosAction();
    get().addProjectVersionsAction();
  },

  setMusicAction: (music) => {
    set({ music });
    get().trackingAction({
      event: 'onButtonClick',
      category: 'bulk-creator',
      action: `music-selection`,
      label: `${music}`
    });

    let _contentVideoData = clone(get().contentVideoData);
    const _musics = sampleMusics
      .find((m) => m.mood === music)
      .music.sort(() => Math.random() - 0.5);

    let j = 0;
    for (let i = 0; i < _contentVideoData.length; i++) {
      if (_musics.length <= j) j = 0;
      _contentVideoData[i].music = _musics[j];
      j++;
    }
    set({ contentVideoData: _contentVideoData });
    get().buildVideosAction();
    get().addProjectVersionsAction();
  }
});
