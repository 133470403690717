import { v4 } from 'uuid';
import { sampleRestaurantCateringEventsArtboardVideoData as version_0_1 } from './v0_1/sampleRestaurantCateringEvents';
import { sampleRestaurantCateringEventsArtboardVideoData as version_1 } from './v1/sampleRestaurantCateringEvents';
import { sampleRestaurantCateringEventsArtboardVideoData as version_2 } from './v2/sampleRestaurantCateringEvents';

const version_0 = [
  {
    videoDefinition: [
      {
        text: 'A special place for your special day!',
        type: 'Catering & Events'
      },
      {
        text: 'Make any ocassion memorable...',
        type: 'Catering & Events'
      },
      {
        text: 'Make any ocassision unforgettable...',
        type: 'Catering & Events'
      },
      {
        text: 'We make your dream party come true!',
        type: 'Catering & Events'
      }
    ],
    medias: [
      {
        path: 'Fpreview-1.jpeg',
        preview:
          'https://player.vimeo.com/external/664425036.hd.mp4?s=689372ee73406953e138bd95455ab56f7d4d444b&profile_id=175&oauth_token_id=57447761&h=1918&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F664425036.sd.mp4%3Fs%3Dd3f724472e04e360dd411cfe0367eef4c4fdbb27%26profile_id%3D139%26oauth_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F10811236%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/664425036.hd.mp4?s=689372ee73406953e138bd95455ab56f7d4d444b&profile_id=175&oauth_token_id=57447761&h=1918&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F664425036.sd.mp4%3Fs%3Dd3f724472e04e360dd411cfe0367eef4c4fdbb27%26profile_id%3D139%26oauth_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F10811236%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-2.jpeg',
        preview:
          'https://player.vimeo.com/external/477518771.hd.mp4?s=59a4f5be8c06003be5c4a295bf7bdcee323c59d9&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F477518771.sd.mp4%3Fs%3Daebda9107d6e6187df5f777f80eae760e25dc0a0%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5848517%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/477518771.hd.mp4?s=59a4f5be8c06003be5c4a295bf7bdcee323c59d9&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F477518771.sd.mp4%3Fs%3Daebda9107d6e6187df5f777f80eae760e25dc0a0%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5848517%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-3.jpeg',
        preview:
          'https://player.vimeo.com/external/575390143.hd.mp4?s=d8f3d72e9f8d11e7e3d251ea672ce41918350b47&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F575390143.sd.mp4%3Fs%3D2656d90bfe88022c55a70d97a0904ca508266f59%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8765271%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/575390143.hd.mp4?s=d8f3d72e9f8d11e7e3d251ea672ce41918350b47&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F575390143.sd.mp4%3Fs%3D2656d90bfe88022c55a70d97a0904ca508266f59%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8765271%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-4.jpeg',
        preview:
          'https://player.vimeo.com/external/578367093.hd.mp4?s=fceb5964f5debca52972869f15f2a0dc8f9114a2&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F578367093.sd.mp4%3Fs%3D094f7ca8d59a09c285def0747343c774eedacfea%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8871287%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/578367093.hd.mp4?s=fceb5964f5debca52972869f15f2a0dc8f9114a2&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F578367093.sd.mp4%3Fs%3D094f7ca8d59a09c285def0747343c774eedacfea%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8871287%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'A special place for your special day!\n\nMake any ocassion memorable...\n\nMake any ocassision unforgettable...\n\nWe make your dream party come true!',
    type: 'Catering & Events',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: 'Fabulously planned... Remembered always!',
        type: 'Catering & Events'
      },
      {
        text: 'We take your food and fun seriously.',
        type: 'Catering & Events'
      },
      {
        text: 'Eat. Drink. Celebrate.',
        type: 'Catering & Events'
      },
      {
        text: 'We plan, you celebrate!',
        type: 'Catering & Events'
      }
    ],
    medias: [
      {
        path: 'Fpreview-11.jpeg',
        preview:
          'https://player.vimeo.com/external/536851242.hd.mp4?s=847ad01ffa3eee894f9121aae57dc291516aec27&profile_id=169&oauth2_token_id=57447761&h=1920&w=1066&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F536851242.sd.mp4%3Fs%3D08ae07787c7374130b28a681cf2df2706cd5e5d9%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D356&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7509435%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/536851242.hd.mp4?s=847ad01ffa3eee894f9121aae57dc291516aec27&profile_id=169&oauth2_token_id=57447761&h=1920&w=1066&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F536851242.sd.mp4%3Fs%3D08ae07787c7374130b28a681cf2df2706cd5e5d9%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D356&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F7509435%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-22.jpeg',
        preview:
          'https://player.vimeo.com/external/557650674.hd.mp4?s=4ddf056ba849fad788a9c0643c1034fd64601393&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F557650674.sd.mp4%3Fs%3Df6f3cf63e243dfc7f8037fb02a1b32cb39953bc7%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8161209%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/557650674.hd.mp4?s=4ddf056ba849fad788a9c0643c1034fd64601393&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F557650674.sd.mp4%3Fs%3Df6f3cf63e243dfc7f8037fb02a1b32cb39953bc7%26profile_id%3D164%26oauth2_token_id%3D57447761%26h%3D640%26w%3D360&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F8161209%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-33.jpeg',
        preview:
          'https://player.vimeo.com/external/371446682.hd.mp4?s=df0b55d3a3e657dd9a7a81954872102240e03853&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F371446682.sd.mp4%3Fs%3D071d11cdb014f4574082d7e97203ab120950257b%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3189297%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/371446682.hd.mp4?s=df0b55d3a3e657dd9a7a81954872102240e03853&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F371446682.sd.mp4%3Fs%3D071d11cdb014f4574082d7e97203ab120950257b%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F3189297%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-44.jpeg',
        preview:
          'https://player.vimeo.com/external/496856419.hd.mp4?s=e183741bb77159869f326c76710b26b256432ff6&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F496856419.sd.mp4%3Fs%3D45095270647c286cea4437fc2838779416aa4d85%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6395999%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/496856419.hd.mp4?s=e183741bb77159869f326c76710b26b256432ff6&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F496856419.sd.mp4%3Fs%3D45095270647c286cea4437fc2838779416aa4d85%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6395999%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: 'Fabulously planned... Remembered always!\n\nWe take your food and fun seriously.\n\nEat. Drink. Celebrate.\n\nWe plan, you celebrate!',
    type: 'Catering & Events',
    id: v4()
  },
  {
    videoDefinition: [
      {
        text: "SAVE this post and book your private party when you're ready!",
        type: 'Catering & Events'
      },
      {
        text: "Like if you're down for an out of this world celebration!",
        type: 'Catering & Events'
      },
      {
        text: "Drop a 😋 if you're down to celebrate with us!",
        type: 'Catering & Events'
      },
      {
        text: 'What ocassion do you want to celebrate? Drop it below!',
        type: 'Catering & Events'
      }
    ],
    medias: [
      {
        path: 'Fpreview-111.jpeg',
        preview:
          'https://player.vimeo.com/external/448048609.hd.mp4?s=91c939b7efa078d391ef13da6d08c12d0985f2df&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F448048609.sd.mp4%3Fs%3D11dc989cb40a69e3453d00fcf4c73ba1c7a11969%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5107292%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/448048609.hd.mp4?s=91c939b7efa078d391ef13da6d08c12d0985f2df&profile_id=172&oauth2_token_id=57447761&h=3840&w=2160&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F448048609.sd.mp4%3Fs%3D11dc989cb40a69e3453d00fcf4c73ba1c7a11969%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5107292%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-222.jpeg',
        preview:
          'https://player.vimeo.com/external/494071045.hd.mp4?s=5d1e74dfc5edad285975b902d0b7153895216fed&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F494071045.sd.mp4%3Fs%3D10cc294f1e182ea57119b26cb55ee56bcb58f4ff%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6271560%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/494071045.hd.mp4?s=5d1e74dfc5edad285975b902d0b7153895216fed&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F494071045.sd.mp4%3Fs%3D10cc294f1e182ea57119b26cb55ee56bcb58f4ff%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6271560%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-333.jpeg',
        preview:
          'https://player.vimeo.com/external/444521389.hd.mp4?s=8c54cb94aed25f808db698bf65cbdefd842d032a&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F444521389.sd.mp4%3Fs%3De28698496f6be4ad6a2f5266b2a2bcafd82cb74d%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5027636%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/444521389.hd.mp4?s=8c54cb94aed25f808db698bf65cbdefd842d032a&profile_id=175&oauth2_token_id=57447761&h=1920&w=1080&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F444521389.sd.mp4%3Fs%3De28698496f6be4ad6a2f5266b2a2bcafd82cb74d%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F5027636%2Fpictures%2Fpreview-0.jpeg'
        }
      },
      {
        path: 'Fpreview-444.jpeg',
        preview:
          'https://player.vimeo.com/external/505556006.hd.mp4?s=753c8ba1368a13d13af627a3c56226d54af43d19&profile_id=174&oauth2_token_id=57447761&h=1280&w=720&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F505556006.sd.mp4%3Fs%3D964f0f5efcb760f375f2eb618214be464b7275bc%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6624860%2Fpictures%2Fpreview-0.jpeg',
        asset: {
          secure_url:
            'https://player.vimeo.com/external/505556006.hd.mp4?s=753c8ba1368a13d13af627a3c56226d54af43d19&profile_id=174&oauth2_token_id=57447761&h=1280&w=720&preview=https%3A%2F%2Fplayer.vimeo.com%2Fexternal%2F505556006.sd.mp4%3Fs%3D964f0f5efcb760f375f2eb618214be464b7275bc%26profile_id%3D139%26oauth2_token_id%3D57447761%26h%3D426%26w%3D240&cover=https%3A%2F%2Fimages.pexels.com%2Fvideos%2F6624860%2Fpictures%2Fpreview-0.jpeg'
        }
      }
    ],
    text: "SAVE this post and book your private party when you're ready!\n\nLike if you're down for an out of this world celebration!\n\nDrop a 😋 if you're down to celebrate with us!\n\nWhat ocassion do you want to celebrate? Drop it below!",
    type: 'Catering & Events',
    id: v4()
  }
];

export const sampleRestaurantCateringEventsArtboardVideoData = (version = null) => {
  let data = [];
  switch (version) {
    case '0_1':
      data = version_0_1;
      break;
    case '1':
      data = version_1;
      break;
    case '2':
      data = version_2;
      break;
    default:
      data = version_0;
      break;
  }

  return data;
};
